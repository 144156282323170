<template>
  <div class="flex w-full my-5">
    <filtros/>
  </div>
  <div class="grid grid-cols-12 gap-x-4 gap-y-2 mb-4">
    <div class="col-span-12 sm:col-span-6">
      <label for="cliente" class="block text-sm font-medium text-gray-700">Cliente:</label>
      <select class="form-select form-select-sm mt-1" id="cliente" v-model="selectedCardCode" @change="getData('cliente')">
        <option v-for="(row,k) in clientes" :key="k" :value="row.value">{{ row.name }}</option>
      </select>
    </div>
    <div class="col-span-12 sm:col-span-6">
      <label for="contrato" class="block text-sm font-medium text-gray-700">Contrato:</label>
      <select class="form-select form-select-sm mt-1" id="contrato" v-model="filterContrato" @change="getData('contrato')">
        <option value="">- Todos -</option>
        <option v-for="(row,k) in contratos" :key="k" :value="row.value">{{ row.name }} --- Fec. Inicio: {{ row.fecInicio }}</option>
      </select>
    </div>
  </div>

  <div class="grid grid-rows-3 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
    <div>
      <cardFacturacion
        ref="_cardFacturacion"
        @getData="getData"/>
    </div>
    <cardCumplimientoRef/>
    <cardCumplimientoUnd/>
    <div>
      <cardPedidos/>
    </div>
    <cardRemisionVsFacturas/>
  </div>
</template>
<script>
import { computed, defineAsyncComponent, onMounted, watch, ref } from 'vue'
import storeCardFacturacion from './store/cardFacturacion.store'
import storeCardPedidos from './store/cardPedidos.store'
import storeCardCumplimiento from './store/cardCumplimiento.store'
import storeFiltro from './store/filtro.store'
import { storeCardRemisionVsFacturas } from './store/cardRemisionVsFacturas.store'

export default {
  name: 'index',
  components: {
    filtros: defineAsyncComponent(() => import('./filtro')),
    cardFacturacion: defineAsyncComponent(() => import('./cardFacturacion')),
    cardCumplimientoRef: defineAsyncComponent(() => import('./cardCumplimiento')),
    cardCumplimientoUnd: defineAsyncComponent(() => import('./cardCumplimientoUnd')),
    cardPedidos: defineAsyncComponent(() => import('./cardPedidos')),
    cardRemisionVsFacturas: defineAsyncComponent(() => import('./cardRemisionVsFacturas'))
  },
  setup () {
    const useCardRemisionVsFacturas = storeCardRemisionVsFacturas()
    const selectedCardCode = ref('CL900006037')
    const filterContrato = ref('')
    // eslint-disable-next-line no-unused-vars
    const clientes = ref([
      {
        name: 'COOSALUD ( GUAINIA )',
        value: 'CL900226715',
        vlr: 0
      },
      {
        name: 'COMFAORIENTE',
        value: 'CL890500675',
        vlr: 0
      },
      {
        name: 'E.S.E HOSPITAL UNIVERSITARIO ERASMOMEOZ',
        value: 'CL800014918',
        vlr: 3833184899
      },
      {
        name: 'E.S.E HOSPITAL UNIVERSITARIO',
        value: 'CL900006037',
        vlr: 1950000000
      },
      {
        name: 'UNIVERSIDAD INDUSTRIAL DE SANTANDER',
        value: 'CL890201213',
        vlr: 0
      }
    ])

    /** computed **/
    const contratos = computed(() => storeCardFacturacion.getters.contratos)
    const _anio = computed(() => storeFiltro.getters.anio)
    const _mes = computed(() => storeFiltro.getters.mes)
    const contrato = computed(
      () => storeCardFacturacion.getters.contrato
    )
    /** watch **/
    watch(_anio, (a, b) => {
      getData()
    })
    watch(_mes, (a, b) => {
      getData()
    })
    /** methodos **/
    const getData = (typeChange) => {
      filterContrato.value = typeChange === 'cliente' ? '' : filterContrato.value

      storeCardFacturacion.dispatch('getContratos', {
        cardCode: selectedCardCode.value
      }).then(() => {
        let vlrContratado = 0

        if (selectedCardCode.value === 'CL800014918') {
          if (filterContrato.value === '') {
            vlrContratado = contratos.value.reduce((a, b) => a + b.vlrContrato, 0)
          } else {
            vlrContratado = contratos.value.find((item) => item.value === filterContrato.value).vlrContrato
          }
        } else {
          vlrContratado = clientes.value.find((item) => item.value === selectedCardCode.value).vlr
        }

        storeCardFacturacion.dispatch('calcularVlr', { vlrContratado, selectedCardCode: selectedCardCode.value, selectedContrato: filterContrato.value })

        storeCardFacturacion.dispatch('getDataFacturacion', {
          anio: _anio.value,
          mes: _mes.value,
          cardCode: selectedCardCode.value,
          contrato: filterContrato.value
        })
      })

      Promise.all([
        storeCardCumplimiento.dispatch('getDataCumplimiento', {
          anio: _anio.value,
          mes: _mes.value,
          cardCode: selectedCardCode.value
        }),
        storeCardPedidos.dispatch('getDataPedidos', {
          anio: _anio.value,
          mes: _mes.value,
          cardCode: selectedCardCode.value
        }),
        useCardRemisionVsFacturas.getData({ cardCode: selectedCardCode.value })
      ])
    }
    /** hook **/
    onMounted(() => {
      getData()
    })
    return {
      selectedCardCode,
      filterContrato,
      contratos,
      contrato,
      clientes,
      getData
    }
  }
}
</script>
<style scoped>
</style>
