import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_DASHBOARD_HUS
export default class Cumplimiento {
  getDataCumplimiento (payload = {}) {
    return http.get(`${baseUrl}/operaciones/pedidos-cumplimiento`, {
      params: payload,
      headers: {
        loading: true
      }
    })
  }
}
