import axios from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}`

const apiIndicadoresFinancierosBodega = (query) => axios.get(`${api}/indicadores-financieros-bodega`, { params: { ...query } })
const apiWineriesList = () => axios.get(`${api}/wineries-list`)

export {
  apiIndicadoresFinancierosBodega,
  apiWineriesList
}
