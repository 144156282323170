import { getFacturacion } from './service'
import { computed, ref } from 'vue'
import { helper } from '../../../../../utils/helper'

const info = ref({
  Capita: {
    cantidad: 0,
    total: 0,
    items: [],
    subtotales: {}
  },
  Evento: {
    cantidad: 0,
    total: 0,
    items: [],
    subtotales: {}
  }
})
const estaCargando = ref(true)
const searchable = ref(false)
const contratoSel = ref('')
const sedeSel = ref('')
const estado = ref(1)
const typeModalidad = ref('evento')
const searchNivel2 = ref('')
const searchNivel3 = ref('')

const chartPorcentaje = computed(() => {
  if (info.value.total) return (info.value.total * 100) / 12500000000
  return 0
})

const chartData = computed(() => {
  const tmp = []
  let porcentaje = 0
  if (info.value.total) porcentaje = (info.value.total * 100) / 12500000000
  if (porcentaje) {
    tmp.push(porcentaje)
    tmp.push(100 - porcentaje)
  }
  return tmp
})

const setContratoSel = (value) => {
  estado.value = 3
  contratoSel.value = value
}

const setSedeSel = (value) => {
  estado.value = 4
  sedeSel.value = value
}

const chartLabels = computed(() => {
  return [
    helper.formatCurrency(info.value.total),
    helper.formatCurrency((12500000000 - info.value.total))
  ]
})

const fetchFacturacion = (queryParams) => {
  estaCargando.value = true
  return getFacturacion(queryParams).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err.message)
  })
}

const setEstado = (value, type = 'evento') => {
  estado.value = value
  typeModalidad.value = type
}

const getCantidad = (column) => {
  return info.value.Evento.items[column].reduce((total, item) => total + item.cantidad, 0)
}

const getTotal = (column) => {
  return info.value.Evento.items[column].reduce((total, item) => total + item.total, 0)
}

const getItems = computed(() => {
  const tmp = []
  let infoType
  if (typeModalidad.value === 'evento') {
    infoType = info.value.Evento.items
  } else {
    infoType = info.value.Capita.items
  }
  for (const value of infoType) {
    if (value.label !== '') {
      const contrato = value.label
      const total = value.total
      const cantidad = value.cantidad
      tmp.push({ contrato, total, cantidad })
    }
  }
  return tmp.filter(a =>
    a.contrato.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.cantidad.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
  )
})

const getNameTypeModalidad = computed(() => {
  if (typeModalidad.value === 'evento') {
    return 'Evento'
  }
  return 'Capita'
})

const getResumen = computed(() => {
  let infoType
  if (typeModalidad.value === 'evento') {
    infoType = info.value.Evento.items
  } else {
    infoType = info.value.Capita.items
  }
  const rows = infoType.find(f => f.label === contratoSel.value)
  if (rows === undefined) return []
  const filtered = rows.children.filter(a =>
    a.label.toLowerCase().includes(searchNivel3.value.toLowerCase()) ||
    a.cantidad.toString().toLowerCase().includes(searchNivel3.value.toLowerCase()) ||
    a.total.toString().toLowerCase().includes(searchNivel3.value.toLowerCase())
    )
  return filtered
})

export {
  estaCargando,
  searchable,
  estado,
  info,
  chartPorcentaje,
  chartLabels,
  chartData,
  searchNivel2,
  searchNivel3,
  getItems,
  getResumen,
  fetchFacturacion,
  setEstado,
  getCantidad,
  getTotal,
  setContratoSel,
  setSedeSel,
  getNameTypeModalidad,
  typeModalidad
}
