import http from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/operation-report`

const apiSelectFilter = () => http.get(`${api}/select-filter`)

const apiAuthorizationReason = (options) => http.get(
  `${api}/authorization-reason`,
  { params: { ...options } })

const apiclasificacionPqr = (queryParams = {}) => http.get(`${api}/clasificacion-pqr`, { params: { ...queryParams } })
const apiclasificacionTipoPqr = (queryParams = {}) => http.get(`${api}/clasificacion-tipo-pqr`, { params: { ...queryParams } })
const apiClientePqr = (queryParams = {}) => http.get(`${api}/cliente-pqr`, { params: { ...queryParams } })
const apiClienteTipoPqr = (queryParams = {}) => http.get(`${api}/cliente-tipo-pqr`, { params: { ...queryParams } })
const apiMedioPqr = (queryParams = {}) => http.get(`${api}/medio-pqr`, { params: { ...queryParams } })
const apiMedioClientePqr = (queryParams = {}) => http.get(`${api}/medio-cliente-pqr`, { params: { ...queryParams } })
const apiFiltroClasificacion = (queryParams = {}) => http.get(`${api}/filtro-clasificacion-pqr`, { params: { ...queryParams } })
const apiTopVentasCliente = (queryParams = {}) => http.get(`${api}/top-ventas-clientes`, { params: { ...queryParams } })
const apiTopVentasClienteMx = (queryParams = {}) => http.get(`${api}/top-ventas-clientes-mx`, { params: { ...queryParams } })
const apiTopComprasProveedores = (queryParams = {}) => http.get(`${api}/top-compras-proveedores`, { params: { ...queryParams } })
const apiTopComprasProveedoresMx = (queryParams = {}) => http.get(`${api}/top-compras-proveedores-mx`, { params: { ...queryParams } })

export {
  apiSelectFilter,
  apiAuthorizationReason,
  apiclasificacionPqr,
  apiclasificacionTipoPqr,
  apiClientePqr,
  apiClienteTipoPqr,
  apiMedioPqr,
  apiMedioClientePqr,
  apiFiltroClasificacion,
  apiTopVentasCliente,
  apiTopVentasClienteMx,
  apiTopComprasProveedores,
  apiTopComprasProveedoresMx
}
