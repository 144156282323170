import { defineStore } from 'pinia'
// import dayjs from 'dayjs'

export const useFiltersCardComprasCatalogoStore = defineStore({
  id: 'filtersCardComprasCatalogo',
  state: () => ({
    _filters: {
      year: '',
      month: '',
      itemCode: '',
      agrupacion: ''
    }
  }),
  getters: {
    getFilters: (state) => state._filters
  },
  actions: {
    setUpdateFilters (filters) {
      this._filters = filters
    }
  }
})
