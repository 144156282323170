<template>
  <div v-if="getInfoComprasCatalogo.ItemCode !== ''">
    <div class="flex justify-between items-center mb-2">
      <h3 class="text-xs font-normal mb-2">
        Información detallada
        <span class="font-bold">(Precios)</span>
      </h3>
      <div class="flex justify-end">
        <Button
          icon="pi pi-table"
          class="p-button p-button-success sm:flex h-7 sm:mr-1"
          :disabled="typeTab === 'table'"
          @click="changeTypeTab('table')"
          v-tippy="{ content: 'Mostrar Tabla' }"
        />
        <Button
          icon="pi pi-chart-line"
          class="p-button p-button-help sm:flex h-7 sm:mr-1 text-sm"
          :disabled="typeTab === 'chart'"
          @click="changeTypeTab('chart')"
          v-tippy="{ content: 'Mostrar gráfica' }"
        />
        <Button
          icon="pi pi-align-justify"
          class="p-button sm:flex h-7"
          :disabled="typeTab === 'chart'"
          @click="autoSizeAll(false)"
          v-tippy="{ content: 'Ajustar Columnas al contenido' }"
        />
      </div>
    </div>
    <div v-if="loading" style="width:100%; height: 590px" class="rounded-md icono-carga flex justify-center items-center relative">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 590px"/>
    </div>
    <div v-else>
      <!-- Grafica -->
      <div v-if="typeTab === 'chart'" ref="containerChart">
        <!-- Filtros grafica -->
        <div class="grid grid-cols-12 gap-x-4 gap-y-2">
          <div class="col-start-1 col-end-13 sm:col-start-4 sm:col-end-6">
            <div class="flex justify-end items-center w-full mb-2">
              <label for="start_year" class="block text-xs font-medium text-gray-700 mr-2">Año:</label>
              <select class="form-select form-select-sm" id="start_year" v-model="filtersChart.year" @change="handleYearChange">
                <option value="">Todos</option>
                <option v-for="(row,k) in filterYears" :key="k" :value="row">{{ row }}</option>
              </select>
            </div>
          </div>
          <div class="col-start-1 col-end-13 sm:col-start-6 sm:col-end-9">
            <div class="flex justify-end items-center w-full mb-2">
              <label for="start_month" class="flex-none mr-2 block text-xs font-medium text-gray-700">Mes Inicial:</label>
              <select class="flex-grow form-select form-select-sm" id="start_month" v-model="filtersChart.startMonth" :disabled="disabledMonth">
                <option v-for="(row,k) in months" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-start-1 col-end-13 sm:col-start-9 sm:col-end-12">
            <div class="flex justify-end items-center w-full mb-2">
              <label for="end_month" class="flex-none mr-2 block text-xs font-medium text-gray-700">Mes Final:</label>
              <select class="flex-grow form-select form-select-sm" id="end_month" v-model="filtersChart.endMonth" :disabled="disabledMonth">
                <option v-for="(row,k) in filterMonths" :key="k" :value="row.id">{{ row.name }}</option>
              </select>
            </div>
          </div>
          <div class="col-start-1 col-end-13 sm:col-start-12 sm:col-end-13">
            <div class="flex justify-end items-center w-full mb-2">
              <Button
                icon="pi pi-filter"
                class="p-button-xs sm:mr-2 p-button-primary h-7 w-full"
                @click="handleFilter"
                v-tippy="{ content: 'Filtrar' }"
              />
              <Button
                icon="pi pi-trash"
                severity="Expandir"
                class="p-button-xs p-button-danger h-7 w-full"
                @click="handleClear"
                v-tippy="{ content: 'Limpiar' }"
              />
            </div>
          </div>
        </div>
        <div v-if="loadingChart" style="width:100%; height: 590px" class="rounded-md icono-carga flex justify-center items-center relative">
          <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 590px"/>
        </div>

        <div v-else>
          <div class="flex flex-col justify-center items-center" v-if="chartData.value.length === 0">
            <div class="flex justify-center items-center w-full opacity-40" style="height: 495px">
              <svg data-name="Layer 1" style="height: 300px; margin: auto;" viewBox="0 0 647.63626 632.17383" xmlns:xlink="http://www.w3.org/1999/xlink">
                <path d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z" transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/><path d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/><path d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z" transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/><circle cx="190.15351" cy="24.95465" r="20" fill="#6c63ff"/><circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/><path d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z" transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
                <path d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
                <path d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z" transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/>
                <circle cx="433.63626" cy="105.17383" r="20" fill="#6c63ff"/><circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
              </svg>
            </div>
            <span class="text-sm sm:text-base font-bold text-red-400 my-4 normal-case text-center opacity-50">No hay información para graficar</span>
          </div>
          <div v-else>
            <!-- Quick stats -->
            <div class="grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-5 my-1">
              <div
                class="flex items-center cursor-pointer p-2 bg-white rounded-lg border border-dashed shadow-sm dark:bg-gray-800"
                aria-expanded="false">
                <div class="h-8 w-8 rounded-full flex flex-col items-center justify-center text-lg text-yellow-800 bg-yellow-200 dark:bg-slate-900">
                  <Icon icon="carbon:chart-average" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                  <span class="text-sm font-bold">
                    {{ $h.formatCurrency(dataQuickStats.avgPrice, 2) }}
                  </span>
                  <div class="flex items-center justify-between">
                    <span class="text-xs text-gray-500 font-semibold">Precio Promedio</span>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center cursor-pointer p-2 bg-white rounded-lg border border-dashed shadow-sm dark:bg-gray-800"
                aria-expanded="false">
                <div class="h-8 w-8 rounded-full flex flex-col items-center justify-center text-lg text-green-800 bg-green-200 dark:bg-slate-900">
                  <Icon icon="streamline:money-graph-arrow-decrease-down-stats-graph-descend-right-arrow" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                  <span class="text-sm font-bold">
                    {{ $h.formatCurrency(dataQuickStats.minPrice, 2) }}
                  </span>
                  <div class="flex items-center justify-between">
                    <span class="text-xs text-gray-500 font-semibold">Precio Mínimo</span>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center cursor-pointer p-2 bg-white rounded-lg border border-dashed shadow-sm dark:bg-gray-800"
                aria-expanded="false">
                <div class="h-8 w-8 rounded-full flex flex-col items-center justify-center text-lg text-red-800 bg-red-200 dark:bg-slate-900">
                  <Icon icon="streamline:money-graph-arrow-increase-ascend-growth-up-arrow-stats-graph-right-grow" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                  <span class="text-sm font-bold">
                    {{ $h.formatCurrency(dataQuickStats.maxPrice, 2) }}
                  </span>
                  <div class="flex items-center justify-between">
                    <span class="text-xs text-gray-500 font-semibold">Precio Máximo</span>
                  </div>
                </div>
              </div>
              <div
                class="flex items-center cursor-pointer p-2 bg-white rounded-lg border border-dashed shadow-sm dark:bg-gray-800"
                aria-expanded="false">
                <div class="h-8 w-8 rounded-full flex flex-col items-center justify-center text-lg text-blue-800 bg-blue-200 dark:bg-slate-900">
                  <Icon icon="mdi:counter" />
                </div>
                <div class="flex-grow flex flex-col ml-4">
                  <span class="text-sm font-bold">
                    {{ dataQuickStats.totalItems }}
                  </span>
                  <div class="flex items-center justify-between">
                    <span class="text-xs text-gray-500 font-semibold">Total Registros</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- Quick stats End  -->

            <!-- Grafica -->
            <div ref="elChart" class="chart w-full h-full" style="height: 490px" id="chart"></div>
          </div>
        </div>
      </div>
      <!-- Tabla -->
      <div v-if="typeTab === 'table'">
        <ag-grid-vue
          class="ag-theme-alpine dark:ag-theme-alpine-dark ag-grid-compras-catalogo"
          style="height: calc(590px)"
          animateRows="true"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
          :columnDefs="columnDefs.value"
          :rowData="rowData.value"
          :defaultColDef="defaultColDef"
          :gridOptions="gridOptions"
          :localeText="localeText"
          :popupParent="popupParent"
          :onRowClicked="onRowClicked"
          :getRowStyle="getRowStyle"
          :statusBar="statusBar"
          :suppressDragLeaveHidesColumns="true"
        >
        </ag-grid-vue>
      </div>
    </div>
  </div>
  <div class="flex flex-col justify-center items-center" v-else>
    <div class="flex justify-center items-center w-full opacity-40" style="height: 570px">
      <svg data-name="Layer 1" style="height: 300px; margin: auto;" viewBox="0 0 647.63626 632.17383" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path d="M687.3279,276.08691H512.81813a15.01828,15.01828,0,0,0-15,15v387.85l-2,.61005-42.81006,13.11a8.00676,8.00676,0,0,1-9.98974-5.31L315.678,271.39691a8.00313,8.00313,0,0,1,5.31006-9.99l65.97022-20.2,191.25-58.54,65.96972-20.2a7.98927,7.98927,0,0,1,9.99024,5.3l32.5498,106.32Z" transform="translate(-276.18187 -133.91309)" fill="#f2f2f2"/><path d="M725.408,274.08691l-39.23-128.14a16.99368,16.99368,0,0,0-21.23-11.28l-92.75,28.39L380.95827,221.60693l-92.75,28.4a17.0152,17.0152,0,0,0-11.28028,21.23l134.08008,437.93a17.02661,17.02661,0,0,0,16.26026,12.03,16.78926,16.78926,0,0,0,4.96972-.75l63.58008-19.46,2-.62v-2.09l-2,.61-64.16992,19.65a15.01489,15.01489,0,0,1-18.73-9.95l-134.06983-437.94a14.97935,14.97935,0,0,1,9.94971-18.73l92.75-28.4,191.24024-58.54,92.75-28.4a15.15551,15.15551,0,0,1,4.40966-.66,15.01461,15.01461,0,0,1,14.32032,10.61l39.0498,127.56.62012,2h2.08008Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/><path d="M398.86279,261.73389a9.0157,9.0157,0,0,1-8.61133-6.3667l-12.88037-42.07178a8.99884,8.99884,0,0,1,5.9712-11.24023l175.939-53.86377a9.00867,9.00867,0,0,1,11.24072,5.9707l12.88037,42.07227a9.01029,9.01029,0,0,1-5.9707,11.24072L401.49219,261.33887A8.976,8.976,0,0,1,398.86279,261.73389Z" transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/><circle cx="190.15351" cy="24.95465" r="20" fill="#6c63ff"/><circle cx="190.15351" cy="24.95465" r="12.66462" fill="#fff"/><path d="M878.81836,716.08691h-338a8.50981,8.50981,0,0,1-8.5-8.5v-405a8.50951,8.50951,0,0,1,8.5-8.5h338a8.50982,8.50982,0,0,1,8.5,8.5v405A8.51013,8.51013,0,0,1,878.81836,716.08691Z" transform="translate(-276.18187 -133.91309)" fill="#e6e6e6"/>
        <path d="M723.31813,274.08691h-210.5a17.02411,17.02411,0,0,0-17,17v407.8l2-.61v-407.19a15.01828,15.01828,0,0,1,15-15H723.93825Zm183.5,0h-394a17.02411,17.02411,0,0,0-17,17v458a17.0241,17.0241,0,0,0,17,17h394a17.0241,17.0241,0,0,0,17-17v-458A17.02411,17.02411,0,0,0,906.81813,274.08691Zm15,475a15.01828,15.01828,0,0,1-15,15h-394a15.01828,15.01828,0,0,1-15-15v-458a15.01828,15.01828,0,0,1,15-15h394a15.01828,15.01828,0,0,1,15,15Z" transform="translate(-276.18187 -133.91309)" fill="#3f3d56"/>
        <path d="M801.81836,318.08691h-184a9.01015,9.01015,0,0,1-9-9v-44a9.01016,9.01016,0,0,1,9-9h184a9.01016,9.01016,0,0,1,9,9v44A9.01015,9.01015,0,0,1,801.81836,318.08691Z" transform="translate(-276.18187 -133.91309)" fill="#6c63ff"/>
        <circle cx="433.63626" cy="105.17383" r="20" fill="#6c63ff"/><circle cx="433.63626" cy="105.17383" r="12.18187" fill="#fff"/>
      </svg>
    </div>
    <span class="text-sm sm:text-base font-bold text-red-400 my-4 normal-case text-center opacity-50">No hay información para mostrar, seleccione un artículo del listado general para visualizar el detalle</span>
  </div>
<!--  <pre>Info: {{ JSON.stringify(getInfoComprasCatalogo, null, '\t') }}</pre>
  <pre>Filtros: {{ JSON.stringify(getterFiltersComprasCatalogo, null, '\t') }}</pre>-->
</template>

<script setup>
import { onMounted, computed, ref, reactive, watch } from 'vue'
import { useInfoComprasCatalogoStore } from '../../stores/infoComprasCatalogo'
import { getListDetailsCatalogo } from '../../services/getListDetailsCatalogo.service'
import { AgGridVue } from 'ag-grid-vue3'
import 'ag-grid-enterprise' // Optional - for Enterprise features
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'
import localeText from '../../../../../../../lang/ag-grid/es'
import { helper } from '../../../../../../../utils/helper'
import dayjs from 'dayjs'
import { useFiltersCardComprasCatalogoStore } from '../../stores/filtersCardComprasCatalogo'
import { getListDetailsChartCatalogo } from '../../services/getListDetailsChartCatalogo.service'
import Icon from '@/components/Icon/index.vue'
import Highcharts from 'highcharts'

const elChart = ref(null)
const containerChart = ref(null)
const chart = ref(null)
const filtersCardComprasCatalogoStore = useFiltersCardComprasCatalogoStore()
const infoComprasCatalogoStore = useInfoComprasCatalogoStore()
const typeTab = ref('table')
const loading = ref(false)
const loadingChart = ref(false)
const chartData = reactive({})
const dataQuickStats = ref({})
const rowData = reactive({}) // Establecer rowData como un array de objetos, un objeto por fila
const gridApi = ref(null) // Opcional - para acceder a la API de Grid
const gridColumnApi = ref(null) // Opcional - para acceder a la API de columnas de Grid
const popupParent = document.querySelector('body')
const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left'
    },
    {
      statusPanel: 'agTotalRowCountComponent',
      align: 'center'
    },
    {
      statusPanel: 'agFilteredRowCountComponent',
      align: 'right'
    },
    {
      statusPanel: 'agAggregationComponent',
      align: 'right'
    }
  ]
}
const filtersChart = reactive({
  proveedor: '',
  year: '',
  startMonth: '',
  endMonth: ''
})
const disabledMonth = ref(true)

const years = ref([
  2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017
])

const months = ref([
  { id: 1, name: 'Enero' },
  { id: 2, name: 'Febrero' },
  { id: 3, name: 'Marzo' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Mayo' },
  { id: 6, name: 'Junio' },
  { id: 7, name: 'Julio' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Septiembre' },
  { id: 10, name: 'Octubre' },
  { id: 11, name: 'Noviembre' },
  { id: 12, name: 'Diciembre' }
])

// Desactivar el select de mes si no se ha seleccionado un año
const handleYearChange = () => {
  if (filtersChart.year === '') {
    filtersChart.startMonth = ''
    filtersChart.endMonth = ''
    disabledMonth.value = true
  } else {
    filtersChart.startMonth = 1
    filtersChart.endMonth = 12
    disabledMonth.value = false
  }
}

// Computada para mostrar los años del 2017 al actual
const filterYears = computed(() => {
  return years.value.filter((itemYear) => {
    return itemYear <= dayjs().year()
  })
})

// Computada para filtrar meses en el select de endMonth
const filterMonths = computed(() => {
  return months.value.filter((itemMonth) => {
    return parseInt(filtersChart.startMonth) <= parseInt(itemMonth.id)
  })
})

// Obtener API del evento onGridReady de la grid
const onGridReady = (params) => {
  gridColumnApi.value = params.columnApi
  gridApi.value = params.api

  // Aplicar el autoSize a todas las columnas apenas se cargue la grid
  /* nextTick(() => {
    autoSizeAll(false)
  }) */
}

// Cada definición de columna da lugar a una columna.
const columnDefs = reactive({
  value: [
    {
      headerName: 'Artículo',
      autoHeight: true,
      minWidth: 300,
      floatingFilter: true,
      filterParams: {
        closeOnApply: true,
        buttons: ['apply', 'reset']
      },
      spanHeaderHeight: true,
      cellRenderer: (params) => {
        return `
          <div class="flex flex-col">
           <span>${params.data.ItemCode}</span>
           <span class="font-semibold">${params.data.ItemName}</span>
          </div>
        `
      },
      valueGetter: (params) => {
        return params.data.ItemCode + ',' + params.data.ItemName
      }
    },
    {
      field: 'Price',
      headerName: 'Precio',
      minWidth: 90,
      autoHeight: true,
      valueGetter: (params) => {
        return helper.formatCurrency(params.data.Price)
      }
    },
    {
      field: 'CardName',
      headerName: 'Proveedor',
      minWidth: 175,
      autoHeight: true,
      floatingFilter: true,
      filterParams: {
        closeOnApply: true,
        buttons: ['apply', 'reset']
      }
    },
    {
      field: 'FechaActualizacionDigitada',
      headerName: 'Fecha Vigencia',
      autoHeight: true,
      minWidth: 130,
      floatingFilter: true,
      filterParams: {
        closeOnApply: true,
        buttons: ['apply', 'reset']
      }
    },
    {
      field: 'Motivo',
      headerName: 'Motivo',
      autoHeight: true,
      minWidth: 175,
      floatingFilter: true
    },
    {
      field: 'Comentario',
      headerName: 'Comentario',
      autoHeight: true,
      minWidth: 175,
      floatingFilter: true
    }
  ]
})

// DefaultColDef establece los accesorios comunes a todas las columnas de la grid
const defaultColDef = {
  sortable: true,
  filter: true,
  flex: 1,
  resizable: true
}

// Las opciones de rejilla establecen accesorios comunes a todas las rejillas.
const gridOptions = {
  suppressMenuHide: true
  // suppressContextMenu: true
}

// Hacer clic en las filas y obtener los datos de la fila
const onRowClicked = async (params) => {
  console.log('Row clic details data ---> ', params.data)
}

// Dar estilo a las filas
const getRowStyle = (params) => {
  return { cursor: 'pointer' }
}

// Opcional - para ajustar el tamaño de las columnas a la cuadrícula
const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit()
}

// Computada para obtener la información de compras del catálogo
const getInfoComprasCatalogo = computed(() => {
  return infoComprasCatalogoStore.getInfoComprasCatalogo
})

// Computada para obtener el store de filtros
const getterFiltersComprasCatalogo = computed(() => {
  return filtersCardComprasCatalogoStore.getFilters
})

// Función para obtener los datos de la API
const getDataTable = async (newFilter) => {
  try {
    const filterInfoCatalogo = {
      year: getterFiltersComprasCatalogo.value.year,
      month: getterFiltersComprasCatalogo.value.month,
      itemCode: getInfoComprasCatalogo.value.ItemCode
    }

    loading.value = true
    const { data } = await getListDetailsCatalogo(filterInfoCatalogo)
    if (data.length === 0) {
      loading.value = false
      return
    }
    loading.value = false
    await convertDataTable(data)
  } catch (error) {
    loading.value = false
    console.log('Error al obtener los datos ---> ', error)
  }
}

// Funcion para convertir los datos de la API
const convertDataTable = async (data) => {
  if (data.length === 0) {
    return
  }

  rowData.value = data.items.map((item) => {
    return {
      id: item.id,
      ItemCode: item.md,
      ItemName: getInfoComprasCatalogo.value.ItemName,
      Price: parseFloat(item.precio),
      CardCode: item.cardcode,
      CardName: item.nombre_corto_proveedor,
      Origen: item.origen,
      Motivo: item.motivo,
      Comentario: item.comentario,
      FechaActualizacionDigitada: dayjs(item.fecha_actualizacion_digitada).format('YYYY-MM-DD'),
      FechaVigencia: item.fecha_vigencia === null ? '' : dayjs(item.fecha_vigencia).format('YYYY-MM-DD'),
      FechaActualizacion: dayjs(item.fecha_actualizacion).format('YYYY-MM-DD')
    }
  })
  // Ordenarlo por el campo de date
  rowData.value.sort((a, b) => dayjs(b.FechaActualizacionDigitada) - dayjs(a.FechaActualizacionDigitada))
}

// Inicializando el chart
const initChart = () => {
  chart.value = Highcharts.chart(elChart.value, {
    chart: {
      zoomType: 'x'
    },
    title: {
      text: 'Evolución de precios del artículo',
      align: 'left',
      style: {
        fontSize: '12px'
      }
    },
    subtitle: {
      text: document.ontouchstart === undefined
        ? 'Haga clic y arrastre en el área del gráfico para ampliarlo' : 'Pellizque el gráfico para ampliarlo',
      align: 'left',
      style: {
        fontSize: '10px'
      }
    },
    xAxis: {
      type: 'category',
      tickWidth: 1, // ancho de las líneas verticales de los marcadores
      gridLineWidth: 1, // ancho de las líneas verticales de la grilla
      labels: {
        rotation: -45,
        style: {
          fontSize: '9px'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Precio ($)'
      },
      labels: {
        formatter: function () {
          const value = this.value
          if (value >= 1000000) {
            return '$' + (value / 1000000).toFixed(1) + 'M' // Formato para valores en millones
          } else if (value >= 1000) {
            return '$' + (value / 1000).toFixed(1) + 'K' // Formato para valores en miles
          } else {
            return '$' + value // Formato para valores pequeños
          }
        },
        x: 0,
        style: {
          fontSize: '9px'
        }
      }
    },
    legend: {
      enabled: false
    },
    tooltip: {
      crosshairs: true,
      animation: false,
      shared: true,
      useHTML: true,
      formatter: function () {
        // console.log('Tooltip: ', this.point)
        if (![undefined].includes(this.point)) {
          return `
            <div class="w-full flex justify-center border-b-2 mb-1">
              <span class="text-xs font-bold text-green-700">Inf. Precios (Catálogo)</span>
            </div>
            <div class="flex flex-col mb-3" style="width: 200px">
              <div class="flex justify-between mb-1">
                <span class="font-bold">Proveedor:</span>
                <span class="text-right">${this.point.options.proveedor}</span>
              </div>
              <div class="flex justify-between mb-1">
                <span class="font-bold">Precio:</span>
                <span class="text-right">${helper.formatCurrency(this.point.options.y, 2)}</span>
              </div>
              <div class="flex justify-between">
                <span class="font-bold">Fecha:</span>
                <span class="text-right">${this.point.options.name}</span>
              </div>
            </div>
          `
        }
      },
      style: {
        fontSize: '11px'
      }
    },
    plotOptions: {
      scatter: {
        lineWidth: 1,
        lineColor: '#1d9e14',
        color: '#1d9e14'
      }
    },
    series: [{
      type: 'scatter',
      // type: 'area',
      marker: {
        enabled: true, // activa los markers
        radius: 2 // ajusta el tamaño de los markers
      },
      data: chartData.value
    }],
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500 // Regla para pantallas con un ancho máximo de 500 píxeles
        },
        chartOptions: {
          yAxis: {
            title: {
              text: '' // Ocultar el título del eje y
            },
            labels: {
              style: {
                fontSize: '7px'
              }
            }
          },
          xAxis: {
            labels: {
              style: {
                fontSize: '7px'
              }
            }
          },
          title: {
            style: {
              fontSize: '9px'
            }
          },
          subtitle: {
            style: {
              fontSize: '7px'
            }
          }
        }
      }]
    },
    credits: {
      enabled: false
    }
  })
}

// Función para obtener los datos de la API
const getDataChart = async (filter) => {
  try {
    const filterInfoCatalogo = {
      year: filtersChart.year,
      startMonth: filtersChart.startMonth,
      endMonth: filtersChart.endMonth,
      proveedor: filtersChart.proveedor,
      itemCode: getInfoComprasCatalogo.value.ItemCode
    }

    loading.value = true
    const { data } = await getListDetailsChartCatalogo(filter ?? filterInfoCatalogo)
    if (data.length === 0) {
      loading.value = false
      return
    }
    loading.value = false
    await convertDataChart(data)
    await initChart()
  } catch (error) {
    loading.value = false
    console.log('Error al obtener los datos ---> ', error)
  }
}

// Funcion para convertir los datos de la API
const convertDataChart = async (data = []) => {
  if (data.length === 0) {
    return
  }

  chartData.value = data.items.map((item) => {
    return {
      name: dayjs(item.fecha_actualizacion_digitada).format('YYYY-MM-DD'),
      // Si el precio es null, se asigna 0
      y: [null, undefined].includes(item.precio) ? 0 : parseFloat(item.precio),
      proveedor: item.nombre_corto_proveedor
    }
  })

  dataQuickStats.value = {
    minPrice: data.quickStats.minPrice,
    maxPrice: data.quickStats.maxPrice,
    avgPrice: data.quickStats.avgPrice,
    totalItems: data.quickStats.totalItems
  }

  // Ordenar los datos por fecha
  chartData.value.sort((a, b) => {
    return dayjs(a.name) - dayjs(b.name)
  })
}

// Funcion para filtrar
const handleFilter = async () => {
  await getDataChart()
}

// Limpiar los filtros
const handleClear = () => {
  filtersChart.proveedor = ''
  filtersChart.year = ''
  filtersChart.startMonth = ''
  filtersChart.endMonth = ''

  handleFilter()
  disabledMonth.value = true
}

// Función para ajustar el contenido de las columnas
const autoSizeAll = (skipHeader) => {
  const allColumnIds = []
  gridColumnApi.value.getColumns().forEach((column) => {
    allColumnIds.push(column.getId())
  })
  gridColumnApi.value.autoSizeColumns(allColumnIds, skipHeader)
}

// Función para cambiar entre chart y table
const changeTypeTab = (typeTabValue) => {
  typeTab.value = typeTabValue
}

watch(() => getInfoComprasCatalogo.value.ItemCode, async (newValue, oldValue) => {
  if (![null, ''].includes(getInfoComprasCatalogo.value.ItemCode)) {
    if (typeTab.value === 'chart') {
      await getDataChart()
    } else {
      await getDataTable()
    }
  }
})

// Watch para el typeTab
watch(() => typeTab.value, async (newValue, oldValue) => {
  console.log('watch typeTab ---> ', newValue)
  if (![null, ''].includes(getInfoComprasCatalogo.value.ItemCode)) {
    if (newValue === 'chart') {
      await getDataChart()
    } else {
      await getDataTable()
    }
  }
})

// Onmounted
onMounted(async () => {
  console.log(![null, ''].includes(getInfoComprasCatalogo.value.ItemCode))
  if (![null, ''].includes(getInfoComprasCatalogo.value.ItemCode)) {
    console.log('onMounted tab 2')
    if (typeTab.value === 'chart') {
      await getDataChart()

      // Observar el resize del contenedor del chart
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          console.log(
            'El tamaño ha cambiado:',
            entry.contentRect.width,
            entry.contentRect.height
          )
        }
        chart.value.reflow()
      })
      observer.observe(containerChart.value)
    } else {
      await getDataTable()
    }
  }
})
</script>

<style scoped>

</style>
