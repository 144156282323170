<template>
  <div class="flex flex-col">
    <div class="flex justify-between">
      <span>{{ data.ItemCode }}</span>
      <i
        class="pi pi-info-circle text-sm text-yellow-500"
        v-tippy="{ content: `
          <div class='flex flex-col' style='max-width: 350px'>
            <span class='text-xs font-bold text-green-300'>Artículo</span>
            <span class='my-1 border border-dashed'></span>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Código:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${data.ItemCode}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Descripción:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${data.ItemName}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Agrupación:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${[null, '', 0, undefined].includes(data.U_PHR_AgrupCom) ? 'N/A' : data.U_PHR_AgrupCom}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Stock Total:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${ [null, '', 0, undefined].includes(data.StockGeneral) ? 0 : $h.formatNumber(parseInt(data.StockGeneral))}
              </span>
            </div>
            <span class='my-1 border border-dashed'></span>
            <span class='text-xs font-bold text-yellow-300'>Información</span>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Fecha:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${dateFormat1} (${dateFormat2})
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Proveedor. Precio Min:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${[null, '', 0, undefined].includes(data.ProvPriceMin) ? 'N/A' : data.ProvPriceMin}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Precio Min:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${$h.formatCurrency(data.PriceMin, 2)}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Proveedor Precio Max:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${[null, '', 0, undefined].includes(data.ProvPriceMax) ? 'N/A' : data.ProvPriceMax}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Precio Max:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${$h.formatCurrency(data.PriceMax, 2)}
              </span>
            </div>
            <span class='my-1 border border-dashed'></span>
            <span class='text-xs font-bold text-blue-300'>Catálogo Opcion 1</span>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Proveedor:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${[null, '', 0, undefined].includes(data.ProvCatOpc1) ? 'N/A' : data.ProvCatOpc1}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Precio:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${$h.formatCurrency(data.PriceCatOpc1, 2)}
              </span>
            </div>
            <span class='my-1 border border-dashed'></span>
            <span class='text-xs font-bold text-red-300'>Catálogo Opcion 2</span>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Proveedor:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${[null, '', 0, undefined].includes(data.ProvCatOpc2) ? 'N/A' : data.ProvCatOpc2}
              </span>
            </div>
            <div class='flex justify-between'>
              <div class='flex'>
                <span class='text-xxs font-semibold'>
                  Precio:
                </span>
              </div>
              <span class='text-xxs text-right font-normal'>
                ${$h.formatCurrency(data.PriceCatOpc2, 2)}
              </span>
            </div>
          </div>
        `, trigger: 'click' }"
      />
    </div>
    <span><span class="font-semibold">{{ data.ItemName }}</span></span>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import dayjs from 'dayjs'

const props = defineProps({
  params: Object
})

const data = ref(props.params.data)
const dateFormat1 = ref(dayjs(data.value.Year + '-' + data.value.Month).format('YYYY-MM'))
const dateFormat2 = ref(dayjs(data.value.Year + '-' + data.value.Month).format('MMMM [del] YYYY'))

// console.log('Data cell info -> ', data.value)
</script>

<style scoped>

</style>
