import { getFacturacion } from './service'
import { computed, ref } from 'vue'

const info = ref({
  cantidad: 0,
  total: 0,
  // items: {},
  items: [],
  subtotales: {}
})
const estaCargando = ref(true)
const searchable = ref(false)
const contratoSel = ref('')
const estado = ref(1)
const searchNivel2 = ref('')
const searchNivel3 = ref('')

const chartPorcentaje = computed(() => {
  if (info.value.total) return (info.value.total * 100) / 12500000000
  return 0
})

const chartData = computed(() => {
  const tmp = []
  let porcentaje = 0
  if (info.value.total) porcentaje = (info.value.total * 100) / 12500000000
  if (porcentaje) {
    tmp.push(porcentaje)
    tmp.push(100 - porcentaje)
  }
  return tmp
})

const setContratoSel = (value) => {
  console.log('setContratoSel', value)
  estado.value = 3
  contratoSel.value = value
}

const chartLabels = computed(() => {
  return [
    // helper.formatCurrency(info.value.total),
    // helper.formatCurrency((12500000000 - info.value.total))
  ]
})

const fetchFacturacion = (queryParams) => {
  estaCargando.value = true
  return getFacturacion(queryParams).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    console.error(err.message)
    estaCargando.value = false
  })
}

const setEstado = (value) => {
  estado.value = value
}

const getCantidad = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.Cantidad, 0)
}

const getTotal = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.Total, 0)
}

const getItems = computed(() => {
  return info.value.items
  /*
  const tmp = []
  for (const [key, value] of Object.entries(info.value.subtotales)) {
    const contrato = key
    const total = value.total
    const cantidad = value.cantidad
    tmp.push({ contrato, total, cantidad })
  }
  const filtered = tmp.filter(a =>
    a.contrato.toLowerCase().includes(searchNivel2.value) ||
    a.cantidad.toString().toLowerCase().includes(searchNivel2.value) ||
    a.total.toString().toLowerCase().includes(searchNivel2.value)
    )
  return filtered.reverse()
  */
})

const getResumen = computed(() => {
  // const rows = info.value.items[contratoSel.value]
  // return info.value.items

  const rows = info.value.items.find(a => a.label === contratoSel.value)
  if (rows === undefined) return []
  return rows.clientes
  /*
  if (rows === undefined) return []
  const filtered = rows.clientes.filter(a =>
    a.Cliente.toLowerCase().includes(searchNivel3.value) ||
    a.Cantidad.toString().toLowerCase().includes(searchNivel3.value) ||
    a.Total.toString().toLowerCase().includes(searchNivel3.value)
    )
  return filtered
  */
})

const clienteSel = ref('')
const getPbs = computed(() => {
  const rows = info.value.items.find(a => a.label === contratoSel.value)
  if (rows === undefined) return []
  const pbs = rows.clientes.find(b => b.label === clienteSel.value)
  if (pbs === undefined) return []
  return pbs.pbs
})

const setPbsSel = (value) => {
  estado.value = 4
  clienteSel.value = value
}

export {
  estaCargando,
  searchable,
  estado,
  info,
  chartPorcentaje,
  chartLabels,
  chartData,
  searchNivel2,
  searchNivel3,
  getItems,
  getResumen,
  getPbs,
  setPbsSel,
  fetchFacturacion,
  setEstado,
  getCantidad,
  getTotal,
  setContratoSel
}
