<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'
import { computed, defineComponent, onMounted, ref } from 'vue'
import { fetchCarteraClienteServicio } from './Service'
import axios from 'axios'

export default defineComponent({
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup () {
    const modalVisible = ref(false)
    const estaCargando = ref(true)
    const info = ref({
      cantidad: 0,
      total: 0,
      items: {},
      totales: {}
    })
    const totales = ref([])
    const totalesExpandido = ref(false)
    const seleccionado = ref([])
    const estado = ref(1)
    const searchNivel2 = ref('')
    const detalleInfo = ref([])

    /* Abrir modal */
    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    /* Funcion para expandir o contraer los totales */
    const setTotalesExpandido = (state) => {
      totalesExpandido.value = state
    }

    /* Funcion para obtener los datos */
    const getData = async (value) => {
      estaCargando.value = true
      return fetchCarteraClienteServicio(value).then(({ status, data }) => {
        estaCargando.value = false
        if (status === 200) {
          info.value = data
          getItems()
        }
      }).catch(err => {
        estaCargando.value = false
        console.error(err)
      })
    }

    /* Funcion para obtener los totales */
    const getItems = () => {
      for (const [key, value] of Object.entries(info.value.totales)) {
        const cliente = key
        const total = value.total
        totales.value.push({ cliente, total })
        // Ordenar alfabeticamente el cliente
        totales.value.sort((a, b) => {
          if (a.cliente < b.cliente) {
            return -1
          }
          if (a.cliente > b.cliente) {
            return 1
          }
          return 0
        })
      }
    }

    /* Funcion para obtener los totales expandidos o contraidos */
    const getTotales = computed(() => {
      if (totalesExpandido.value) {
        return totales.value
      } else {
        return totales.value.slice(0, 3)
      }
    })

    /* Funcion para setear el estado */
    const setEstado = (value) => {
      estado.value = value
      searchNivel2.value = ''
    }

    /* Funcion para setear el seleccionado */
    const setSeleccion = (value, state = null) => {
      if (state) {
        estado.value = state
      }
      seleccionado.value = value
      detalleInfo.value = info.value.items[value]
    }

    /* Funcion para buscar */
    const search = (value, key) => {
      // Dividir el rango de fechas
      const [fechaInicio, fechaFin] = searchNivel2.value.split(' ')

      if (fechaInicio && fechaFin) {
        const fechaInicioObj = new Date(fechaInicio)
        const fechaFinObj = new Date(fechaFin)

        detalleInfo.value = detalleInfo.value.filter(row => {
          const fechaRow = new Date(row.fecharadicado)
          return fechaRow >= fechaInicioObj && fechaRow <= fechaFinObj
        })
      } else {
        detalleInfo.value = info.value.items[seleccionado.value]
      }
    }

    /* Funcion para limpiar filtros */
    const clear = () => {
      searchNivel2.value = ''
      detalleInfo.value = info.value.items[seleccionado.value]
    }

    /* Obtener el total de capitacion */
    const totalCapitacion = computed(() => {
      let total = 0
      for (const dt of detalleInfo.value) {
        total += Number(dt.capitacion) ? Number(dt.capitacion) : 0
      }

      return total
    })
    /* Obtener el total de pbs */
    const totalPbs = computed(() => {
      let total = 0
      for (const dt of detalleInfo.value) {
        total += Number(dt.pbs) ? Number(dt.pbs) : 0
      }

      return total
    })
    /* Obtener el total de nopbs */
    const totalNoPbs = computed(() => {
      let total = 0
      for (const dt of detalleInfo.value) {
        total += Number(dt.nopbs) ? Number(dt.nopbs) : 0
      }

      return total
    })
    /* Obtener el total de cartera */
    const totalCartera = computed(() => {
      let total = 0
      for (const dt of detalleInfo.value) {
        total += Number(dt.totalsaldocartera) ? Number(dt.totalsaldocartera) : 0
      }

      return total
    })

    /* Funcion para descargar el excel */
    const handleExcelDownload = () => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/operacion/export-cartera-clientes-servicio`
      const res = axios.getUri({ url })
      window.open(res, '_blank')
    }

    /**/
    onMounted(async () => {
      await getData()
    })

    return {
      modalVisible,
      openModalConsulta,
      closeModalConsulta,
      estaCargando,
      info,
      getData,
      totales,
      setTotalesExpandido,
      totalesExpandido,
      getTotales,
      setSeleccion,
      estado,
      setEstado,
      searchNivel2,
      search,
      detalleInfo,
      seleccionado,
      totalCapitacion,
      totalPbs,
      totalNoPbs,
      totalCartera,
      clear,
      handleExcelDownload
    }
  }
})
</script>

<template>
  <div class="w-full p-5 box rounded-md zoom-in relative" style="z-index: 9999; overflow: auto">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>

    <div class="w-full flex justify-between">
      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px"
          v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>
    </div>

    <template v-if="estado === 1">
      <div class="flex flex-wrap w-full justify-between mb-1">
        <div class="font-medium truncate capitalize">Cartera clientes Por Servicio</div>
        <div
          class="cursor-pointer text-primary-1 dark:text-white"
          title="Descargar"
          @click="handleExcelDownload()"
        >
          <svg style="width:24px;height:24px" class="text-green-900" viewBox="0 0 24 24">
            <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
          </svg>
        </div>
      </div>
      <div class="flex w-full justify-between letra">
        <div class="font-bold cursor-pointer">
          Cliente
        </div>
        <div class="text-right cursor-pointer font-bold" style="width:110px; min-width:110px;">
          Total
        </div>
      </div>

      <div class="scroll-ajuste">
        <div
          v-for="(row,k) in getTotales"
          :key="k"
          class="flex w-full justify-between letra"
        >
          <div
            class="text-primary-1 cursor-pointer dark:text-white"
            @click="setSeleccion(row.cliente, 2)"
          >
            {{row.cliente}}
          </div>
          <div class="text-right" style="width:110px; min-width:110px;">
            {{$h.formatCurrency(row.total)}}
          </div>
        </div>
      </div>

      <div class="flex justify-between">
        <div
          class="text-primary-1 dark:text-white  rounded-md cursor-pointer w-1/12"
        >
          <div v-if="totalesExpandido" @click="setTotalesExpandido(false)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
            </svg>
          </div>
          <div v-else @click="setTotalesExpandido(true)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </div>
        </div>
      </div>
    </template>
    <template v-if="estado === 2">
      <div class="flex justify-between items-center">
        <div class="flex flex-col">
          <span class="font-medium truncate capitalize">Cartera clientes Por Servicio</span>
          <span class="font-medium truncate text-xs mb-2">({{ seleccionado }})</span>
        </div>
        <button
          type="button"
          class="btn btn-atras"
          @click="setEstado(1)"
        >
          <svg style="width:12px;height:12px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
          </svg>
        </button>
      </div>
      <div class="w-full flex">
        <InputMask
          id="basic"
          v-model="searchNivel2"
          mask="9999-99 9999-99"
          class="border border-gray-300 px-2 py-1 mr-2 rounded-md dark:text-primary-1"
          placeholder="yyyy-mm al yyyy-mm"
          :pt="{
            root: {
              style: 'height: 1.7rem; font-size: 0.75rem;'
            }
          }"
        />
        <Button
          icon="pi pi-search"
          class="p-button sm:mr-2 p-button-primary p-mt-auto my-auto sm:flex h-6"
          @click="search(searchNivel2)"
          v-tippy="{ content: 'Buscar' }"
          :pt="{
            icon: {
              style: 'font-size: 0.75rem;'
            }
          }"
        />
        <Button
          icon="pi pi-trash"
          class="p-button sm:mr-2 p-button-danger p-mt-auto my-auto sm:flex h-6"
          @click="clear()"
          v-tippy="{ content: 'Limpiar' }"
          :pt="{
            icon: {
              style: 'font-size: 0.75rem;'
            }
          }"
        />
      </div>
      <div class="scroll-ajuste mt-2">
        <table style="width: 100%;" class="table_cards">
          <thead>
            <tr>
              <th class="text-center" style="padding: 0 4px">Fec.Rad</th>
              <th class="text-right" style="padding: 0 4px">Capitacion</th>
              <th class="text-right" style="padding: 0 4px">PBS</th>
              <th class="text-right" style="padding: 0 4px">NOPBS</th>
              <th class="text-right" style="padding: 0 4px">Total</th>
            </tr>
          </thead>
          <tr
            class="hover:bg-gray-300" v-for="(row,k) in detalleInfo"
            :key="k"
          >
            <td class="text-center" style="min-width: 80px; padding: 0 4px">{{row.fecharadicado}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px">{{$h.formatCurrency(Number(row.capitacion))}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px">{{$h.formatCurrency(Number(row.pbs))}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px">{{$h.formatCurrency(Number(row.nopbs))}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px">{{$h.formatCurrency(Number(row.totalsaldocartera))}}</td>
          </tr>
          <!-- Agregar fila de totalizados -->
          <tr>
            <td class="text-center" style="min-width: 50px; padding: 0 4px; font-weight: 600;">Totales</td>
            <td class="text-right" style="width: 100px; padding: 0 4px; font-weight: 600;">{{$h.formatCurrency(Number(totalCapitacion))}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px; font-weight: 600;">{{$h.formatCurrency(Number(totalPbs))}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px; font-weight: 600;">{{$h.formatCurrency(Number(totalNoPbs))}}</td>
            <td class="text-right" style="width: 100px; padding: 0 4px; font-weight: 600;">{{$h.formatCurrency(Number(totalCartera))}}</td>
          </tr>
        </table>
      </div>
    </template>
  </div>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <highlightjs
      autodetect
      :code="info.query"
    />

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<style scoped>
.letra{
  font-size: 0.8em;
}
.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
