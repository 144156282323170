import { defineStore } from 'pinia'

export const useInfoComprasCatalogoStore = defineStore({
  id: 'infoComprasCatalogo',
  state: () => ({
    _infoCatalogo: {
      ItemCode: '',
      ItemName: '',
      Year: '',
      Month: '',
      U_PHR_AgrupCom: '',
      Cum: ''
    },
    _expandedGeneral: false,
    _loading: false
  }),
  getters: {
    getInfoComprasCatalogo: (state) => state._infoCatalogo,
    getExpandedGeneral: (state) => state._expandedGeneral,
    getLoading: (state) => state._loading
  },
  actions: {
    setUpdateInfoComprasCatalogo (filters) {
      this._infoCatalogo = filters
    },
    setExpandedGeneral (expanded) {
      this._expandedGeneral = expanded
    },
    setLoading (loading) {
      this._loading = loading
    }
  }
})
