<template>
  <div class="box p-5 tabla-digiturnos">
    <DataTable
        :value="props.data"
        v-model:expandedRows="expandedRows"
        :loading="loading"
        showGridlines
        class="p-datatable-sm"
      >
        <template #header>
         <table class="table">
          <thead>
            <tr>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell"></th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">Sede</th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">Total Turnos</th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">Total OV Sap</th>
              <!-- <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">Total MD Sap</th> -->
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">T. Min en Sala
                <br><span class="text-xs">(hh:mm:ss)</span></th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">T. Max en Sala
                <br><span class="text-xs">(hh:mm:ss)</span></th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">T. Prom en Sala
                <br><span class="text-xs">(hh:mm:ss)</span></th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">T. Min en Atencion
                <br><span class="text-xs">(hh:mm:ss)</span></th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">T. Max en Atencion
                <br><span class="text-xs">(hh:mm:ss)</span></th>
              <th class="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">T. Prom en Atencion
                <br><span class="text-xs">(hh:mm:ss)</span></th>
              </tr>
            </thead>
          </table>
        </template>
        <template #empty>No se encontraron turnos</template>
        <template #loading>Cargando...</template>
        <Column :expander="true" bodyClass="desplegable" headerStyle="width: 10%" style="min-width: 10%; font-size:11px;" headerClass="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell"/>
        <Column field="sede"  style="min-width: 60%; font-size:11px;" headerClass="p-3 text-xs font-bold uppercase bg-gray-700 text-white text-center hidden lg:table-cell">
          <template #body="{data}">
            <div class="flex flex-grow justify-between border-md-b">
              <div @click="DetalleServicio(data.location_id)" class="w-2/3 my-auto text-xs font-bold uppercase md:hidden">Sede</div>
              <div class="my-auto w-name-sede text-center">{{data.name}}</div>
            </div>
          </template>
        </Column>
        <Column field="totalTurnos"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between border-md-b">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">Total Turnos</div>
                <div class="my-auto w-1/3 text-center">{{data.totalTurnos}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    Total turnos
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.total_turnos}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="totalOv"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-grow justify-between border-md-b">
              <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">Total OV Sap</div>
              <div class="my-auto w-1/3 text-center">{{data.totalOv}}</div>
            </div>
          </template>
        </Column>
        <Column field="minimoTiempoSala"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between border-md-b">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">T. Min en Sala
                <br><span class="text-xs">(hh:mm:ss)</span></div>
                <div class="my-auto w-1/3 text-center">{{data.minimoTiempoSala}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    T. Min en sala
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.min_tiempo_sala}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="maximoTiempoSala"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between border-md-b">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">T. Max en Sala
                <br><span class="text-xs">(hh:mm:ss)</span></div>
                <div class="my-auto w-1/3 text-center">{{data.maximoTiempoSala}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    T. Max en Sala
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.max_tiempo_sala}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="promedioTiempoSala"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between border-md-b">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">T. Prom en Sala
                <br><span class="text-xs">(hh:mm:ss)</span></div>
                <div class="my-auto w-1/3 text-center">{{data.promedioTiempoSala}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    T. Prom en Sala
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.promedio_tiempo_sala}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="minimoTiempoAtencion"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between border-md-b">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">T. Min en Atencion
                <br><span class="text-xs">(hh:mm:ss)</span></div>
                <div class="my-auto w-1/3 text-center">{{data.minimoTiempoAtencion}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    T. Min en Atencion
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.min_tiempo_atencion}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="maximoTiempoAtencion"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between border-md-b">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">T. Max en Atencion
                <br><span class="text-xs">(hh:mm:ss)</span></div>
                <div class="my-auto w-1/3 text-center">{{data.maximoTiempoAtencion}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    T. Max en Atencion
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.max_tiempo_atencion}}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="promedioTiempoAtencion"  style="min-width: 60%; font-size:11px;">
          <template #body="{data}">
            <div class="flex flex-wrap w-full">
              <div class="flex flex-grow justify-between">
                <div @click="DetalleServicio(data.location_id)" class="w-2/3 text-xs font-bold uppercase md:hidden">T. Prom en Atencion
                <br><span class="text-xs">(hh:mm:ss)</span></div>
                <div class="my-auto w-1/3 text-center">{{data.promedioTiempoAtencion}}</div>
              </div>
              <div :class="`detalle-${data.location_id}`" class="detalle orders-subtable" style="width: 100%">
                <div class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    Servicio
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    T. Prom en Atencion
                  </div>
                </div>
                <div v-for="(item, key) in data.servicios" :key="key" class="flex flex-grow">
                  <div class="flex flex-col w-2/3">
                    {{item.servicio}}
                  </div>
                  <div class="flex flex-col w-1/3 text-center">
                    {{item.promedio_tiempo_atencion}}
                  </div>
                </div>
              </div>
          </div>
          </template>
        </Column>
        <template #expansion="{data}">
          <div class="orders-subtable" style="width: 100%">
            <DataTable
              :value="data.servicios"
              showGridlines
              class="p-datatable-sm"
            >
              <Column header="Servicio" headerStyle=""  style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.servicio}}
                </template>
              </Column>
              <Column header="Total turnos" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.total_turnos}}
                </template>
              </Column>
              <Column header="T. Min en sala" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.min_tiempo_sala}}
                </template>
              </Column>
              <Column header="T. Max en Sala" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.max_tiempo_sala}}
                </template>
              </Column>
              <Column header="T. Prom en Sala" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.promedio_tiempo_sala}}
                </template>
              </Column>
              <Column header="T. Min en Atencion" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.min_tiempo_atencion}}
                </template>
              </Column>
              <Column header="T. Max en Atencion" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.max_tiempo_atencion}}
                </template>
              </Column>
              <Column header="T. Prom en Atencion" headerStyle="" style="min-width: 30%; font-size:11px;">
                <template #body="{data}">
                  {{data.promedio_tiempo_atencion}}
                </template>
              </Column>
            </DataTable>
          </div>
        </template>
        <template #footer>
          <table class="table">
          <tbody>
            <tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">Totales</span>
                Totales
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">Total Turnos</span>
                {{ sumTotalTurnos }}
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">Total OV Sap</span>
                {{ sumTotalOv }}
              </td>
              <!-- <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">Total MD Sap</span>
                {{ sumTotalMd }}
              </td> -->
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">T. Min en Sala</span>
                {{ promTotalMinSala }}
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">T. Max en Sala</span>
                {{ promTotalMaxSala }}
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">T. Prom en Sala</span>
                {{ promTotalProSala }}
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">T. Min en Atencion</span>
                {{ promTotalMinAte }}
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">T. Max en Atencion</span>
                {{ promTotalMaxAte }}
              </td>
              <td class="w-full lg:w-auto p-3 bg-gray-700 text-white sm:text-center text-right block lg:table-cell relative lg:static">
                <span class="lg:hidden absolute top-0 left-0 bg-gray-700 text-white px-2 py-1 text-xs font-bold uppercase">T. Prom en Atencion</span>
                {{ promTotalProAte }}
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      </DataTable>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import dayjs from 'dayjs'
export default {
  name: 'TablaDigiturnos',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      },
      required: true
    }
  },
  setup (props) {
    const sumTotalTurnos = computed(() => {
      return props.data.reduce((acc, item) => acc + item.totalTurnos, 0)
    })
    const sumTotalOv = computed(() => {
      return props.data.reduce((acc, item) => acc + item.totalOv, 0)
    })
    const promTotalMinSala = computed(() => {
      const second = props.data.reduce((acc, item) => {
        if ((typeof item.minimoTiempoSala === 'undefined' || item.minimoTiempoSala === '---')) return acc + 0
        return acc + parseInt(item.minimoTiempoSala.substr(0, 2)) * 3600 + parseInt(item.minimoTiempoSala.substr(3, 2)) * 60 + parseInt(item.minimoTiempoSala.substr(6, 2))
      }, 0)
      return dayjs('1999-01-01 00:00:00').add(dayjs.duration({ second: (second / props.data.length) })).format('HH:mm:ss')
    })
    const promTotalMaxSala = computed(() => {
      const second = props.data.reduce((acc, item) => {
        if ((typeof item.maximoTiempoSala === 'undefined' || item.maximoTiempoSala === '---')) return acc + 0
        return acc + parseInt(item.maximoTiempoSala.substr(0, 2)) * 3600 + parseInt(item.maximoTiempoSala.substr(3, 2)) * 60 + parseInt(item.maximoTiempoSala.substr(6, 2))
      }, 0)
      return dayjs('1999-01-01 00:00:00').add(dayjs.duration({ second: (second / props.data.length) })).format('HH:mm:ss')
    })
    const promTotalProSala = computed(() => {
      const second = props.data.reduce((acc, item) => {
        if ((typeof item.promedioTiempoSala === 'undefined' || item.promedioTiempoSala === '---')) return acc + 0
        return acc + parseInt(item.promedioTiempoSala.substr(0, 2)) * 3600 + parseInt(item.promedioTiempoSala.substr(3, 2)) * 60 + parseInt(item.promedioTiempoSala.substr(6, 2))
      }, 0)
      return dayjs('1999-01-01 00:00:00').add(dayjs.duration({ second: (second / props.data.length) })).format('HH:mm:ss')
    })
    const promTotalMinAte = computed(() => {
      const second = props.data.reduce((acc, item) => {
        if ((typeof item.minimoTiempoAtencion === 'undefined' || item.minimoTiempoAtencion === '---')) return acc + 0
        return acc + parseInt(item.minimoTiempoAtencion.substr(0, 2)) * 3600 + parseInt(item.minimoTiempoAtencion.substr(3, 2)) * 60 + parseInt(item.minimoTiempoAtencion.substr(6, 2))
      }, 0)
      return dayjs('1999-01-01 00:00:00').add(dayjs.duration({ second: (second / props.data.length) })).format('HH:mm:ss')
    })
    const promTotalMaxAte = computed(() => {
      const second = props.data.reduce((acc, item) => {
        if ((typeof item.maximoTiempoAtencion === 'undefined' || item.maximoTiempoAtencion === '---')) return acc + 0
        return acc + parseInt(item.maximoTiempoAtencion.substr(0, 2)) * 3600 + parseInt(item.maximoTiempoAtencion.substr(3, 2)) * 60 + parseInt(item.maximoTiempoAtencion.substr(6, 2))
      }, 0)
      return dayjs('1999-01-01 00:00:00').add(dayjs.duration({ second: (second / props.data.length) })).format('HH:mm:ss')
    })
    const promTotalProAte = computed(() => {
      const second = props.data.reduce((acc, item) => {
        if ((typeof item.promedioTiempoAtencion === 'undefined' || item.promedioTiempoAtencion === '---')) return acc + 0
        return acc + parseInt(item.promedioTiempoAtencion.substr(0, 2)) * 3600 + parseInt(item.promedioTiempoAtencion.substr(3, 2)) * 60 + parseInt(item.promedioTiempoAtencion.substr(6, 2))
      }, 0)
      return dayjs('1999-01-01 00:00:00').add(dayjs.duration({ second: (second / props.data.length) })).format('HH:mm:ss')
    })
    /* const sumTotalMd = computed(() => {
      return props.data.reduce((acc, item) => acc + item.totalMd, 0)
    }) */
    const DetalleServicio = (id = null) => {
      var concat = 'detalle-' + id
      if (id) {
        var element = document.getElementsByClassName(concat)
        element.forEach(element => {
          element.classList.toggle('mostrar')
        })
      }
      // element.classList.toggle('mostrar')
    }
    const customers1 = ref([])
    const expandedRows = ref([])
    const loading = ref(false)
    return {
      props,
      sumTotalTurnos,
      sumTotalOv,
      customers1,
      expandedRows,
      loading,
      DetalleServicio,
      promTotalMinSala,
      promTotalMaxSala,
      promTotalProSala,
      promTotalMinAte,
      promTotalMaxAte,
      promTotalProAte
      // sumTotalMd
    }
  }
}
</script>

<style lang="scss">
.tabla-digiturnos{
.detalle{
  display: none;
}
.mostrar{
  display: block !important;
}
.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.desplegable{
  visibility: visible;
}
.border-md-b{
  border: 0
}
.w-name-sede{
  width: 100%;
}
@media (max-width: 1000px) {
  .desplegable{
    visibility: hidden!important;
  }
  .border-md-b{
    padding-bottom: 1px;
    border-bottom: 1px solid #00000020;
  }
  .w-name-sede{
    width: 33%;
  }
}
}
.desplegable{
  visibility: visible;
}
@media (max-width: 1000px) {
  .desplegable{
    visibility: hidden!important;
  }
}
</style>
