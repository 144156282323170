import http from '../../../../../../../libs/http'
import { helper } from '../../../../../../../utils/helper'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/digiturno`

const multiplesHttp = (query = {}) => {
  const params = {
    fecha_inicial: helper.formatDate(query.fecha_inicial, 'YYYY-MM-DD'),
    fecha_final: helper.formatDate(query.fecha_final, 'YYYY-MM-DD')
  }

  const url = [
    // http.get(`${api}/turnos-total-md`, { params: { ...params } }),
    http.get(`${api}/turnos-total`, { params: { ...params } }),
    http.get(`${api}/turnos-total-ov`, { params: { ...params } }),
    http.get(`${api}/turnos-total-servicio`, { params: { ...params } }),
    http.get(`${api}/turnos-tiempo-sala`, { params: { ...params } }),
    http.get(`${api}/turnos-tiempo-atencion`, { params: { ...params } }),
    http.get(`${api}/turnos-tiempo-sala-servicio`, { params: { ...params } })
    // http.get(`${api}/turnos-tiempo-atencion-servicio`, { params: { ...params } })
  ]
  return Promise.all(url).then((values) => {
    // console.log('Values: ', values)
    if (values.some(element => element.status !== 200)) {
      return []
    } else {
      return values.map(e => {
        return e.data
      })
    }
  }).catch((err) => {
    console.error(err.message)
  })
}

export {
  multiplesHttp
}
