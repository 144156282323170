import { computed, ref } from 'vue'
import { pagosProveedores } from './Service'
import { helper } from '../../../../../utils/helper'
import XLSX from 'xlsx'

const info = ref({
  info: {
    facturacionYear: 0,
    facturacionMonth: 0
  }
})
const evento = ref({
  evento: {
    cliente: '',
    total: 0,
    costo: 0,
    cantidad: 0
  }
})
const capita = ref([])
const med = ref([])
const adm = ref([])
const totalMed = ref(0)
const totalAdm = ref(0)
const estaCargando = ref(true)
const searchable = ref(false)
const searchNivel2 = ref('')
const estado = ref(1)
const typeModalidad = ref('Medicamentos')

const getData = (payload = {}) => {
  estaCargando.value = true
  return pagosProveedores(payload).then(({ status, data }) => {
    if (status === 200) {
      data.result.map(m => {
        if (m.codproveedor.substr(0, 2) === 'PM') {
          totalMed.value += m.totalpagado
        } else if (m.codproveedor.substr(0, 2) === 'PA') {
          totalAdm.value += m.totalpagado
        }
      })
    }
    med.value = data.result.filter(f => f.codproveedor.substr(0, 2) === 'PM')
    adm.value = data.result.filter(f => f.codproveedor.substr(0, 2) === 'PA')
    estaCargando.value = false
  }).catch(err => {
    estaCargando.value = false
    console.error(err)
  })
}

const getDataProv = computed(() => {
  const tmp = []
  let infoType
    if (typeModalidad.value === 'Medicamentos') {
      infoType = med.value
    } else {
      infoType = adm.value
    }
    for (const value of infoType) {
      const proveedor = value.proveedor ? value.proveedor : 'Sin Nombre'
      const total = value.totalpagado ? value.totalpagado : 0
      const visible = false
      tmp.push({ proveedor, total, visible })
    }
    const filtered = tmp.filter(a =>
      a.proveedor.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
      a.total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
      )
    return filtered
})

const getNameTypeModalidad = computed(() => {
  if (typeModalidad.value === 'Medicamentos') {
    return 'Medicamento'
  }
  return 'Administrativo'
})
const setEstado = (value, type = 'Medicamentos') => {
  estado.value = value
  typeModalidad.value = type
}

const handleExcelDownload = () => {
  const dataExport = []

  for (const value of getDataProv.value) {
    dataExport.push({
      proveedor: value.proveedor,
      total: helper.formatNumber(parseInt(value.total))
    })
  }
  const data = XLSX.utils.json_to_sheet(dataExport)
  const workbook = XLSX.utils.book_new()
  const filename = 'PagosProveedores'
  XLSX.utils.book_append_sheet(workbook, data, filename)
  XLSX.writeFile(workbook, `${filename}.xlsx`)
}

export {
  estaCargando,
  searchable,
  searchNivel2,
  info,
  evento,
  capita,
  totalMed,
  totalAdm,
  estado,
  setEstado,
  getData,
  getDataProv,
  typeModalidad,
  getNameTypeModalidad,
  handleExcelDownload
}
