<template>
  <section class="relative">
    <div v-if="loading" class="rounded-md icono-carga p-8 pos-loading">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="box w-full" style="overflow:hidden;">
      <div
      id="grafica"
      ref="elGrafica"
      style="height:500px;"
      class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { apiTopComprasProveedoresMx } from '../../services'
// import { truncate } from 'lodash'
import currency from 'currency.js'
import { useStore } from 'vuex'
const navegadorVentanaAncho = 1024
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter)
      ) return
      fetchResults(filter)
    })
    /*
    * Refs and reactives
    */
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const loading = ref(false)
    const colorLabel = ref('white')
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    /*
    * Methods
    */
    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          top: 15,
          left: 15,
          text: 'Top Compras por Mx'
        },
        grid: {
          left: 0,
          right: 15,
          bottom: 70,
          top: 85,
          containLabel: true
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            saveAsImage: {}
          }
        },
        tooltip: {
          top: 0,
          position: (pos) => {
            if (window.innerWidth > navegadorVentanaAncho) {
              return pos[0]
            } else {
              return ['5%', '0']
            }
          },
          formatter (a) {
            let Tipos = ''
            Tipos = Tipos + `<div class="title-tool"><p>MD<p><div style="width:80%"><p>${a.name}<p></div></div><br/>`
            Tipos = Tipos + `<div class="title-tool" style="border: 0!important;"><div style="width:50%"><p>Valor<p></div><span style="width:50%;text-align: right;">${getChartTotal(a.data)}</span></div><br/>`
            Tipos = Tipos + `<div class="title-tool" style="border: 0!important;"><div style="width:50%"><p>Cantidad<p></div><span style="width:50%;text-align: right;">${a.data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</span></div><br/>`
            return Tipos
          }
        },
        legend: {
          show: false,
          data: ['Medicos']
        },
        yAxis: [
          {
            show: false
          }
        ],
        xAxis: {
          data: getChartLabels(),
          axisLabel: {
            interval: 0,
            fontSize: 10,
            width: 60,
            overflow: 'break'
            // formatter (value) {
            //   return value.split(' ').join('\n')
            // }
          }
        },
        series: [
          {
            name: 'Medicos',
            type: 'bar',
            showBackground: true,
            data: getChartData(),
            itemStyle: {
              borderRadius: 9,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              color: colorLabel.value,
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value,
              formatter (d) {
                return `${parseInt((d.value / 1000), 10)}MIL`
              }
            }
          }
        ]
      }
      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          data: getChartLabels(),
          show: true
        },
        series: [
          {
            showBackground: true,
            name: 'Medicos',
            type: 'bar',
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }

      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }

      chart.setOption(option)
    }

    const getChartData = () => {
      // Por Cantidad o por Total
      return chartData.value.map(a => parseInt(a.Cantidad, 10))
      // obj.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    }

    const getChartLabels = () => {
      return chartData.value.map(a => a.ItemName)
    }
    const getChartTotal = (cant) => {
      const obj = chartData.value.filter(a => parseInt(a.Cantidad, 10) === cant).map(m => currency(m.Total, { precision: 0, separator: '.' }).format())
      return obj
    }
    const setChartData = (values = []) => {
      chartData.value = values.data
    }

    watch(() => props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter)
      ) return
      fetchResults(filter)
    })

    const fetchResults = (filters) => {
      loading.value = true
      return apiTopComprasProveedoresMx(filters).then(({ data, status }) => {
        // console.log('dataTop', data)
        setChartData(data)
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
      }).finally(() => {
        loading.value = false
      })
    }

    // const fetchResultsTipo = (filters) => {
    //   loading.value = true
    //   return apiClienteTipoPqr(filters).then(({ data, status }) => {
    //     setChartTipo(data)
    //     reloadChart()
    //   }).catch((err) => {
    //     console.error(err.message)
    //   }).finally(() => {
    //     loading.value = false
    //   })
    // }

    /*
    * Hooks
    */
    onMounted(() => {
      fetchResults(props.filters)
      initChart()
    })

    return {
      chart,
      loading,
      elGrafica
    }
  }
}
</script>

<style>
.pos-loading {
  height:350px;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  width:100%
}
</style>
