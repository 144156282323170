<template>
  <div
  class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
  style="display:flex;"
  >
    <div class="flex align-baseline icon-calendar mr-2">
      <CalendarIcon class="my-auto inset-y-0 left-0" size="30" />
    </div>
    <div class="p-2" :class="props.marco ? 'border':''">
      <div style="width: auto; display: flex;">
        <div class="mr-2">
          <label for="filterYear">Año</label>
          <select
          v-model="year"
          id="filterYear"
          class="form-control form-control-sm"
          @change="handleYear"
          >
            <option :value="null">--</option>
            <option v-for="(row,k) in anos" :key="k" :value="row">{{row}}</option>
          </select>
        </div>
        <div v-if="tieneMes" class="mr-2">
          <label for="">Mes {{rango? 'Inicial': ''}}</label>
          <select
          v-model="month"
          name=""
          id=""
          class="form-control form-control-sm"
          @change="handleMonth"
          >
            <option :value="null">--</option>
            <option v-for="(row,k) in meses" :key="k" :value="row.id">{{row.nombre}}</option>
          </select>
        </div>
        <div v-if="rango" class="mr-2">
          <label for="">Mes Final</label>
          <select
          v-model="month2"
          name=""
          id=""
          class="form-control form-control-sm"
          @change="handleMonth2"
          >
            <option :value="null">--</option>
            <option v-for="(row,k) in filterMonths" :key="k" :value="row.id">{{row.nombre}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'

export default defineComponent({
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    tieneMes: {
      type: Boolean,
      default: true
    },
    rango: {
      type: Boolean,
      default: false
    },
    marco: {
      type: Boolean,
      default: true
    }
  },
  setup (props, context) {
    const year = ref(props.modelValue.year)
    const month = ref(props.modelValue.month)
    const month2 = ref(props.modelValue.month2)
    const meses = [
        { id: 1, nombre: 'Enero' },
        { id: 2, nombre: 'Febrero' },
        { id: 3, nombre: 'Marzo' },
        { id: 4, nombre: 'Abril' },
        { id: 5, nombre: 'Mayo' },
        { id: 6, nombre: 'Junio' },
        { id: 7, nombre: 'Julio' },
        { id: 8, nombre: 'Agosto' },
        { id: 9, nombre: 'Septiembre' },
        { id: 10, nombre: 'Octubre' },
        { id: 11, nombre: 'Noviembre' },
        { id: 12, nombre: 'Diciembre' }
      ]

    const getYears = () => {
      const years = []
      const yearNow = new Date().getFullYear()
      for (let index = 0; index < 5; index++) {
        years.push(yearNow - index)
      }
      return years
    }

    const handleYear = (e) => {
      let value = null
      if (e.target.value) value = +e.target.value
      year.value = value
      emitir()
    }

    const handleMonth = (e) => {
      let value = null
      if (e.target.value) value = +e.target.value
      month.value = value
      emitir()
    }

    const handleMonth2 = (e) => {
      let value = null
      if (e.target.value) value = +e.target.value
      month2.value = value
      emitir()
    }

    const filterMonths = computed(() => {
      return meses.filter((itemMonth) => {
        return parseInt(props.modelValue.month) <= parseInt(itemMonth.id)
      })
    })

    const emitir = () => {
      if (props.rango) {
        context.emit('update:modelValue', { year, month, month2 })
      } else {
        context.emit('update:modelValue', { year, month })
      }
    }

    return {
      props,
      year,
      month,
      month2,
      handleYear,
      handleMonth,
      handleMonth2,
      filterMonths,
      anos: getYears(),
      meses
    }
  }
})
</script>

<style lang="scss">
@media(max-width: 1000px){
  .icon-calendar{
    display: none!important;
  }
}
</style>
