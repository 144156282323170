import http from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}`

const getFacturacionCapita = (query) => http.get(`${api}/operacion/consumo-cliente-capita`, { params: { ...query } })
const getFacturacion = (query) => http.get(`${api}/operacion/consumo-cliente`, { params: { ...query } })

export {
  getFacturacion,
  getFacturacionCapita
}
