<template>
  <section class="text-xs col-span-12 sm:col-span-12  md:col-span-6 xl:col-span-4 intro-y">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class=" box p-5 zoom-in scroll-ajuste">

      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px"
          v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>

      <template v-if="estado === 1">
        <div class="font-medium text-sm truncate">Ventas x Sede</div>
        <div class="flex items-center">
          <div class="w-6/12 flex-none" @click="setEstado(2, 'evento')">
            <div class="font-normal truncate">Evento</div>
            <div class="text-gray-600 mt-1">{{$h.formatCurrency(info.Evento.total)}}</div>
            <div class="font-normal mt-1">Cantidad</div>
            <div class="text-gray-600">{{$h.formatNumber(info.Evento.cantidad)}}</div>
          </div>
          <div class="w-6/12 flex-none" @click="setEstado(2, 'capita')">
            <div class="font-normal truncate">Capita</div>
            <div class="text-gray-600 mt-1">{{$h.formatCurrency(info.Capita.total)}}</div>
            <div class="font-normal mt-1">Cantidad Sedes</div>
            <div class="text-gray-600">{{$h.formatNumber(info.Capita.cantidad)}}</div>
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
        <div class="flex items-center justify-between w-full">
          <div class="font-medium truncate text-sm">Ventas x Sede ({{ getNameTypeModalidad }})</div>
          <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(1, typeModalidad)"
            >
              <svg style="width:12px;height:12px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
              </svg>
            </button>
        </div>
        <div v-if="searchable" class="w-full flex justify-between">
          <input
          v-model="searchNivel2"
          type="search"
          placeholder="Buscar"
          class="border border-gray-400 px-2 py-1 rounded-md dark:text-primary-1"
          >
        </div>
          <table style="width: 100%" class="table_cards">
            <thead>
              <tr>
                <th class="text-left">Dpto</th>
                <th class="text-right">Cant. Sedes</th>
                <th class="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr class="hover:bg-gray-300" v-for="(row,k)  in getItems" :key="k">
                <td class="text-left">
                  <div
                  style="display: flex; align-items: center;"
                  @click.stop="setContratoSel(row.contrato)"
                  >
                    <div class="dark:text-white text-primary-1" style="position: relative; top: 1px; "> {{row.contrato}} </div>
                  </div>
                </td>
                <td class="text-right">{{$h.formatNumber(row.cantidad)}}</td>
                <td class="text-right">{{$h.formatCurrency(row.total)}}</td>
              </tr>
            </tbody>
          </table>
      </template>
      <template v-if="estado === 3">
        <div class="flex justify-between items-center w-full">
          <div class="font-medium truncate text-sm">Ventas x Sede ({{ getNameTypeModalidad }})</div>
          <button
          type="button"
          class="btn btn-atras"
          @click="setEstado(2, typeModalidad)"
          >
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <div class="w-full flex justify-between">
          <input
          v-model="searchNivel3"
          type="search"
          class="border border-gray-300 px-2 py-1 rounded-md dark:text-primary-1"
          placeholder="Buscar"
          >
        </div>

        <div class="w-full flex items-center text-sm">
          <div
            class="table_cards w-full"
          >
            <div class="flex w-full">
              <div style="width:55%;" class="font-bold">Sede</div>
              <div v-if="typeModalidad === 'evento'" style="width:10%;" class="text-right font-bold">Cant</div>
              <div :style="typeModalidad === 'evento' ? 'width:35%' : 'width:45%'" class="text-right font-bold">Total</div>
            </div>
            <template
              class="hover:bg-gray-300" v-for="(row,k) in getResumen"
              :key="k"
            >
              <div class="w-full flex">
                <!-- @click="setPbsSel(row.label)" -->
                <div
                  class="text-left dark:text-white text-primary-1"
                  style="width:55%;"
                  @click="row.visible = !row.visible"
                >
                  {{row.label}}
                </div>
                <div
                  class="text-right"
                  style="width:10%;"
                  v-if="typeModalidad === 'evento'"
                >
                  {{$h.formatNumber(row.cantidad)}}
                </div>
                <div
                  class="text-right"
                  :style="typeModalidad === 'evento' ? 'width:35%' : 'width:45%'"
                >
                  {{$h.formatCurrency(row.total)}}
                </div>
              </div>
              <div v-if="row.visible" class="w-full flex flex-col">
                <div class="flex w-full">
                  <div style="width:40%;" class="font-bold pl-2">Cliente</div>
                  <div style="width:30%;" class="text-right font-bold">Vlr Factura</div>
                  <div style="width:30%;" class="text-right font-bold">Vlr Costo</div>
                </div>
                <div v-for="(row3,m) in row.children" :key="m" class="w-full flex">
                  <div style="width:40%;" class="text-left pl-2">{{row3.label}}</div>
                  <div style="width:30%;" class="text-right">{{$h.formatCurrency(row3.total)}}</div>
                  <div style="width:30%;" class="text-right">{{$h.formatCurrency(row3.costo)}}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </section>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <TabView>
      <TabPanel header="Query Evento">
        <highlightjs
          autodetect
          :code="info.Evento.query"
        />
      </TabPanel>
      <TabPanel header="Query Capita">
        <highlightjs
          autodetect
          :code="info.Capita.query"
        />
      </TabPanel>
    </TabView>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { defineComponent, ref, watchEffect } from 'vue'
import * as parameters from './Hook'
export default defineComponent({
  name: 'CardConsumoBodegas',
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup (props, context) {
    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    watchEffect(() => {
      parameters.fetchFacturacion({
        year: props.year,
        month: props.month
      })
    })
    return {
      ...parameters,
      modalVisible,
      openModalConsulta,
      closeModalConsulta
    }
  }
})
</script>

<style scoped>
.table_cards{
  text-align: start;
  font-size: 0.7rem;
  margin-top: 10px;
  line-height: 1.1rem;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
