<template>
  <div class="w-full p-5 box rounded-md zoom-in overflow-y-auto">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>

    <div class="w-full flex justify-between">
      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px"
          v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>
      <div class="capitalize font-medium">Cartera clientes</div>
    </div>
    <div
    v-if="estado === 1 "
    class="flex w-full flex-col letra"
    >
      <div
      v-for="(value,key) in getTotales"
      :key="key"
      class="flex justify-between uppercase"
      >
        <div class="text-primary-1 dark:text-white">{{key}}</div>
        <div class="">{{$h.formatCurrency(value)}}</div>
      </div>
      <div class="flex justify-between">
        <div
        class="text-primary-1 dark:text-white  rounded-md cursor-pointer w-1/12"
        >
          <div v-if="totalesExpandido" @click="setTotalesExpandido(false)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z" />
            </svg>
          </div>
          <div v-else @click="setTotalesExpandido(true)">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
            </svg>
          </div>
        </div>
        <div
        class="text-primary-1 dark:text-white  rounded-md cursor-pointer text-right w-1/2 "
        style="width:max-content;"
        @click="setEstado(2)"
        >
          Ver mas
        </div>
      </div>
    </div>
    <div v-if="estado === 2">
      <div class="flex justify-end  letra">
        <button
        type="button"
        class="btn btn-atras"
        title="Atras"
        @click="setEstado(1)"
        >
          <svg viewBox="0 0 24 24" data-v-4ef94f20="" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <div class="w-full flex justify-between">
          <input
          v-model="searchNivel2"
          type="search"
          placeholder="Buscar"
          class="border border-gray-400 px-2 py-1 rounded-md dark:text-primary-1"
          >
        </div>
      <div class="flex w-full justify-between letra">
        <div class="font-bold cursor-pointer" @click="ordenarCliente()">
          Cliente
          <i :class="orden.cliente === 'asc'? 'pi pi-sort-amount-up-alt text-xs' : orden.cliente === 'desc'? ' pi pi-sort-amount-down text-xs':''"></i>
        </div>
        <div class="text-right cursor-pointer font-bold" style="width:110px; min-width:110px;" @click="ordenarTotal()">
          Total
          <i :class="orden.total === 'asc'? 'pi pi-sort-amount-up-alt text-xs' : orden.total === 'desc'? ' pi pi-sort-amount-down text-xs':''"></i>
        </div>
      </div>
      <div class="scroll-ajuste">
        <div
        v-for="(row,k) in getItems"
        :key="k"
        class="flex w-full justify-between letra"
        >
          <div
          class="text-primary-1 cursor-pointer dark:text-white"
          @click="setSeleccion(row,3)"
          >
            {{row.name}}
          </div>
          <div class="text-right" style="width:110px; min-width:110px;">
            {{$h.formatCurrency(row.saldo)}}
          </div>
        </div>
      </div>
    </div>
    <div v-if="estado === 3">
      <div class="flex justify-end  letra">
        <button
        type="button"
        class="btn btn-atras"
        title="Atras"
        @click="setEstado(2)"
        >
          <svg viewBox="0 0 24 24" style="width: 12px; height: 12px;"><path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" data-v-4ef94f20=""></path></svg>
        </button>
      </div>
      <table class="w-full letra">
        <tr
        v-for="(row,k) in seleccionado" :key="k"
        >
          <td v-if="k === 'name' ">Nombre</td>
          <td v-else>{{k}}</td>
          <td
          v-if="k !== 'name'"
          class="text-right"
          style="width:110px; min-width:110px;"
          >
            {{$h.formatCurrency(row)}}
          </td>
          <td
          v-else
          class="text-right"
          style="width:110px; min-width:110px;"
          >
            {{row}}
          </td>
        </tr>
      </table>
    </div>
  </div>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <highlightjs
      autodetect
      :code="info.query"
    />

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { defineComponent, onMounted, ref } from 'vue'
import {
  estaCargando,
  info,
  getItems,
  searchNivel2,
  getData,
  setEstado,
  estado,
  orden,
  seleccionado,
  setSeleccion,
  getTotales,
  setTotalesExpandido,
  totalesExpandido
  } from './Hook'
export default defineComponent({
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup () {
    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    const ordenarCliente = () => {
      if (orden.value.cliente === 'asc') {
        orden.value.cliente = 'desc'
      } else
      if (orden.value.cliente === 'desc') {
        orden.value.cliente = 'neutral'
      } else
      if (orden.value.cliente === 'neutral') {
        orden.value.cliente = 'asc'
      }
      orden.value.total = 'neutral'
      getData(orden.value)
    }
    const ordenarTotal = () => {
      if (orden.value.total === 'asc') {
        orden.value.total = 'desc'
      } else
      if (orden.value.total === 'desc') {
        orden.value.total = 'neutral'
      } else
      if (orden.value.total === 'neutral') {
        orden.value.total = 'asc'
      }
      orden.value.cliente = 'neutral'
      getData(orden.value)
    }

    onMounted(() => {
      getData({ cliente: 'asc', total: 'neutral' })
    })
    return {
      totalesExpandido,
      setTotalesExpandido,
      ordenarCliente,
      ordenarTotal,
      estaCargando,
      estado,
      info,
      orden,
      searchNivel2,
      getItems,
      getData,
      setEstado,
      seleccionado,
      setSeleccion,
      getTotales,
      openModalConsulta,
      closeModalConsulta,
      modalVisible
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}

</style>
