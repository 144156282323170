import http from '../../../../../libs/http'

const api = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}`

const fetchTotalesFacturacion = (query) => http.get(`${api}/operacion/v2-total-facturacion`, { params: { ...query } })
const totalesFacturacionCliente = (query) => http.get(`${api}/operacion/total-facturacion-cliente`, { params: { ...query } })

export {
  fetchTotalesFacturacion,
  totalesFacturacionCliente
}
