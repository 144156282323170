<template>
  <div class="w-full p-2 rounded-md letra box centro-costo">
    <div v-if="cargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <template v-else>
      <div class="divide-y divide-opacity-50">
      <div class="options">
          <div class="p-fluid p-m-3">
            <div class="p-field p-grid">
              <div class="p-col-12 p-md-8">
              <label  class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">Centro de costos</label>
                <div class="p-inputgroup">
                  <span class="p-inputgroup-addon">
                      <i class="pi pi-map-marker"></i>
                  </span>
                  <Dropdown
                    v-model="selectedCostCenters"
                    :options="costCenters"
                    optionLabel="OcrName"
                    optionValue="ProfitCode"
                    placeholder="Seleccione"
                    class="p-inputtext-sm"
                    :filter="true"
                    filterPlaceholder="Buscar por nombre"
                  />
                </div>
              </div>
            </div>
            <div class="p-field p-grid">
              <div class="p-col-auto px-1 mb-1">
                <label class="p-col-12 p-mb-2 p-md-4 p-mb-md-0">Año/Mes</label>
                <FiltroMesAno v-model="selectedYear" :marco="false" rango />
              </div>
            </div>
            <div class="p-field p-grid justify-end">
              <span class="p-buttonset">
                  <Button label="Buscar" icon="pi pi-search" class="p-button-sm " @click="handleSearch"/>
                  <Button label="Limpiar" icon="pi pi-trash" class="p-button-sm p-button-danger" @click="handleClearFilters"/>
              </span>
            </div>
          </div>
        </div>
        <div class="body">
          <div class="grip  p-field p-m-3">
            <h3 class="text-center capitalize font-bold">Estados de Resultados</h3>
            <h4 class="text-center">Centro de costos {{costCenter}}</h4>
            <!-- <p class="text-center" style="font-size: 0.6rem">
              de {{$h.formatDate(selectedYear.month,'MMMM')}}
              a {{$h.formatDate(selectedYear.month2,'MMMM')}}
              del {{selectedYear.year}}
            </p> -->
            <p class="text-center" style="font-size: 0.6rem">expresado en miles de pesos</p>
          </div>
          <template v-if="indicadores.OcrName !== ''">
            <div class="grid p-field p-mx-3 capitalize font-medium underline ">
              Ingresos
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field">Operacionales</div>
              <div class="text-right font-light">{{$h.formatCurrency(indicadores.Operacionales,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field">No Operacionales</div>
              <div class="text-right font-light">{{$h.formatCurrency(indicadores.NoOperacionales,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field">Costos de Dispensación</div>
              <div class="text-right font-light">{{$h.formatCurrency(indicadores.CostoDispensacion,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field  capitalize font-bold ">Utilidad Bruta</div>
              <div class="text-right font-light  capitalize font-bold">{{$h.formatCurrency(indicadores.UtilidadBruta,2)}}</div>
            </div>
            <div class="grid p-field p-mx-3 capitalize font-medium underline ">
              Gastos
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field">Operaciones</div>
              <div class="text-right font-light">{{$h.formatCurrency(indicadores.Operaciones,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field">Ventas</div>
              <div class="text-right font-light">{{$h.formatCurrency(indicadores.Ventas,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field">Financieras</div>
              <div class="text-right font-light">{{$h.formatCurrency(indicadores.Financieras,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field  capitalize font-bold ">Total Gastos</div>
              <div class="text-right font-light  capitalize font-bold">{{$h.formatCurrency(indicadores.TotalGastos,2)}}</div>
            </div>
            <div class="grid p-field grid-cols-2 gap-4 p-mx-3">
              <div class="text-left font-light p-field  capitalize font-bold ">Utilidad Operacional</div>
              <div class="text-right font-light  capitalize font-bold">{{$h.formatCurrency(indicadores.UtilidadOperacional,2)}}</div>
            </div>
          </template>
        </div>
    </div>
    </template>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import { ref, computed, onMounted } from 'vue'
import { apiIndicadoresFinancierosBodega, apiWineriesList } from './service'
import FiltroMesAno from '../filtroMesAno'
export default {
  name: 'IndicadoresFinancierosBodega',
  components: {
    FiltroMesAno
  },
  setup () {
    /** data **/
    const cargando = ref(false)
    const costCenters = ref([])
    const selectedCostCenters = ref([])
    const indicadores = ref({
      CostoDispensacion: 0,
      Financieras: 0,
      Ingresos: 0,
      NoOperacionales: 0,
      OcrName: '',
      Operacionales: 0,
      Operaciones: 0,
      ProfitCode: '',
      TotalGastos: 0,
      UtilidadBruta: 0,
      UtilidadOperacional: 0,
      Ventas: 0
    })

    const getYearNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        if (dayjs().month() === 0) {
          return new Date().getFullYear() - 1
        }
      }
      return new Date().getFullYear()
    }
    const getMonthNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        return dayjs().subtract(1, 'month').month() + 1
      }
      return new Date().getMonth() + 1
    }
    const selectedYear = ref({
      year: getYearNow(),
      month: getMonthNow(),
      month2: getMonthNow()
    })

    const seletedCalendar = ref(dayjs().format('MM/YYYY'))
    const startDate = ref(dayjs().startOf('month').format('YYYY-MM-DD'))
    const finishDate = ref(dayjs().endOf('month').format('YYYY-MM-DD'))
    /** computed **/

    const costCenter = computed(() => {
      const filter = costCenters.value.find(x => x.ProfitCode === selectedCostCenters.value)
      return filter === undefined ? null : filter.OcrName
    })

    /** methods **/
    const changeSelectedYear = () => {
      cargando.value = true
      loadData()
    }
    const changeCalendarst = (e) => {
      startDate.value = dayjs(e).startOf('month').format('YYYY-MM-DD')
    }
    const changeCalendarfn = (e) => {
      finishDate.value = dayjs(e).endOf('month').format('YYYY-MM-DD')
    }
    const loadData = () => {
      const mes = selectedYear.value.year + '-' + selectedYear.value.month
      const mes2 = selectedYear.value.year + '-' + selectedYear.value.month2
      cargando.value = true
      apiIndicadoresFinancierosBodega({ startDate: dayjs(mes).startOf('month').format('YYYY-MM-DD'), finishDate: dayjs(mes2).endOf('month').format('YYYY-MM-DD'), profitCode: selectedCostCenters.value }).then((response) => {
        cargando.value = false
        indicadores.value = response.data
      }).catch(e => {
        cargando.value = false
        console.error(e)
      })
    }
    const handleSearch = () => {
      loadData()
    }
    const loadWineriesList = () => {
      cargando.value = true
      apiWineriesList().then(({ data }) => {
        costCenters.value = data
        cargando.value = false
      }).catch(e => {
        cargando.value = false
        console.error(e)
      })
    }
    const handleClearFilters = () => {
      indicadores.value = {
        CostoDispensacion: 0,
        Financieras: 0,
        Ingresos: 0,
        NoOperacionales: 0,
        OcrName: '',
        Operacionales: 0,
        Operaciones: 0,
        ProfitCode: '',
        TotalGastos: 0,
        UtilidadBruta: 0,
        UtilidadOperacional: 0,
        Ventas: 0
      }
      selectedCostCenters.value = null
    }
    /** mounted **/
    onMounted(() => {
      loadWineriesList()
    })
    /** retur **/
    return {
      costCenters,
      selectedCostCenters,
      seletedCalendar,
      selectedYear,
      changeCalendarst,
      changeCalendarfn,
      costCenter,
      startDate,
      finishDate,
      handleSearch,
      indicadores,
      cargando,
      handleClearFilters,
      changeSelectedYear
    }
  }
}
</script>
<style lang="scss">
.centro-costo {
  .p-inputgroup-addon{
    padding: 0!important;
  }
  .p-inputgroup {
      .p-inputtext-sm{
        height: 28px;
        .p-inputtext{
          height: 28px;
          padding-top: 3px;
        }
      }
  }
}
  .p-dropdown-panel {
    font-size: 11px!important;
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      padding: 0.3rem 1rem;
    }
    .p-dropdown-filter .p-inputtext .p-component{
          padding-top: 2px;
          padding-bottom: 4px;
    }
  }
</style>
