import { createStore } from 'vuex'
import Cumplimiento from '../services/cumplimiento'
const _Cumplimiento = new Cumplimiento()
const storeCardCumplimiento = {
  state: {
    _dataCumplimiento: {
      totalFacturadoAnio: 0
    }
    // _detalleCumplimiento: []
  },
  getters: {
    dataCumplimiento: state => state._dataCumplimiento
    // detalleCumplimiento: state => state._detalleCumplimiento
  },
  mutations: {
    setDataCumplimiento (state, payload) {
      state._dataCumplimiento = payload
    }
    // setDetalleCumplimiento (state, payload) {
    //   state._detalleCumplimiento = payload
    // }
  },
  actions: {
    getDataCumplimiento ({ commit }, payload) {
        return new Promise((resolve) => {
          _Cumplimiento.getDataCumplimiento(payload).then(({ data }) => {
            if (data.data.length > 0) {
              commit('setDataCumplimiento', data.data[0])
            } else {
              const tep = {
                referenciasSolicitadas: 0,
                referenciasEntregadas: 0,
                referenciasPendientes: 0,
                porcentReferencias: '0',
                porcentUnidades: '0',
                unidadesSolicitadas: 0,
                unidadesEntregadas: 0,
                unidadesPendientes: 0,
                referenciasAgotadas: 0,
                unidadesAgotadas: 0,
                referenciasEntregadasParcial: 0
              }
              commit('setDataCumplimiento', tep)
            }
            resolve()
          })
        })
    }
    // getDetalleCumplimiento ({ commit }, payload) {
    //   return new Promise((resolve) => {
    //     _Cumplimiento.getDetalleCumplimiento(payload).then(({ data }) => {
    //       commit('setDetalleCumplimiento', data)
    //       resolve()
    //     })
    //   })
    // }
  }
}
const store = createStore(storeCardCumplimiento)
export default {
  namespaced: true,
  ...store
}
