<script setup>

</script>

<template>
  <div class="box">
    <div class="flex flex-col sm:flex-row items-center p-4 border-b">
      <h4 class="mr-auto capitalize text-base font-bold">Dashboard Informe Salud Mía v3</h4>
    </div>
    <div class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-5 p-4">
      <div class="col-span-12 responsive-iframe">
        <iframe width="100%" height="100%" frameborder="0" scrolling="no" src="https://pharmasan-my.sharepoint.com/personal/profesionalsistemas8_daily-tech_co1/_layouts/15/Doc.aspx?sourcedoc={e0622677-cf4a-4d2f-9dd5-e9970184fe5e}&action=embedview&wdHideGridlines=True&wdHideHeaders=True&wdDownloadButton=True&wdInConfigurator=True&wdInConfigurator=True"></iframe>
      </div>
    </div>
  </div>
</template>

<style scoped>
.responsive-iframe {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 700px; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
