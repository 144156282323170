import { computed, ref } from 'vue'
import { fetchTotalesFacturacion, totalesFacturacionCliente } from './Service'

const info = ref({
  info: {
    facturacionYear: 0,
    facturacionMonth: 0
  }
})
const evento = ref({
  evento: {
    cliente: '',
    total: 0,
    costo: 0,
    cantidad: 0
  }
})
const capita = ref([])
const estaCargando = ref(true)
const searchable = ref(false)
const searchNivel2 = ref('')
const estado = ref(1)
const typeModalidad = ref('Evento')
const queryCliente = ref('')
const nameCliente = ref('')

const getData = (payload = {}) => {
  estaCargando.value = true
  totalesFacturacionCliente(payload).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      evento.value = data.totalEvento
      capita.value = data.totalCapita
      queryCliente.value = data.query
      nameCliente.value = data.name
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err)
  })
  return fetchTotalesFacturacion(payload).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err)
  })
}
const getDataCliente = computed(() => {
  const tmp = []
  let infoType
    if (typeModalidad.value === 'Evento') {
      infoType = evento.value
    } else {
      infoType = capita.value
    }
    for (const value of infoType) {
      const cliente = value.cliente
      const contrato = value.contrato
      const total = value.total
      const cantidad = value.cantidad
      const renta = value.renta
      const costos = value.costos
      const visible = false
      tmp.push({ cliente, contrato, total, cantidad, costos, renta, visible })
    }
    const filtered = tmp.filter(a =>
      a.cliente.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
      a.cantidad.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
      a.total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
      )
    return filtered
})

const getNameTypeModalidad = computed(() => {
  if (typeModalidad.value === 'Evento') {
    return 'Evento'
  }
  return 'Capita'
})
const setEstado = (value, type = 'evento') => {
  estado.value = value
  typeModalidad.value = type
}

export {
  estaCargando,
  searchable,
  searchNivel2,
  info,
  evento,
  capita,
  estado,
  setEstado,
  getData,
  getDataCliente,
  typeModalidad,
  getNameTypeModalidad,
  queryCliente,
  nameCliente
}
