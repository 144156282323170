<template>
  <section class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
    <div class=" box p-5 zoom-in scroll-ajuste">
      <div v-if="estaCargando" class="rounded-md icono-carga p-8">
        <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
      </div>
      <div class="w-full">
        <div class="capitalize font-medium">Pagos Proveedores</div>
      </div>
      <template v-if="estado === 1">
        <div class="grid grid-cols-2 gap-4 text-xs">
          <div class="flex w-full flex-col scroll-ajuste" @click="setEstado(2, 'Medicamentos')">
            <div class="mt-1">
              <div class="font-medium capitalize">
                Medicamentos
              </div>
              <div class="text-gray-600  w-full cursor-pointer mt-1">
                {{$h.formatCurrency(totalMed)}}
              </div>
            </div>
          </div>
          <div class="flex w-full flex-col scroll-ajuste" @click="setEstado(2, 'Administrativo')">
            <div class="mt-1">
              <div class="font-medium capitalize">
                Administrativo
              </div>
              <div class="text-gray-600  w-full cursor-pointer mt-1">
                {{$h.formatCurrency(totalAdm)}}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
        <div class="flex items-center justify-between w-full">
          <div class="font-medium truncate text-sm">({{ typeModalidad }})</div>
          <div class="flex items-center">
            <div
              class="mr-2 cursor-pointer text-primary-1 dark:text-white"
              title="Descargar"
              @click="handleExcelDownload"
            >
              <svg style="width:24px;height:24px" class="text-green-900" viewBox="0 0 24 24">
                <path fill="currentColor" d="M21.17 3.25Q21.5 3.25 21.76 3.5 22 3.74 22 4.08V19.92Q22 20.26 21.76 20.5 21.5 20.75 21.17 20.75H7.83Q7.5 20.75 7.24 20.5 7 20.26 7 19.92V17H2.83Q2.5 17 2.24 16.76 2 16.5 2 16.17V7.83Q2 7.5 2.24 7.24 2.5 7 2.83 7H7V4.08Q7 3.74 7.24 3.5 7.5 3.25 7.83 3.25M7 13.06L8.18 15.28H9.97L8 12.06L9.93 8.89H8.22L7.13 10.9L7.09 10.96L7.06 11.03Q6.8 10.5 6.5 9.96 6.25 9.43 5.97 8.89H4.16L6.05 12.08L4 15.28H5.78M13.88 19.5V17H8.25V19.5M13.88 15.75V12.63H12V15.75M13.88 11.38V8.25H12V11.38M13.88 7V4.5H8.25V7M20.75 19.5V17H15.13V19.5M20.75 15.75V12.63H15.13V15.75M20.75 11.38V8.25H15.13V11.38M20.75 7V4.5H15.13V7Z" />
              </svg>
            </div>
            <button
              type="button"
              class="btn btn-atras"
              @click="setEstado(1, typeModalidad)"
            >
              <svg style="width:12px;height:12px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
              </svg>
            </button>
          </div>

        </div>
        <div class="w-full flex justify-between">
          <input
          v-model="searchNivel2"
          type="search"
          placeholder="Buscar"
          class="border border-gray-400 px-2 py-1 rounded-md dark:text-primary-1"
          >
        </div>
        <div class="w-full overflow-x-auto letra">
            <div class="w-full flex">
              <div class="font-bold" style="width:55%;">Proveedor</div>
              <div class="font-bold text-right" style="width:130px;">Total</div>
            </div>

            <div v-for="(row,k) in getDataProv" :key="k" class="w-full flex flex-col">
              <div class="flex w-full">
                <div class="mb-1 text-primary-1 dark:text-white" style="width:55%;">
                  {{row.proveedor}}
                </div>
                <div class="mb-1 text-right" style="width:130px;">{{$h.formatCurrency(row.total)}}</div>
              </div>
            </div>
          </div>
      </template>
    </div>
  </section>
</template>
<script>
import { defineComponent, watchEffect } from 'vue'
import * as parameters from './Hook'
export default defineComponent({
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  name: 'Card Pagos Proveedores',
  setup (props, context) {
    watchEffect(() => {
      parameters.getData({
        year: props.year,
        mes: props.month
      })
    })
    return {
      ...parameters
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
.table_cards{
  text-align: start;
  font-size: 0.7rem;
  margin-top: 10px;
  line-height: 1.1rem;
}
</style>
