import { getOvAbiertasV2 } from './service'
import { computed, ref } from 'vue'

const info = ref({
  cantidad: 0,
  total: 0,
  items: {},
  subtotales: {}
})
const estaCargando = ref(true)
const searchable = ref(false)
const contratoSel = ref('')
const estado = ref(1)
const searchNivel2 = ref('')

const setContratoSel = (value) => {
  console.log('setContratoSel', value)
  estado.value = 2
  contratoSel.value = value
}

const fetchOvAbiertas = (queryParams) => {
  estaCargando.value = true
  return getOvAbiertasV2(queryParams).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err.message)
  })
}

const setEstado = (value) => {
  estado.value = value
}

const getTotal = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.Total, 0)
}

const getItems = computed(() => {
  const tmp = []
  for (const [key, value] of Object.entries(info.value.subtotales)) {
    const contrato = key
    const total = value.total
    const totalabierto = value.totalabierto
    const totalentregado = value.totalentregado
    tmp.push({ contrato, total, totalabierto, totalentregado })
  }
  return tmp.filter(a =>
    a.contrato.toLowerCase().includes(searchNivel2.value) ||
    a.total.toString().toLowerCase().includes(searchNivel2.value) ||
    a.totalabierto.toString().toLowerCase().includes(searchNivel2.value) ||
    a.totalentregado.toString().toLowerCase().includes(searchNivel2.value)
  )
})

const getResumen = computed(() => {
  const rows = info.value.items[contratoSel.value]
  if (rows === undefined) return []
  return rows.filter(a =>
    a.cardname.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.totalabierto.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.totalentregado.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
  )
})

export {
  estaCargando,
  searchable,
  estado,
  info,
  searchNivel2,
  getItems,
  getResumen,
  contratoSel,
  fetchOvAbiertas,
  setEstado,
  getTotal,
  setContratoSel
}
