<template>
  <section class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class=" box p-5 zoom-in scroll-ajuste">

      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px" v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>

      <template v-if="estado === 1">
        <div
        class="flex items-center text-xs"
        >
          <div
          class="w-2/3 flex-none"
          @click="setEstado(2)"
          >
            <div class="font-medium capitalize">Facturación</div>
            <div class="text-gray-600 mt-1">{{$h.formatCurrency(info.total)}}</div>
            <div class="font-medium mt-1">Cantidad</div>
            <div class="text-gray-600 ">{{$h.formatNumber(info.cantidad)}}</div>
          </div>
          <div class="flex-none ml-auto relative">
            <ReportDonutChart1
            :width="85"
            :height="85"
            :laData="chartData"
            :losLabels="chartLabels"
            />
            <div
            class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
            style="z-index:-1;"
            >
              {{(chartPorcentaje).toFixed(0)}}%
            </div>
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
          <div class="flex justify-between items-center text-xs">
            <div class="font-medium truncate capitalize">Facturación</div>
            <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(1)"
            >
              <svg style="width:12px;height:12px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
              </svg>
            </button>
          </div>
          <div
          v-if="searchable"
          class="w-full flex text-xs"
          :class="searchable ? 'justify-between' : 'justify-end' "
          >
            <input
            v-model="searchNivel2"
            type="search"
            placeholder="Buscar"
            class="border border-gray-400 px-2 py-1 rounded-md"
            >
          </div>
          <table style="width: 100%" class="table_cards">
            <thead>
              <tr>
                <th class="text-left">Tipo Cto</th>
                <th class="text-right">Cant</th>
                <th class="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr class="hover:bg-gray-300" v-for="(row,k)  in getItems" :key="k">
                <td class="text-left">
                  <div
                  style="display: flex; align-items: center;"
                  @click.stop="setContratoSel(row.label)"
                  >
                    <div
                    class="dark:text-white text-primary-1 uppercase"
                    style="position: relative; top: 1px;"
                    >
                     {{row.label}}
                    </div>
                  </div>
                </td>
                <td class="text-right">{{$h.formatNumber(row.cantidad)}}</td>
                <td class="text-right">{{$h.formatCurrency(row.total)}}</td>
              </tr>
            </tbody>
          </table>
      </template>
      <template v-if="estado === 3">
        <div class="flex justify-between text-xs">
          <div class="font-medium truncate capitalize">Facturación</div>
          <button
          type="button"
          class="btn btn-atras"
          title="Atras"
          @click="setEstado(2)"
          >
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <div v-if="searchable" class="w-1/2 text-xs">
          <input
          v-model="searchNivel3"
          type="search"
          class="border border-gray-300 px-2 py-1 rounded-md"
          placeholder="Buscar"
          >
        </div>
        <div class="w-full flex items-center">
            <div
            class="table_cards w-full"
            >
              <div class="flex w-full">
                <div style="width:122px;" class="font-bold">Cliente</div>
                <div style="width:35px;" class="text-right font-bold">Cant</div>
                <div style="width:calc(100% - 157px);" class="text-right font-bold">Total</div>
              </div>
              <template
              class="hover:bg-gray-300" v-for="(row,k)  in getResumen"
              :key="k"
              >
                <div class="w-full flex">
                  <!-- @click="setPbsSel(row.label)" -->
                  <div
                  class="text-left dark:text-white text-primary-1"
                  style="width:122px;"
                  @click="row.visible = !row.visible"
                  >
                    {{row.label}}
                  </div>
                  <div
                  class="text-right"
                  style="width:35px;"
                  >
                    {{$h.formatNumber(row.cantidad)}}
                  </div>
                  <div
                  class="text-right"
                  style="width:calc(100% - 157px);">
                    {{$h.formatCurrency(row.total)}}
                  </div>
                </div>
                <div v-if="row.visible" class="w-full flex flex-col">
                  <div v-for="(row3,m) in row.pbs" :key="m" class="w-full flex">
                    <div style="width:122px;" class="text-left pl-2">{{row3.label}}</div>
                    <div style="width:35px;" class="text-right">{{$h.formatNumber(row3.cantidad)}}</div>
                    <div style="width: calc(100% - 157px);" class="text-right">{{$h.formatCurrency(row3.total)}}</div>
                  </div>
                </div>
              </template>
            </div>
        </div>
      </template>
      <template v-if="estado === 4">
        <div class="flex justify-between">
          <div class="font-medium truncate capitalize">Facturación</div>
          <button
          type="button"
          class="btn btn-atras"
          title="Atras"
          @click="setEstado(3)"
          >
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <div class="w-full flex items-center">
            <table  style="width: 100%" class="table_cards">
              <thead>
                <tr>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Cant</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tr
              class="hover:bg-gray-300" v-for="(row,k)  in getPbs"
              :key="k"
              >
                <td class="text-left w-2/4 dark:text-white text-primary-1">{{row.label}}</td>
                <td class="text-left">{{$h.formatNumber(row.cantidad)}}</td>
                <td class="text-right" style="width:140px;">{{$h.formatCurrency(row.total)}}</td>
              </tr>
            </table>
        </div>
      </template>
    </div>
  </section>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <p class="m-0">
      <highlightjs
        autodetect
        :code="info.query"
      />
    </p>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { computed, defineComponent, ref, watchEffect } from 'vue'
import { useStore } from 'vuex'
import {
  searchable,
  estado,
  fetchFacturacion,
  info,
  setEstado,
  chartPorcentaje,
  chartLabels,
  chartData,
  getCantidad,
  getTotal,
  searchNivel2,
  searchNivel3,
  getItems,
  setContratoSel,
  getResumen,
  estaCargando,
  setPbsSel,
  getPbs
  } from './Hook'
import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
export default defineComponent({
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component,
    ReportDonutChart1
  },
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  setup (props, context) {
    const store = useStore()
    watchEffect(() => {
      fetchFacturacion({
        year: props.year,
        month: props.month
      })
    })
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })

    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    return {
      isDark,
      estado,
      info,
      chartPorcentaje,
      chartLabels,
      chartData,
      setEstado,
      getCantidad,
      getTotal,
      searchNivel2,
      searchNivel3,
      getItems,
      setContratoSel,
      getResumen,
      searchable,
      estaCargando,
      setPbsSel,
      getPbs,
      openModalConsulta,
      closeModalConsulta,
      modalVisible
    }
  }
})
</script>

<style scoped>
.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
.letra{
  font-size: 12px !important;
}
</style>
