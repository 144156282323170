import { computed, ref } from 'vue'
import { fetchCarteraCliente } from './Service'

const info = ref({
  groupTotal: [],
  detailClient: []
})
const estaCargando = ref(true)
const buscar = ref('')
const estado = ref(1)
const searchNivel2 = ref('')
const seleccionado = ref({})
const orden = ref({
  cliente: 'asc',
  total: 'neutral'
})

// const setEstado = (value) => {
//   console.log('Le dieron click al ver mas', value)
//   estado.value = value
// }
const totalesExpandido = ref(false)
const setTotalesExpandido = (state) => {
  totalesExpandido.value = state
}
const getTotales = computed(() => {
  if (totalesExpandido.value) {
    return info.value.groupTotal
  }

  return info.value.groupTotal.filter(f => ['Total', 'Totalnovencido', 'Totalvencido'].includes(f.label))
})
const getData = (value) => {
  estaCargando.value = true
  return fetchCarteraCliente(value).then(({ status, data }) => {
    if (status === 200) {
      info.value = data
      estaCargando.value = false
    }
  }).catch(err => {
    console.error(err)
    estaCargando.value = false
  })
}
const setSeleccion = (value, state = null) => {
  if (state) {
    estado.value = state
  }
  seleccionado.value = value
}

const getItems = computed(() => {
  return info.value.items.map(a => {
    a.Proveedor = a.Proveedor ? a.Proveedor : 'N/A'
    return a
  }).filter(a => a.Proveedor.toLocaleLowerCase().includes(buscar.value.toLocaleLowerCase()))
})

const getItemsProv = computed(() => {
  const filtered = info.value.detailClient.filter(a =>
    a.NombreSN.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.Total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
    )
  return filtered
})

const handleExcelDownload = () => {
  window.open(`${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/operation/cartera-proveedor/export`, '_blank')
}

export {
  handleExcelDownload,
  estaCargando,
  buscar,
  estado,
  orden,
  info,
  seleccionado,
  getItems,
  searchNivel2,
  getItemsProv,
  getTotales,
  totalesExpandido,
  setTotalesExpandido,
  getData,
  // setEstado,
  setSeleccion
}
