<template>
  <section class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class=" box p-5 zoom-in scroll-ajuste">

      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px" v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>

      <template v-if="estado === 1">
        <div class="flex flex-wrap w-full justify-between items-center mb-2">
          <div class="font-medium truncate capitalize">OVs Abiertas V2</div>
          <div class="text-gray-600">{{$h.formatCurrency(info.total)}}</div>
        </div>
        <div class="flex flex-col sm:flex-row w-full text-xs">
          <div class="sm:flex flex-col w-4/12 items-end justify-center hidden font-medium">
            <span class="text-gray-600 mt-1 text-right">&nbsp;</span>
            <span class="text-gray-600 mt-1 text-right">Abierto:</span>
            <span class="text-gray-600 mt-1 text-right">Entregado:</span>
            <span class="text-gray-600 mt-1 text-right">Total:</span>
          </div>
          <div
            class="flex flex-col sm:flex-row w-full sm:w-4/12 justify-around"
            v-for="(row,k) in getItems"
            :key="k"
          >
            <div
              class="flex flex-col w-full items-end justify-center"
              @click.stop="setContratoSel(row.contrato)"
            >
              <div class="flex justify-between sm:justify-center w-full text-gray-600 mt-1">
                <span class="block sm:hidden">&nbsp;</span>
                <span class="font-medium">{{row.contrato}}</span>
              </div>
              <div class="flex justify-between sm:justify-center w-full text-gray-600 mt-1">
                <span class="block sm:hidden">Abierto</span>
                <span>{{$h.formatCurrency(row.totalabierto)}}</span>
              </div>
              <div class="flex justify-between sm:justify-center w-full text-gray-600 mt-1">
                <span class="block sm:hidden">Entregado</span>
                <span>{{$h.formatCurrency(row.totalentregado)}}</span>
              </div>
              <div class="flex justify-between sm:justify-center  w-full text-gray-600 mt-1">
                <span class="block sm:hidden">Total</span>
                <span>{{$h.formatCurrency(row.total)}}</span>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="estado === 2">
        <div class="w-full flex items-center justify-between">
          <div class="font-medium truncate capitalize">OVs Abiertas V2 ({{ contratoSel }})</div>
          <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(1)"
          >
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <div class="w-full flex justify-between">
          <input
          v-model="searchNivel2"
          type="search"
          placeholder="Buscar"
          class="border border-gray-400 px-2 py-1 rounded-md"
          >
        </div>
        <div class="flex items-center text-sm">
          <table style="width: 100%" class="table_cards">
            <thead>
              <tr>
                <th class="text-left px-2">Cliente</th>
                <th class="text-right px-2">Abierto</th>
                <th class="text-right px-2">Entregado</th>
                <th class="text-right px-2">Total</th>
              </tr>
            </thead>
            <tr
              class="hover:bg-gray-300" v-for="(row,k) in getResumen"
              :key="k"
            >
              <td class="text-left px-2 w-2/4 dark:text-white text-primary-1">{{row.cardname}}</td>
              <td class="text-right px-2" style="width:140px;">{{$h.formatCurrency(row.totalabierto)}}</td>
              <td class="text-right px-2" style="width:140px;">{{$h.formatCurrency(row.totalentregado)}}</td>
              <td class="text-right px-2" style="width:140px;">{{$h.formatCurrency(row.total)}}</td>
            </tr>
          </table>
        </div>
      </template>
    </div>
  </section>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <p class="m-0">
      <highlightjs
        autodetect
        :code="info.query"
      />
    </p>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { defineComponent, ref, watchEffect } from 'vue'
import {
  searchable,
  estado,
  fetchOvAbiertas,
  info,
  setEstado,
  getTotal,
  searchNivel2,
  getItems,
  setContratoSel,
  contratoSel,
  getResumen,
  estaCargando
  } from './Hook'
export default defineComponent({
  name: 'CardOvAbiertasV2',
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup (props, context) {
    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    watchEffect(() => {
      fetchOvAbiertas({
        year: props.year,
        month: props.month
      })
    })
    return {
      estaCargando,
      searchable,
      estado,
      info,
      setEstado,
      getTotal,
      searchNivel2,
      getItems,
      setContratoSel,
      contratoSel,
      getResumen,
      modalVisible,
      openModalConsulta,
      closeModalConsulta
    }
  }
})
</script>

<style scoped>
.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
