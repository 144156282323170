<template>
  <section class="col-span-12 sm:col-span-12  md:col-span-6 xl:col-span-4 intro-y">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class=" box p-5 zoom-in scroll-ajuste">

      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px" v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>

      <div v-show="estado === 1">
        <div
        class="flex items-center"
        >
          <div
          class="w-2/3 flex-none"
          @click="setEstado(2)"
          >
            <div class="font-medium truncate capitalize">Compras Proveedores</div>
            <div class="text-gray-600 mt-1">{{$h.formatCurrency(info.total)}}</div>
            <div class="font-medium mt-1">Cantidad</div>
            <div class="text-gray-600 ">{{$h.formatNumber(info.cantidad)}}</div>
          </div>
          <div class="flex-none ml-auto relative" id="grafica" ref="elGrafica"
          style="width: 90px; height: 90px">
<!--            <ReportDonutChart1
            :width="90"
            :height="90"
            :laData="chartData"
            />
            <div
            class="font-medium absolute w-full h-full flex items-center justify-center top-0 left-0"
            style="z-index:-1;"
            >
              {{(chartPorcentaje).toFixed(0)}}%
            </div>-->
          </div>
        </div>
      </div>
      <div v-show="estado === 2">
        <div class="flex items-center justify-between">
          <div class="font-medium truncate capitalize">Compras Proveedores</div>
          <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(1)"
            >
              <svg style="width:12px;height:12px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
              </svg>
            </button>
        </div>
        <div class="w-full flex justify-between">
          <input
          v-model="searchNivel2"
          type="search"
          placeholder="Buscar"
          class="border border-gray-400 px-2 py-1 rounded-md dark:text-primary-1"
          >
        </div>
          <table style="width: 100%" class="table_cards">
            <thead>
              <tr>
                <th class="text-left">Proveedor</th>
                <th class="text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr class="hover:bg-gray-300" v-for="(row,k)  in getItems" :key="k">
                <td class="text-left">
                  <div
                  style="display: flex; align-items: center;"
                  >
                    <div class="dark:text-white text-primary-1" style="position: relative; top: 1px; "> {{row.CardName}} </div>
                  </div>
                </td>
                <td class="text-right">{{$h.formatCurrency(row.Total)}}</td>
              </tr>
            </tbody>
          </table>
      </div>
    </div>
  </section>

  <!-- Modal para visualizar la consulta -->
  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '60rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>{{ `Consulta - ` + info.name }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <p class="m-0">
      <highlightjs
        autodetect
        :code="info.query"
      />
    </p>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>
</template>

<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { defineComponent, onMounted, ref, watchEffect } from 'vue'
import * as echarts from 'echarts'
// import ReportDonutChart1 from '@/components/report-donut-chart-1/Main.vue'
import {
  chartData,
  chartLabels,
  chartPorcentaje,
  estaCargando,
  estado,
  fetchFacturacion,
  getCantidad,
  getItems,
  getResumen,
  getTotal,
  info,
  searchNivel2,
  searchNivel3,
  setContratoSel,
  setEstado
} from './Hook'

export default defineComponent({
  name: 'CardComprasProveedores',
  // components: { ReportDonutChart1 },
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup (props, context) {
    const elGrafica = ref(null)
    let chart
    const initChart = () => {
      chart = echarts.init(elGrafica.value, 'light')
      const option = {
        grid: {
          left: 0,
          right: 10,
          bottom: 0,
          top: 10
        },
        tooltip: {
          position: 'left',
          textStyle: {
            fontSize: 10
          },
          formatter (a) {
            return `${a.name} <br> ${a.value.toFixed(0)}%`
          }
        },
        xAxis: {
          show: false,
          data: ['Vlr Compras vs Fact Evento', 'Vlr Compras vs Fact Total']
        },
        yAxis: {
          show: false
        },
        series: [{
          data: [0, 0],
          type: 'bar',
          label: {
            show: true,
            color: 'black',
            formatter (d) {
              return d.data.valueReal < 0 ? `-${Math.abs(d.value).toFixed(0)}%` : `${d.value.toFixed(0)}%`
            },
            position: 'top',
            verticalAlign: 'middle'
          }
        }]
      }
      chart.setOption(option)
    }

    onMounted(() => {
      initChart()
    })

    const reloadChart = () => {
      const option = {
        series: [{
          data: chartPorcentaje.value,
          type: 'bar'
        }]
      }
      chart.setOption(option)
    }

    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    watchEffect(() => {
      fetchFacturacion({
        year: props.year,
        month: props.month
      }).then(() => {
        reloadChart()
      })
    })
    return {
      estaCargando,
      estado,
      info,
      chartPorcentaje,
      chartLabels,
      chartData,
      setEstado,
      getCantidad,
      getTotal,
      searchNivel2,
      searchNivel3,
      getItems,
      setContratoSel,
      getResumen,
      elGrafica,
      modalVisible,
      openModalConsulta,
      closeModalConsulta
    }
  }
})
</script>

<style scoped>
.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
</style>
