<template>
  <section class="w-full">
    <div class="flex flex-col">
      <span class="text-sm font-semibold">Detalles</span>
      <div
        class="flex flex-wrap justify-between items-center mt-4 mb-2 w-full"
      >
        <div class="flex flex-col w-full">
          <div class="flex justify-between items-center border-dashed border-b pb-1">
            <span class="text-xs font-normal">Artículo</span>
            <span class="text-xs font-semibold text-right">
              {{
                getInfoComprasCatalogo.ItemName === ""
                  ? "N/A"
                  : getInfoComprasCatalogo.ItemCode + ' - ' + getInfoComprasCatalogo.ItemName.toUpperCase()
              }}
            </span>
          </div>
          <div class="flex justify-between items-center border-dashed border-b pt-1">
            <span class="text-xs font-normal">Agrupación</span>
            <span class="text-xs font-semibold text-right">
              {{
                [null, undefined, ""].includes(getInfoComprasCatalogo.U_PHR_AgrupCom)
                  ? "N/A"
                  : getInfoComprasCatalogo.U_PHR_AgrupCom.toUpperCase()
              }}
            </span>
          </div>
          <div class="flex justify-between items-center border-dashed border-b pt-1">
            <span class="text-xs font-normal">Cum:</span>
            <span class="text-xs font-semibold text-right">
              {{
                [null, undefined, ""].includes(getInfoComprasCatalogo.Cum)
                  ? "N/A"
                  : getInfoComprasCatalogo.Cum.toUpperCase()
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="box p-2 mt-2">
        <div class="boxed-tabs nav nav-tabs text-xs justify-center w-full bg-gray-200 text-gray-600 dark:bg-dark-1 dark:text-gray-500 rounded-md mx-auto flex-col sm:flex-row" role="tablist">
          <a
            v-for="(tab, index) in tabs"
            :key="index"
            :id="tab.id + '-tab'"
            :data-target="'#' + tab.id"
            href="javascript:;"
            class="btn flex-1 border-0 shadow-none py-1 px-2"
            :class="{ active: activeTab === tab.id }"
            role="tab"
            :aria-controls="tab.id"
            :aria-selected="activeTab === tab.id"
            @click="activateTab(tab.id)"
          >
            <component :is="tab.icon" class="w-4 h-4 mr-2" />
            {{ tab.label }}
          </a>
        </div>

        <div class="tab-content mt-4">
          <div
            v-for="(tab, index) in tabs"
            :key="index"
            :id="tab.id"
            class="tab-pane"
            role="tabpanel"
            :class="{ active: activeTab === tab.id }"
            :aria-labelledby="tab.id + '-tab'"
          >
            <div class="mt-4">
              <component :is="tab.component" v-if="activeTab === tab.id " />
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import Tab1 from './tabs/tab1.vue'
import Tab2 from './tabs/tab2.vue'
import Tab3 from './tabs/tab3.vue'
import { ArrowLeftCircleIcon, BoxIcon, SettingsIcon } from '@zhuowenli/vue-feather-icons'

import { useInfoComprasCatalogoStore } from '../stores/infoComprasCatalogo'

//
const infoComprasCatalogoStore = useInfoComprasCatalogoStore()
const activeTab = ref(1)

const tabs = [
  { id: 1, label: 'Histórico Entradas de Mercancía', icon: ArrowLeftCircleIcon, component: Tab1 },
  { id: 2, label: 'Histórico Precios', icon: SettingsIcon, component: Tab2 },
  { id: 3, label: 'Analisis Grafica', icon: BoxIcon, component: Tab3 }
]

// Funcion para activar la pestaña
const activateTab = (tabId) => {
  console.log('Pestaña activada: ' + tabId)
  activeTab.value = tabId
}

// Computada para traer los filtros del localstorage y actualizarlos en el store
const getInfoComprasCatalogo = computed(() => {
  return infoComprasCatalogoStore.getInfoComprasCatalogo
})

// Watch...
watch(activeTab, (newTabId, oldTabId) => {
  if (newTabId !== oldTabId) {
    console.log('Nueva pestaña: ' + newTabId)
    console.log('Pestaña anterior: ' + oldTabId)
  }
})

onMounted(() => {
  // Se activa por defecto la pestaña 1
  activeTab.value = 1
})
</script>

<style scoped>

</style>
