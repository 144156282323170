<template>
  <div class="w-full p-2 rounded-md letra box">
    <div v-if="cargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <section v-else>
      <div class="p-fluid">
        <div class="p-field p-grid justify-center pt-2">
          <!-- <label for="years" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Año</label> -->
          <div class="p-col-auto">
            <FiltroMesAno v-model="selectedYear" @change="changeSelectedYear" :tiene-mes="false" :marco="false" />
            <!-- <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-calendar"></i>
                </span>
              <Dropdown id="years" v-model="selectedYear" :options="years" @change="changeSelectedYear" class="p-inputtext-sm" />
            </div> -->
          </div>
        </div>
      </div>
      <div class="font-medium truncate capitalize text-center" style="font-size: 0.8rem;">
        Indicadores financieros  {{selectedYear.year}}
      </div>
      <DataTable
        :value="balance"
        class="p-datatable-sm"
        responsiveLayout="stack"
        breakpoint="400">
        <Column headerStyle="font-size:0.8rem; width:30%;" bodyStyle="font-size:0.7rem; width:30%;">
          <template #header>
            <div class="table-header" style="width: 100%;">
              Indicador
            </div>
          </template>
          <template #body="{ data }">
            {{data.indicador}}
          </template>
        </Column>
        <Column headerStyle="font-size:0.8rem; width:20%; text-align: center !important;" bodyStyle="font-size:0.7rem; width:20%;">
          <template #header>
            <div class="table-header" style="width: 100%;">
              %
            </div>
          </template>
          <template #body="{ data }">
            {{data.porcentaje}}
          </template>
        </Column>
        <Column headerStyle="font-size:0.8rem; width:50%;" bodyStyle="font-size:0.7rem; width:50%;">
          <template #header>
            <div class="table-header" style="width: 100%;">
              Interpretacion
            </div>
          </template>
          <template #body="{ data }">
            {{data.interpretacion}}
          </template>
        </Column>
      </DataTable>
    </section>
  </div>
</template>
<script>
import { apiIndicadoresFinancieros } from './service'
import { onMounted, ref } from 'vue'
import dayjs from 'dayjs'
import FiltroMesAno from '../filtroMesAno'
export default {
  name: 'IndicadoresFinancieros',
  components: {
    FiltroMesAno
  },
  setup () {
    /** data **/
    const balance = ref(null)
    const cargando = ref(true)
    // const selectedYear = ref(dayjs().year())
    const getYearNow = () => {
      return new Date().getFullYear()
    }
    const selectedYear = ref({
      year: getYearNow(),
      month: null
    })
    const years = ref([])
    /** mounted **/
    onMounted(() => {
      for (var i = 2017; i <= dayjs().year(); i++) {
        years.value.push(i)
      }
       loadData()
    })
    /** methods **/
    const changeSelectedYear = () => {
      cargando.value = true
      loadData()
    }

    const loadData = () => {
      const anio = selectedYear.value.year
      apiIndicadoresFinancieros({ year: anio, status: true }).then(({ data }) => {
        balance.value = data
        cargando.value = false
      }).catch(logError => {
        cargando.value = false
        console.error(logError)
      })
    }
    /** return **/
    return {
      balance,
      cargando,
      selectedYear,
      years,
      changeSelectedYear
    }
  }
}
</script>
<style scoped>
::v-deep(.p-datatable .p-datatable-thead > tr > th){
  text-align: center;
  padding: 0.5rem 0.5rem;
  border: 0;
  font-weight: 600;
  color: var(--text-color);
  background: var(--surface-0);
  transition: box-shadow 0.2s;
}
::v-deep(.p-datatable .p-datatable-tbody > tr > td  ){
  text-align: left;
  padding: 1rem 1rem;
  border: 0;
}

</style>
