import { getFacturacion } from './service'
import { computed, ref } from 'vue'
import { helper } from '../../../../../utils/helper'

const info = ref({
  cantidad: 0,
  total: 0,
  items: [],
  subtotales: {},
  porcentaje: 0
})
const estaCargando = ref(true)
const contratoSel = ref('')
const estado = ref(1)
const searchNivel2 = ref('')
const searchNivel3 = ref('')

const chartPorcentaje = computed(() => {
  return [
    {
      value: info.value.porcentaje < 0 ? Math.abs(info.value.porcentaje) : info.value.porcentaje,
      valueReal: info.value.porcentaje,
      itemStyle: {
        normal: {
          color: '#FF8B26'
        }
      }
    },
    {
      value: info.value.porcentajeAll < 0 ? Math.abs(info.value.porcentajeAll) : info.value.porcentajeAll,
      valueReal: info.value.porcentajeAll,
      itemStyle: {
        normal: {
          color: '#1C3FAA'
        }
      }
    }
  ]
})

const chartData = computed(() => {
  const tmp = []
  tmp.push(info.value.porcentaje)
  tmp.push(100 - info.value.porcentaje)
  return tmp
})

const setContratoSel = (value) => {
  console.log('setContratoSel', value)
  estado.value = 3
  contratoSel.value = value
}

const chartLabels = computed(() => {
  return [
    helper.formatCurrency(info.value.total),
    helper.formatCurrency((12500000000 - info.value.total))
  ]
})

const fetchFacturacion = (queryParams) => {
  estaCargando.value = true
  return getFacturacion(queryParams).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err.message)
  })
}

const setEstado = (value) => {
  estado.value = value
}

const getCantidad = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.Cantidad, 0)
}

const getTotal = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.Total, 0)
}

const getItems = computed(() => {
  const tmp = info.value.items

  const filtered = tmp.filter(a =>
    a.CardName.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.Total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
    )
  return filtered
})

const getResumen = computed(() => {
  const rows = info.value.items[contratoSel.value]
  if (rows === undefined) return []
  const filtered = rows.filter(a =>
    a.CardName.toLowerCase().includes(searchNivel3.value.toLowerCase()) ||
    a.Cantidad.toString().toLowerCase().includes(searchNivel3.value.toLowerCase()) ||
    a.Total.toString().toLowerCase().includes(searchNivel3.value.toLowerCase())
    )
  return filtered
})

export {
  estaCargando,
  estado,
  info,
  chartPorcentaje,
  chartLabels,
  chartData,
  searchNivel2,
  searchNivel3,
  getItems,
  getResumen,
  fetchFacturacion,
  setEstado,
  getCantidad,
  getTotal,
  setContratoSel
}
