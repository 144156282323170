import { computed, ref } from 'vue'
import { fetchCarteraCliente } from './Service'

const info = ref({
  totales: {
    cartera: 0,
    saldo: 0,
    TotalPagoporReconciliar: 0,
    Totalnovencido: 0,
    '0-30': 0,
    '31-60': 0,
    '61-90': 0,
    '91-120': 0,
    '121-150': 0,
    '151-180': 0,
    '>180': 0
  },
  items: []
})
const orden = ref({
  cliente: 'asc',
  total: 'neutral'
})
const estaCargando = ref(true)
const estado = ref(1)
const seleccionado = ref({})
const searchNivel2 = ref('')
const totalesExpandido = ref(false)

const getTotales = computed(() => {
  if (totalesExpandido.value) {
    const obj = {
      ...info.value.totales,
      'pago por reconciliar': info.value.totales.TotalPagoporReconciliar,
      'total no vencido': info.value.totales.Totalnovencido
    }
    delete obj.TotalPagoporReconciliar
    delete obj.Totalnovencido
    return obj
  }
  return {
    cartera: info.value.totales.cartera,
    saldo: info.value.totales.saldo,
    'pago por reconciliar': info.value.totales.TotalPagoporReconciliar,
    'total no vencido': info.value.totales.Totalnovencido
  }
})

const getItems = computed(() => {
  const filtered = info.value.items.filter(a =>
    a.name.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.cartera.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
    a.saldo.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
    )
  return filtered
})

const setTotalesExpandido = (state) => {
  totalesExpandido.value = state
}
const setEstado = (value) => {
  estado.value = value
}
const getData = (value) => {
  estaCargando.value = true
  return fetchCarteraCliente(value).then(({ status, data }) => {
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err)
  })
}
const setSeleccion = (value, state = null) => {
  if (state) {
    estado.value = state
  }
  seleccionado.value = value
}

export {
  estaCargando,
  estado,
  orden,
  info,
  getItems,
  searchNivel2,
  seleccionado,
  getTotales,
  totalesExpandido,
  setTotalesExpandido,
  getData,
  setEstado,
  setSeleccion
}
