<template>
  <div class="box">

    <div class="accordion-item col-span-12 text-xs">
      <div id="faq-accordion-content" class="border-b border-gray-200 dark:border-dark-5 accordion-header flex justify-between col-span-2" style="box-shadow: inset 0 -1px 0 rgb(229, 231, 235);">
        <div class="flex flex-col sm:flex-row items-center p-4">
          <h4 class="mr-auto capitalize text-base font-medium font-bold">Entradas de Mercancia (Analisis de precios)</h4>
        </div>
        <button
          class="accordion-button text-sm font-medium focus:outline-none text-primary-1 dark:text-white"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#faq-accordion-collapse-1"
          aria-expanded="true"
          aria-controls="faq-accordion-collapse-1"
          @click="isCollapsedGlobal = !isCollapsedGlobal"
        >
          <div class="flex flex-col sm:flex-row items-center p-4 text-xl">
            <Icon
              :icon="`${isCollapsedGlobal ? 'ic:baseline-keyboard-double-arrow-down' : 'ic:baseline-keyboard-double-arrow-up'}`"
              v-tippy="{ content: `${isCollapsedGlobal ? 'Mostrar' : 'Ocultar' }`}"
            />
          </div>
        </button>
      </div>
      <div id="faq-accordion-collapse" class="accordion-collapse collapse show" aria-labelledby="faq-accordion-content" data-bs-parent="#faq-accordion">
        <div class="accordion-body text-gray-700 dark:text-gray-600 leading-relaxed">
          <div v-show="loading" style="width:100%; height: 780px" class="rounded-md icono-carga flex justify-center items-center relative">
            <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
          </div>

          <div v-show="!loading" class="grid md:grid-cols-12 sm:grid-cols-12 grid-cols-1 gap-5 p-4">
            <div class="col-span-1 sm:col-span-12 md:col-span-12">
              <Filters />
            </div>

            <div
              :class="`col-span-1 ${isExpandedGeneral ? 'md:col-span-12' : 'sm:col-span-12 md:col-span-5'}`"
            >
              <ListTableGeneral />
            </div>

            <div
              :class="`col-span-1 ${isExpandedGeneral ? 'col-span-12' : 'sm:col-span-12 md:col-span-7'}`"
            >
              <DetailsCatalogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import ListTableGeneral from './components/listTableGeneral.vue'
import DetailsCatalogo from './components/detailsCatalogo.vue'
import Filters from './components/filters.vue'
import { useInfoComprasCatalogoStore } from './stores/infoComprasCatalogo'
import { computed, ref } from 'vue'
import Icon from '@/components/Icon/index.vue'

const infoComprasCatalogoStore = useInfoComprasCatalogoStore()
const isCollapsedGlobal = ref(false)

// Computada para traer la info del expandido
const isExpandedGeneral = computed(() => {
  return infoComprasCatalogoStore.getExpandedGeneral
})

// Computadas para verificar el loading del store
const loading = computed(() => {
  return infoComprasCatalogoStore.getLoading
})
</script>

<style scoped>

</style>
