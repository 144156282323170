<template>
  <div class="grid grid-cols-12 gap-x-4 gap-y-2">
    <div class="col-span-6 sm:col-span-2">
      <label for="start_year" class="block text-sm font-medium text-gray-700">Año:</label>
      <select class="form-select form-select-sm mt-1" id="start_year" v-model="filters.year" @change="handleYearChange">
        <option value="">- Seleccione una opción -</option>
        <option v-for="(row,k) in filterYears" :key="k" :value="row">{{ row }}</option>
      </select>
    </div>
    <div class="col-span-6 sm:col-span-2">
      <label for="start_month" class="block text-sm font-medium text-gray-700">Mes:</label>
      <select class="form-select form-select-sm mt-1" id="start_month" v-model="filters.month" :disabled="disabledMonth">
        <option value="">- Seleccione una opción -</option>
        <option v-for="(row,k) in months" :key="k" :value="row.id">{{ row.name }}</option>
      </select>
    </div>
    <div class="col-span-12 sm:col-span-6">
      <label for="start_month" class="block text-sm font-medium text-gray-700">Artículo:</label>
      <div class="flex w-full">
        <AutoComplete
          v-model="filters.itemCode"
          :minLength="4"
          :suggestions="items"
          @complete="getSearchArticulos"
          field="label"
          inputClass="w-full"
          :panelClass="'items-autocomplete-panel'"
          class="w-full input-custom-autocomplete"
          placeholder="Digita al menos 4 caracteres para buscar..."
          ref="autoCompleteItems"
          @click="handleClick"
        />
      </div>
    </div>
    <div class="col-span-12 sm:col-span-2">
      <label for="start_month" class="block text-sm font-medium text-gray-700">Acciones:</label>
      <div class="flex w-full">
        <Button
          label="Buscar"
          icon="pi pi-search"
          class="p-button-xs mt-1 h-7 w-full sm:mr-2"
          @click="handleSearch"
        />
        <Button
          label="Limpiar"
          icon="pi pi-trash"
          class="p-button-xs p-button-danger mt-1 h-7 w-full"
          @click="handleReset"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from 'vue'
import { getListItems } from '../services/getItems.service'
import { useFiltersCardComprasCatalogoStore } from '../stores/filtersCardComprasCatalogo'
import { useInfoComprasCatalogoStore } from '../stores/infoComprasCatalogo'
import dayjs from 'dayjs'

const infoComprasCatalogoStore = useInfoComprasCatalogoStore()
const filtersCardComprasCatalogoStore = useFiltersCardComprasCatalogoStore()
const items = ref([])
const disabledMonth = ref(false)
const autoCompleteItems = ref(null)
const filters = ref({
  year: '',
  month: '',
  itemCode: {
    value: '',
    label: ''
  }
})
const infoComprasCatalogo = reactive({
  ItemCode: '',
  ItemName: '',
  Year: '',
  Month: '',
  U_PHR_AgrupCom: ''
})

const years = ref([
  2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017
])

const months = ref([
  { id: 1, name: 'Enero' },
  { id: 2, name: 'Febrero' },
  { id: 3, name: 'Marzo' },
  { id: 4, name: 'Abril' },
  { id: 5, name: 'Mayo' },
  { id: 6, name: 'Junio' },
  { id: 7, name: 'Julio' },
  { id: 8, name: 'Agosto' },
  { id: 9, name: 'Septiembre' },
  { id: 10, name: 'Octubre' },
  { id: 11, name: 'Noviembre' },
  { id: 12, name: 'Diciembre' }
])

// Computada para mostrar los años del 2017 al actual
const filterYears = computed(() => {
  return years.value.filter((itemYear) => {
    return itemYear <= dayjs().year()
  })
})

// Select input autocomplete
const handleClick = () => {
  const inputEl = autoCompleteItems.value.$el.querySelector('.p-autocomplete-input')
  inputEl.select()
}

// Computada para obtener el store de filtros
const getterFiltersComprasCatalogo = computed(() => {
  return filtersCardComprasCatalogoStore.getFilters
})

// Desactivar el select de mes si no se ha seleccionado un año
const handleYearChange = () => {
  if (filters.value.year === '') {
    filters.value.month = ''
    disabledMonth.value = true
  } else {
    disabledMonth.value = false
  }
}

// Obtener los items
const getSearchArticulos = async (event) => {
  const query = event.query
  if (query.length >= 4) {
    try {
      const { data } = await getListItems({ query: query.trim() })
      if (data.items.length > 0) {
        items.value = data.items.map((item) => ({
          label: item.ItemCode + '-' + item.ItemName,
          value: item.ItemCode
        }))
      } else {
        items.value = []
      }
    } catch (error) {
      console.log('Error al traer los items: ', error)
    }
  }
}

// Realizar la búsqueda y setear los filtros en el store
const handleSearch = () => {
  const filtersData = {
    year: filters.value.year ?? parseInt(filters.value.year),
    month: filters.value.month ?? parseInt(filters.value.month),
    itemCode: filters.value.itemCode.value ?? filters.value.itemCode
  }

  infoComprasCatalogoStore.setUpdateInfoComprasCatalogo(infoComprasCatalogo)
  filtersCardComprasCatalogoStore.setUpdateFilters(filtersData)
}

// Limpiar los filtros
const handleReset = () => {
  filters.value = {
    year: '',
    month: '',
    itemCode: '',
    agrupacion: ''
  }
  filtersCardComprasCatalogoStore.setUpdateFilters(filters.value)

  // Limpiar el store de los items
  infoComprasCatalogoStore.setUpdateInfoComprasCatalogo(infoComprasCatalogo)
  disabledMonth.value = getterFiltersComprasCatalogo.value.year === ''
}

// Onmounted
onMounted(() => {
  // Obtener los filtros del store
  const filtersData = filtersCardComprasCatalogoStore.getFilters
  filters.value = filtersData
  disabledMonth.value = filtersData.year === ''
})
</script>

<style scoped>
:global(.p-autocomplete.input-custom-autocomplete .p-inputtext.p-component) {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
  margin-top: 4px;
}
:global(.items-autocomplete-panel) {
  max-height: 300px !important;
  max-width: 350px;
}
:global(.p-autocomplete-panel.p-component.items-autocomplete-panel) {
  font-size: 0.7rem;
}
:global(.p-autocomplete-panel.p-component.items-autocomplete-panel .p-autocomplete-item) {
  padding: 0.3rem 0.75rem;
  white-space: normal;
  max-height: 4rem;
  overflow: auto;
  line-height: 1.5;
}
</style>
