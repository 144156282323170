import { defineStore } from 'pinia'
import Service from '../services/remisionesVsFacturas'
const _service = new Service()
export const storeCardRemisionVsFacturas = defineStore('storeCardRemisionVsFacturas', {
  state: () => ({
    _vlrTotalRemisiones: 0,
    _vlrTotalFacturas: 0
  }),
  getters: {
    totalRemisiones: state => state._vlrTotalRemisiones,
    totalFacturas: state => state._vlrTotalFacturas
  },
  actions: {
      getData (payload) {
        _service.getData(payload).then(({ data }) => {
          this._vlrTotalRemisiones = data.vlrTotalRemisiones || 0
          this._vlrTotalFacturas = data.vlrTotalFacturas || 0
        })
      }
  }
})
