<template>
  <section class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
    <div class=" box p-5 zoom-in scroll-ajuste">

      <div
        v-if="$can('pharmasan.dashboard.ver-consultas')"
        class="absolute top-0 left-0 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-blue-500 rounded-full"
        @click="openModalConsulta"
      >
        <Icon
          :icon="`mdi:sql-query`"
          class="absolute text-gray-200 text-sm"
          style="bottom: 7px; right: 7px" v-tippy="{ content: 'Visualizar consulta' }"
        />
      </div>

      <div v-if="estaCargando" class="rounded-md icono-carga p-8">
        <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
      </div>
      <template v-if="estado === 1">
        <div class="grid grid-cols-2 gap-4 text-xs">
          <div class="flex w-full flex-col scroll-ajuste" @click="setEstado(2, 'Evento')">
            <div class="mt-1">
              <div class="font-medium capitalize">
                Fact Anual (Evento)
              </div>
              <div class="text-gray-600  w-full cursor-pointer mt-1">
                {{$h.formatCurrency(info.totalFactYearEvento)}}
              </div>
            </div>
            <div class="mt-1">
              <div class="font-medium capitalize mt-1">
                Fact Mes (Evento)
              </div>
              <div class="text-gray-600 w-full cursor-pointer mt-1">
                {{$h.formatCurrency(info.totalFactMonthEvento)}}
              </div>
            </div>
          </div>
          <div class="flex w-full flex-col scroll-ajuste" @click="setEstado(2, 'Capita')">
            <div class="mt-1">
              <div class="font-medium capitalize">
                Fact Anual (Capita)
              </div>
              <div class="text-gray-600  w-full cursor-pointer mt-1">
                {{$h.formatCurrency(info.totalFactYearCapita)}}
              </div>
            </div>
            <div class="mt-1">
              <div class="font-medium capitalize mt-1">
                Fact Mes (Capita)
              </div>
              <div class="text-gray-600 w-full cursor-pointer mt-1">
                {{$h.formatCurrency(info.totalFactMonthCapita)}}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
        <div class="flex items-center justify-between w-full">
          <div class="font-medium truncate text-sm">Facturación Anual ({{ typeModalidad }})</div>
          <button
            type="button"
            class="btn btn-atras"
            @click="setEstado(1, typeModalidad)"
            >
              <svg style="width:12px;height:12px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
              </svg>
            </button>
        </div>
        <div class="w-full flex justify-between">
          <input
          v-model="searchNivel2"
          type="search"
          placeholder="Buscar"
          class="border border-gray-400 px-2 py-1 rounded-md dark:text-primary-1"
          >
        </div>
        <div class="w-full overflow-x-auto letra">
            <div class="w-full flex">
              <div class="font-bold" style="width:55%;">Cliente</div>
              <div class="font-bold" style="width:100px;">&nbsp;</div>
              <div class="font-bold text-right" style="width:130px;">&nbsp;</div>
            </div>

            <div v-for="(row,k) in getDataCliente" :key="k" class="w-full flex flex-col">
              <div class="flex w-full">
                <div class="mb-1 text-primary-1 dark:text-white" style="width:55%;">
                  {{row.cliente}}
                </div>
                <div class="mb-1 font-bold bg-gray-100" style="width:100px;">Total</div>
                <div class="mb-1 text-right bg-gray-100" style="width:130px;">{{row.total}}</div>
              </div>
              <div class="flex w-full">
                <div style="width:55%;"></div>
                <div class="font-bold bg-gray-100" style="width:100px;">%Rent.</div>
                <div class="font-bold bg-gray-100 text-right" style="width:130px;">Costo</div>
              </div>
              <div class="flex w-full  mb-2 border-b pb-2">
                <div class="" style="width:55%;"></div>
                <div class="bg-gray-100" style="width:100px;">{{row.renta}}</div>
                <div class="bg-gray-100 text-right" style="width:130px;">{{row.costos}}</div>
              </div>
            </div>

          </div>
      </template>
    </div>
  </section>

  <Dialog
    v-model:visible="modalVisible"
    dismissableMask
    closeOnEscape
    maximizable
    modal
    :style="{ width: '50rem' }"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="{
      header: {
        style: 'padding: 0.75rem;'
      }
    }"
  >
    <!-- Header del modal -->
    <template #header>
      <div class="flex items-center text-xl font-bold text-blue-600">
        <i class="pi pi-file mr-2"></i>
        <h4>Consulta - {{ estado === 1 ? info.name : nameCliente }}</h4>
      </div>
    </template>

    <!-- Cuerpo del modal -->
    <p class="m-0">
      <highlightjs
        autodetect
        :code="estado === 1 ? info.query : queryCliente"
      />
    </p>

    <!-- Footer del modal -->
    <template #footer>
      <Button label="Cerrar" icon="pi pi-times" @click="closeModalConsulta" class="p-button-xs p-button-danger"/>
    </template>
  </Dialog>

</template>
<script>
import Icon from '@/components/Icon/index.vue'
import 'highlight.js/styles/atom-one-dark.min.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

import { defineComponent, ref, watchEffect } from 'vue'
import * as parameters from './Hook'
// import { info, getData, estaCargando } from './Hook'
export default defineComponent({
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  name: 'Card Facturacion Totales',
  components: {
    Icon,
    highlightjs: hljsVuePlugin.component
  },
  setup (props, context) {
    const modalVisible = ref(false)

    const openModalConsulta = () => {
      modalVisible.value = true
    }

    /* Cerrar el modal */
    const closeModalConsulta = () => {
      modalVisible.value = false
    }

    watchEffect(() => {
      // getData({
      //   year: props.year,
      //   month: props.month
      // })
      parameters.getData({
        year: props.year,
        month: props.month
      })
    })
    return {
      // estaCargando,
      // info
      openModalConsulta,
      closeModalConsulta,
      modalVisible,
      ...parameters
    }
  }
})
</script>

<style scoped>
.letra{
  font-size: 0.8em;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 295px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
.table_cards{
  text-align: start;
  font-size: 0.7rem;
  margin-top: 10px;
  line-height: 1.1rem;
}
</style>
