import { computed, ref } from 'vue'
import { fetchFacturasPorRadicar } from './Service'
import axios from 'axios'

const info = ref({
  value: []
})
const TotalMeses = ref([
  { mes: 'Enero', Total: 0 }
])
const estaCargando = ref(true)
const TotalSuma = ref(0)
const buscar = ref('')
const filtroMes = ref('')
const estado = ref(1)
const searchNivel2 = ref('')
const seleccionado = ref({})
const meses = [
  { id: 1, nombre: 'Enero' },
  { id: 2, nombre: 'Febrero' },
  { id: 3, nombre: 'Marzo' },
  { id: 4, nombre: 'Abril' },
  { id: 5, nombre: 'Mayo' },
  { id: 6, nombre: 'Junio' },
  { id: 7, nombre: 'Julio' },
  { id: 8, nombre: 'Agosto' },
  { id: 9, nombre: 'Septiembre' },
  { id: 10, nombre: 'Octubre' },
  { id: 11, nombre: 'Noviembre' },
  { id: 12, nombre: 'Diciembre' }
]

const setEstado = (value, mes) => {
  // console.log('Le dieron click al ver mas', value)
  estado.value = value
  filtroMes.value = mes
}
const totalesExpandido = ref(false)
const setTotalesExpandido = (state) => {
  totalesExpandido.value = state
}
const getTotal = computed(() => {
  // var suma = 0
  var datoMes = {}
  var ValTotal = []
  if (info.value.result) {
    var init = 0
    meses.map((m, idxM) => {
      datoMes = { Total: 0, Valor: 0 }
      info.value.result.map((i, idxRes) => {
        if (m.id === i.Mes) {
          datoMes.idMes = m.id
          datoMes.Mes = m.nombre ? m.nombre : 'Sin nombre corto'
          datoMes.Total = datoMes.Total + parseInt(i.Total)
          datoMes.Valor = datoMes.Valor + parseInt(i.Valor)
          }
      })
      if (datoMes.Total > 0) {
        if (init === 0) { init = idxM }
        if (idxM > 10) { init = 10 }
        if (!totalesExpandido.value) {
          if (idxM < init + 3) {
            ValTotal.push(datoMes)
          }
        } else {
          ValTotal.push(datoMes)
        }
      }
    })
    if (totalesExpandido.value !== 2) {
      TotalSuma.value = ValTotal
      return TotalSuma.value
    }
  }
  return info.value.result
})
const getData = (query = {}) => {
  estaCargando.value = true
  return fetchFacturasPorRadicar(query).then(({ status, data }) => {
    if (status === 200) {
      info.value = data
      estaCargando.value = false
    }
  }).catch(err => {
    console.error(err)
    estaCargando.value = false
  })
}
const setSeleccion = (value, state = null) => {
  if (state) {
    estado.value = state
  }
  seleccionado.value = value
}

const getItems = computed(() => {
  return info.value.result.map(a => {
    a.Cliente = a.Cliente ? a.Cliente : 'N/A'
    return a
  }).filter(a => a.Cliente.toLocaleLowerCase().includes(buscar.value.toLocaleLowerCase()))
})

const getItemsProv = computed(() => {
    var result = []
    info.value.result.map(f => {
      if (f.Mes === filtroMes.value) {
        result.push(f)
      }
    })

    const filtered = result.filter(a =>
      a.Cliente.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
      a.Total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
      a.Valor.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
      )

  return filtered.sort(function (a, b) {
    if (a.Total < b.Total) {
      return 1
    }
    if (a.Total > b.Total) {
      return -1
    }
    // a must be equal to b
    return 0
  })
})

const handleExcelDownload = (year) => {
  const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/export-facturas-radicar/export`
  const res = axios.getUri({ url, params: year })
  window.open(res, '_blank')
}
export {
  meses,
  TotalMeses,
  TotalSuma,
  handleExcelDownload,
  estaCargando,
  buscar,
  estado,
  info,
  seleccionado,
  getItems,
  searchNivel2,
  getItemsProv,
  getTotal,
  totalesExpandido,
  setTotalesExpandido,
  getData,
  setEstado,
  setSeleccion
}
