<template>
  <section>
    <div
      class="box w-full relative"
      style="overflow:hidden;">
      <div
        id="graficaTotalTurnos"
        ref="elGraficaTurnosTotal"
        style="height:550px;"
        class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref } from 'vue'
import { useStore } from 'vuex'

const navegadorVentanaAncho = 1024
export default {
  name: 'TotalTurnos',
  setup () {
    const store = useStore()
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const colorLabel = ref('white')
    const radiusPie = ref('')
    const fontSizeLabel = ref(0)
    const estaCargando = ref(true)
    let chartInstance = reactive({})
    const elGraficaTurnosTotal = ref(null)
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })

    gradesRotation.value = (window.innerWidth < 1000) ? 0 : 0
    positionLabel.value = (window.innerWidth < 1000) ? 'right' : 'right'
    distanceLabel.value = (window.innerWidth < 1000) ? 15 : 10
    colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'

    const initChart = () => {
      chartInstance = echarts.init(elGraficaTurnosTotal.value, isDark.value ? 'dark' : 'light')
      const option = {
        title: {
          top: 15,
          left: 15,
          right: 15,
          text: 'Total turnos por sede'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          left: 15,
          right: 15,
          top: 50,
          orient: 'horizontal'
        },
        series: []
      }
      chartInstance.setOption(option)
    }

    const reloadChart = (data, ov, serv) => {
      var getLabels = data.map(f => f.name)
      data.map((f, idx) => {
        if (f.name === ov[idx].name) {
          data[idx].ov = ov[idx].value
        }
        if (f.name === serv[idx].name) {
          data[idx].servicios = serv[idx].infoTipoServicio.servicios
        }
      })
      fontSizeLabel.value = (window.innerWidth < 1000) ? 8 : 10
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        grid: {
          containLabel: true
        },
        tooltip: {
          top: 0,
          position: (pos) => {
            if (window.innerWidth > navegadorVentanaAncho) {
              return pos[0]
            } else {
              return ['5%', '0']
            }
          },
          formatter (a) {
            let serv = ''
              serv = serv + `<div class="title-tool"><p style="width:75%">${a.name}<p><p style="width:25%;text-align: right;">${a.data.value}<p></div><br/>`
              a.data.servicios.forEach(el => {
                serv = serv + `<div style="display:flex;width:250px;"><p style="width:75%">${el.servicio}<p><p style="width:25%;text-align: right;">${el.total_turnos}<p></div>`
              })
            return serv
          }
        },
        xAxis: [
          {
            show: false
          }
        ],
        yAxis: {
          data: getLabels,
          axisLabel: {
            interval: 0,
            fontSize: 10,
            width: 60
            // overflow: 'break'
            // formatter (value) {
            //   return value.split(' ').join('\n')
            // }
          }
        },
        series: [
          {
            selectedMode: true,
            type: 'bar',
            label: {
              fontSize: fontSizeLabel.value,
              fontWeight: '500',
              formatter (a) {
                return `${a.data.value}`
              },
              show: true,
              color: colorLabel.value,
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            },
            data: data,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }

      chartInstance.setOption(option)
      loadEvents()
    }

    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        fontSizeLabel.value = (window.innerWidth < 1000) ? 8 : 10
        chartInstance.setOption({
          series: [{
            radius: radiusPie.value,
            label: {
              fontSize: fontSizeLabel.value
            }
          }]
        })
        chartInstance.resize()
      })
    }

    onMounted(() => {
      initChart()
    })

    return {
      elGraficaTurnosTotal,
      chartInstance,
      estaCargando,
      reloadChart
    }
  }
}
</script>

<style scoped>

</style>
