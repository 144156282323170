<template>
  <Toast />
  <section class="w-full">
    <div class="flex justify-between items-center">
      <div class="flex justify-start">
        <span class="text-sm font-semibold">Listado General</span>
      </div>
      <div class="flex justify-end">
        <Button
          icon="pi pi-file-excel"
          class="p-button sm:mr-2 p-button-success hidden p-mt-auto my-auto sm:flex h-7"
          @click="onBtnExportExcel"
          v-tippy="{ content: 'Exportar a excel' }"
        />
        <Button
          icon="pi"
          severity="Expandir"
          class="p-button hidden sm:flex h-7"
          @click="clicExpand"
          v-tippy="{ content: `${getInfoComprasCatalogoExpanded ? 'Collapsar' : 'Expandir' }` }"
        >
          <Icon :icon="`${getInfoComprasCatalogoExpanded ? 'mdi:arrow-collapse-all' : 'mdi:arrow-expand-all' }`" class="text-lg"/>
        </Button>
      </div>
    </div>

    <div class="pt-2">
      <ag-grid-vue
        class="ag-theme-alpine dark:ag-theme-alpine-dark ag-grid-compras-catalogo"
        style="height: calc(740px)"
        animateRows="true"
        rowSelection="multiple"
        @grid-ready="onGridReady"
        @first-data-rendered="onFirstDataRendered"
        :columnDefs="columnDefs.value"
        :rowData="rowData.value"
        :defaultColDef="defaultColDef"
        :components="components"
        :gridOptions="gridOptions"
        :localeText="localeText"
        :popupParent="popupParent"
        :onRowClicked="onRowClicked"
        :getRowStyle="getRowStyle"
        :statusBar="statusBar"
        :suppressDragLeaveHidesColumns="true"
      >
      </ag-grid-vue>
    </div>

<!--    <pre>{{ JSON.stringify(getterFiltersComprasCatalogo, null, '\t') }}</pre>-->
  </section>
</template>

<script setup>
import { reactive, onMounted, ref, computed, watch } from 'vue'

import { AgGridVue } from 'ag-grid-vue3'
import 'ag-grid-enterprise' // Optional - for Enterprise features
import 'ag-grid-community/styles/ag-grid.css' // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'
import localeText from '../../../../../../lang/ag-grid/es'
import { getListGeneral } from '../services/getListGeneral.service'
import ItemInfoCell from './tableGeneral/itemInfoCell.vue'
import { helper } from '../../../../../../utils/helper'
import { useToast } from 'primevue/usetoast'

import { useFiltersCardComprasCatalogoStore } from '../stores/filtersCardComprasCatalogo'
import { useInfoComprasCatalogoStore } from '../stores/infoComprasCatalogo'
import dayjs from 'dayjs'
import Icon from '@/components/Icon/index.vue'

// Declaracion de variables y referencias
const toast = useToast()
const filtersCardComprasCatalogoStore = useFiltersCardComprasCatalogoStore()
const infoComprasCatalogoStore = useInfoComprasCatalogoStore()
const gridApi = ref(null) // Opcional - para acceder a la API de Grid
const gridColumnApi = ref(null) // Opcional - para acceder a la API de columnas de Grid
const rowData = reactive({}) // Establecer rowData como un array de objetos, un objeto por fila
// Constante para definir las opciones de la grid
const statusBar = {
  statusPanels: [
    {
      statusPanel: 'agTotalAndFilteredRowCountComponent',
      align: 'left'
    },
    {
      statusPanel: 'agTotalRowCountComponent',
      align: 'center'
    },
    {
      statusPanel: 'agFilteredRowCountComponent',
      align: 'right'
    },
    {
      statusPanel: 'agAggregationComponent',
      align: 'right'
    }
  ]
}
const popupParent = document.querySelector('body')
const infoComprasCatalogo = reactive({
  ItemCode: '',
  ItemName: '',
  Year: '',
  Month: '',
  U_PHR_AgrupCom: ''
})

// Obtener API del evento onGridReady de la grid
const onGridReady = (params) => {
  gridColumnApi.value = params.columnApi
  gridApi.value = params.api
}

// Define los componentes que se van a utilizar en el grid
const components = {
  ItemInfoCell: ItemInfoCell
}
// Cada definición de columna da lugar a una columna.
const columnDefs = reactive({
  value: [
    {
      headerName: 'Artículo',
      autoHeight: true,
      floatingFilter: true,
      filter: 'agTextColumnFilter',
      filterParams: {
        closeOnApply: true,
        buttons: ['apply', 'reset']
      },
      minWidth: 250,
      spanHeaderHeight: true,
      cellRenderer: (params) => {
        return `
          <div class="flex flex-col">
           <span>${params.data.ItemCode}</span>
           <span><span class="font-semibold">${params.data.ItemName}</span></span>
          </div>
        `
      },
      cellRendererSelector: (params) => {
        return { component: 'ItemInfoCell', params: params }
      },
      valueGetter: (params) => {
        return params.data.ItemCode + ',' + params.data.ItemName
      }
    },
    {
      field: '',
      headerName: 'Información', /* infoMesActual.value */
      children: [
        {
          headerName: 'Fecha',
          autoHeight: true,
          minWidth: 120,
          floatingFilter: true,
          filterParams: {
            closeOnApply: true,
            buttons: ['apply', 'reset']
          },
          cellRenderer: (params) => {
            const dateFormat = dayjs(params.data.Year + '-' + params.data.Month)
            return `
              <div class="flex flex-col">
                <!-- Obtener la fecha como Mes y año (Ex: Abril de 2023) con dayjs -->
                <span>${dayjs(dateFormat).format('YYYY-MM')}</span>
                <span>(${dayjs(dateFormat).format('MMMM [del] YYYY')})</span>
              </div>
            `
          },
          valueGetter: (params) => {
            return dayjs(params.data.Year + '-' + params.data.Month).format('YYYY-MM')
          }
        },
        {
          headerName: 'Minimo',
          autoHeight: true,
          minWidth: 150,
          cellRenderer: (params) => {
            return `
              <div class="flex flex-col">
               <span>${params.data.ProvPriceMin ?? ''}</span>
               <span class="font-semibold">${helper.formatCurrency(params.data.PriceMin ?? 0, 2)}</span>
              </div>
            `
          },
          valueGetter: (params) => {
            return params.data.ProvPriceMin + ',' + params.data.PriceMin
          }
        },
        {
          headerName: 'Maximo',
          autoHeight: true,
          minWidth: 150,
          cellRenderer: (params) => {
            return `
              <div class="flex flex-col">
               <span>${params.data.ProvPriceMax ?? ''}</span>
               <span class="font-semibold">${helper.formatCurrency(params.data.PriceMax ?? 0, 2)}</span>
              </div>
            `
          },
          valueGetter: (params) => {
            return params.data.ProvPriceMax + ',' + params.data.PriceMax
          }
        }
      ]
    },
    {
      field: '',
      headerName: 'Precio Catálogo Opción 1',
      autoHeight: true,
      minWidth: 200,
      spanHeaderHeight: true,
      cellRenderer: (params) => {
        return `
          <div class="flex flex-col">
           <span>${params.data.ProvCatOpc1 ?? ''}</span>
           <span class="font-semibold">${helper.formatCurrency(params.data.PriceCatOpc1 ?? 0, 2)}</span>
          </div>
        `
      },
      valueGetter: (params) => {
        return params.data.ProvCatOpc1 + ',' + params.data.PriceCatOpc1
      }
    },
    {
      field: '',
      headerName: 'Precio Catálogo Opción 2',
      autoHeight: true,
      minWidth: 200,
      spanHeaderHeight: true,
      cellRenderer: (params) => {
        return `
          <div class="flex flex-col">
           <span>${params.data.ProvCatOpc2 ?? ''}</span>
           <span class="font-semibold">${helper.formatCurrency(params.data.PriceCatOpc2 ?? 0, 2)}</span>
          </div>
        `
      },
      valueGetter: (params) => {
        return params.data.ProvCatOpc2 + ',' + params.data.PriceCatOpc2
      }
    }
  ]
})

// Exportar a excel todas las columnas de ag-grid
const onBtnExportExcel = async () => {
  const params = {
    fileName: 'Listado General',
    sheetName: 'Listado General',
    allColumns: true
  }
  gridApi.value.exportDataAsExcel(params)
}

// DefaultColDef establece los accesorios comunes a todas las columnas de la grid
const defaultColDef = {
  sortable: true,
  filter: true,
  flex: 1,
  resizable: true
}

// Las opciones de rejilla establecen accesorios comunes a todas las rejillas.
const gridOptions = {
  suppressMenuHide: true
  // suppressContextMenu: true
}

// Setear la info de la aplicación seleccionada en el store y actualizar los query params de la route
const setInfoComprasCatalogoStore = (params) => {
  infoComprasCatalogo.ItemCode = params.data.ItemCode
  infoComprasCatalogo.ItemName = params.data.ItemName
  infoComprasCatalogo.Year = params.data.Year
  infoComprasCatalogo.Month = params.data.Month
  infoComprasCatalogo.U_PHR_AgrupCom = params.data.U_PHR_AgrupCom
  infoComprasCatalogo.Cum = params.data.Cum
  infoComprasCatalogoStore.setUpdateInfoComprasCatalogo(infoComprasCatalogo)

  // Actualizar el filtro de itemCode en el store
  const filters = getterFiltersComprasCatalogo.value
  filters.itemCode = params.data.ItemCode
  filtersCardComprasCatalogoStore.setUpdateFilters(filters)
}

// Hacer clic en las filas y obtener los datos de la fila
const onRowClicked = async (params) => {
  setInfoComprasCatalogoStore(params)
}

// Seleccionar la fila con el itemCode
const selectRowByAppId = (apply, itemCode) => {
  if (apply === true) {
    gridApi.value.forEachNode((node) => {
      if (node.data.ItemCode === itemCode) {
        gridApi.value.ensureNodeVisible(node)
        node.setSelected(true)
        onRowClicked({ data: node.data })
      }
    })
  }
}

// Dar estilo a las filas
const getRowStyle = (params) => {
  return { cursor: 'pointer' }
}

// Opcional - para ajustar el tamaño de las columnas a la cuadrícula
const onFirstDataRendered = (params) => {
  params.api.sizeColumnsToFit()
}

// Computada para obtener el store de filtros
const getterFiltersComprasCatalogo = computed(() => {
  return filtersCardComprasCatalogoStore.getFilters
})

// Computada para obtener la información de compras del catálogo
const getInfoComprasCatalogoExpanded = computed(() => {
  return infoComprasCatalogoStore.getExpandedGeneral
})

// Función para expandir o contraer el card
const clicExpand = () => {
  infoComprasCatalogoStore.setExpandedGeneral(!infoComprasCatalogoStore.getExpandedGeneral)
}

// Función para obtener los datos de la API
const getData = async (newFilter) => {
  try {
    const filtersGlobal = {
      ...getterFiltersComprasCatalogo.value
    }

    infoComprasCatalogoStore.setLoading(true)
    const { data } = await getListGeneral(filtersGlobal)
    console.log('data lenght ---> ', data.totalItems)
    if ([null, '', 0, undefined].includes(data.totalItems)) {
      infoComprasCatalogoStore.setLoading(false)
      rowData.value = []
      toast.add({
        severity: 'warn',
        summary: 'Advertencia',
        detail: `No se encontró información para el artículo ${getterFiltersComprasCatalogo.value.itemCode}`,
        life: 3000
      })
      return
    }

    // Si U_PHR_AgrupCom es null, se muestra un mensaje de advertencia
    if (data.items[0].U_PHR_AgrupCom === null) {
      toast.add({
        severity: 'info',
        summary: 'Información',
        detail: `El artículo ${getterFiltersComprasCatalogo.value.itemCode} - ${data.items[0].ItemName} no tiene asociada una agrupación de compras`,
        life: 5000
      })
    }

    console.log('data ---> ', data)
    infoComprasCatalogoStore.setLoading(false)
    rowData.value = data.items
  } catch (error) {
    console.log('Error al obtener los datos ---> ', error)
  }
}

// Función para limpiar infoComprasCatalogo
const clearInfoComprasCatalogo = () => {
  infoComprasCatalogo.ItemCode = ''
  infoComprasCatalogo.ItemName = ''
  infoComprasCatalogo.Year = ''
  infoComprasCatalogo.Month = ''
  infoComprasCatalogo.U_PHR_AgrupCom = ''
  infoComprasCatalogoStore.setUpdateInfoComprasCatalogo(infoComprasCatalogo)
}

// Watch si cambian los filtros
watch(getterFiltersComprasCatalogo, async (newFilter) => {
  await getData(newFilter)
  if (newFilter.itemCode !== '') {
    selectRowByAppId(true, newFilter.itemCode)
  } else {
    // Limpiar el store de los items
    clearInfoComprasCatalogo()
    selectRowByAppId(false, '')
  }
})

// Onmounted
onMounted(async () => {
  await getData()
})
</script>

<style>

.ag-grid-compras-catalogo .ag-row .ag-cell {
  font-size: 10px;
  line-height: 2;
}
.ag-theme-alpine {
  --ag-grid-size: 4px !important;
  --ag-list-item-height: 20px !important;
  --ag-font-size: 10px !important;
}
</style>
