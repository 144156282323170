<template>
  <div
    class="sm:ml-auto mt-3 sm:mt-0 relative text-gray-700 dark:text-gray-300"
    style="display:flex;"
  >
    <div class="flex align-baseline mr-2">
      <!-- <ClipboardList class="my-auto inset-y-0 ml-3 left-0" size="36" /> -->
    </div>
    <div class="border p-2">
      <div style="width: auto; display: flex;">
        <div class="form-inline">
          <label class="mr-2">Rango de Fechas:</label>
          <DatePicker v-model="range" is-range mode="date" >
            <template v-slot="{ inputValue, inputEvents }">
              <div class="flex justify-center items-center">
                <input
                  :value="inputValue.start"
                  v-on="inputEvents.start"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
                <svg
                  class="w-4 h-4 mx-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
                <input
                  :value="inputValue.end"
                  v-on="inputEvents.end"
                  class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
                />
              </div>
            </template>
          </DatePicker>
        </div>
      </div>
    </div>
    <!-- <button
        type="button"
        class="bg-green-600 text-white px-3 py-2 ml-3 rounded-md my-auto"
        @click="handleDownloadXls()"
        >
          Descargar XLS
    </button> -->
  </div>
</template>

<script>
import { warning } from '../../../../../../libs/mensajes'
import { ref, watch, onMounted } from 'vue'
import { DatePicker } from 'v-calendar'
import axios from 'axios'
import { helper } from '../../../../../../utils/helper'

export default {
  components: {
    DatePicker
  },
  setup (props, context) {
    const range = ref({
      start: new Date(),
      end: new Date()
    })
    const fechas = ref({
      fecha_inicial: helper.formatDate(new Date(), 'YYYY-MM-DD'),
      fecha_final: helper.formatDate(new Date(), 'YYYY-MM-DD')
    })

    watch(range, (fecha) => {
      actualizarFechas()
    })

    const actualizarFechas = () => {
        var fecha = range.value
        const actualizarFiltroPadreProps = {
        fecha_inicial: helper.formatDate(fecha.start, 'YYYY-MM-DD'),
        fecha_final: helper.formatDate(fecha.end, 'YYYY-MM-DD')
      }
      fechas.value.fecha_inicial = helper.formatDate(fecha.start, 'YYYY-MM-DD')
      fechas.value.fecha_final = helper.formatDate(fecha.end, 'YYYY-MM-DD')
      context.emit('updateFilters', actualizarFiltroPadreProps)
    }

    onMounted(() => {
      actualizarFechas()
    })

    // const handleDownloadXls = () => {
    //   const url = `${process.env.VUE_APP_MICROSERVICE_API_DASHBOARD}/reporte-digiturnos`
    //      const res = axios.getUri({ url, params: range })
    //      if (range.value.start !== null) {
    //        window.open(res, '_blank')
    //      } else {
    //       warning('No se encontraron datos de digiturnos')
    //     }
    // }

    const handleDownloadXls = () => {
      // const url = 'http://127.0.0.1:8000/reports/pharma_conection/DigiturnosReporteSede.xls?location=77&fecha_inicio=2021-09-22&fecha_fin=2021-09-22'
      const url = '/pharma_conection/DigiturnosReporteSedeXLS.xls'
      if (fechas.value.fecha_inicial !== '' && fechas.value.fecha_inicial !== null && fechas.value.fecha_inicial <= helper.formatDate(new Date(), 'YYYY-MM-DD')) {
        const api = `${process.env.VUE_APP_MICROSERVICE_API_JASPER_REPORT}`
        var filters = {
          location: '',
          fecha_inicio: fechas.value.fecha_inicial,
          fecha_fin: fechas.value.fecha_final
        }
        // const res = axios.getUri({ api, params: filters })
        const res = axios.getUri({ params: filters, url: api + url })
           window.open(res, '_blank')
         } else {
          warning('No se encontraron datos de digiturnos')
        }
    }

    return {
      range,
      DatePicker,
      handleDownloadXls
    }
  }
}
</script>

<style scoped>

</style>
