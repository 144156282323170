// Example locale file for English, give this to your locale team to translate

export default {
  // Set Filter
  selectAll: '(Seleccionar Todo)',
  selectAllSearchResults: '(Seleccionar todos los resultados de búsqueda)',
  searchOoo: 'Buscar...',
  blanks: '(Espacios en blanco)',
  noMatches: 'No hay coincidencias',

  // Number Filter & Text Filter
  filterOoo: 'Filtro...',
  equals: 'Igual',
  notEqual: 'No igual',
  blank: 'En blanco',
  notBlank: 'No en blanco',
  empty: 'Elige uno',

  // Number Filter
  lessThan: 'Menor que',
  greaterThan: 'Mayor que',
  lessThanOrEqual: 'Menor o igual que',
  greaterThanOrEqual: 'Mayor o igual',
  inRange: 'En rango',
  inRangeStart: 'desde',
  inRangeEnd: 'hasta',

  // Text Filter
  contains: 'Contiene',
  notContains: 'No contiene',
  startsWith: 'Empieza por',
  endsWith: 'Termina con',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: 'Aplicar',
  resetFilter: 'Restablecer',
  clearFilter: 'Borrar',
  cancelFilter: 'Cancelar',

  // Filter Titles
  textFilter: 'Filtro Texto',
  numberFilter: 'Filtro numérico',
  dateFilter: 'Filtro de fecha',
  setFilter: 'Fijar Filter',

  // Group Column Filter
  groupFilterSelect: 'Seleccionar campo:',

  // Side Bar
  columns: 'Columnas',
  filters: 'Filtros',

  // columns tool panel
  pivotMode: 'Modo Pivote',
  groups: 'Grupos de filas',
  rowGroupColumnsEmptyMessage: 'Arrastre aquí para establecer grupos de filas',
  values: 'Valores',
  valueColumnsEmptyMessage: 'Arrastre aquí para agregar',
  pivots: 'Etiquetas de columnas',
  pivotColumnsEmptyMessage:
    'Arrastre aquí para establecer etiquetas de columna',

  // Header of the Default Group Column
  group: 'Grupo',

  // Row Drag
  rowDragRow: 'fila',
  rowDragRows: 'filas',

  // Other
  loadingOoo: 'Cargando...',
  loadingError: 'ERR',
  noRowsToShow: 'No mostrar filas',
  enabled: 'Activado',

  // Menu
  pinColumn: 'Pin Columna',
  pinLeft: 'Pin Izquierda',
  pinRight: 'Pin Derecha',
  noPin: 'No Pin',
  valueAggregation: 'Agregación de valores',
  noAggregation: 'Ninguno',
  autosizeThiscolumn: 'Tamaño automático de esta columna',
  autosizeAllColumns: 'Tamaño automático de todas las columnas',
  groupBy: 'Agrupar por',
  ungroupBy: 'Desagrupar por',
  // eslint-disable-next-line no-template-curly-in-string
  addToValues: 'Añadir ${variable} a valores',
  // eslint-disable-next-line no-template-curly-in-string
  removeFromValues: 'Quitar ${variable} de los valores',
  // eslint-disable-next-line no-template-curly-in-string
  addToLabels: 'Añadir ${variable} a las etiquetas',
  // eslint-disable-next-line no-template-curly-in-string
  removeFromLabels: 'Quitar ${variable} de las etiquetas',
  resetColumns: 'Resetear Columnas',
  expandAll: 'Expandir Todo',
  collapseAll: 'Cerrar Todo',
  copy: 'Copiar',
  ctrlC: 'Ctrl+C',
  ctrlX: 'Ctrl+X',
  copyWithHeaders: 'Copiar con cabeceras',
  copyWithGroupHeaders: 'Copiar con cabeceras de grupo',
  cut: 'Cortar',
  paste: 'Pegar',
  ctrlV: 'Ctrl+V',
  export: 'Exportar',
  csvExport: 'Exportar CSV',
  excelExport: 'Exportar Excel',

  // Enterprise Menu Aggregation and Status Bar
  sum: 'Suma',
  first: 'Primero',
  last: 'Último',
  min: 'Mín',
  max: 'Max',
  none: 'Ninguno',
  count: 'Recuento',
  avg: 'Media',
  filteredRows: 'Filtradas',
  selectedRows: 'Seleccionadas',
  totalRows: 'Total Filas',
  totalAndFilteredRows: 'Filas',
  more: 'Más',
  to: 'hasta',
  of: 'de',
  page: 'Página',
  nextPage: 'Página Siguiente',
  lastPage: 'Última Página',
  firstPage: 'Primera Página',
  previousPage: 'Página Anterior',

  // Pivoting
  pivotColumnGroupTotals: 'Total',

  // Enterprise Menu (Charts)
  pivotChartAndPivotMode: 'Gráfico Pivotante Y Modo Pivotante',
  pivotChart: 'Gráfico pivotante',
  chartRange: 'Rango del Gráfico',

  columnChart: 'Columna',
  groupedColumn: 'Agrupada',
  stackedColumn: 'Apilado',
  normalizedColumn: '100% Apilado',

  barChart: 'Barra',
  groupedBar: 'Agrupada',
  stackedBar: 'Apilada',
  normalizedBar: '100% apilada',

  pieChart: 'Tarta',
  pie: 'Tarta',
  doughnut: 'Rosquilla',

  line: 'Línea',

  xyChart: 'X Y (Dispersión)',
  scatter: 'Dispersión',
  bubble: 'Burbuja',

  areaChart: 'Área',
  area: 'Area',
  stackedArea: 'Apilado',
  normalizedArea: '100% Apilado',

  histogramChart: 'Histograma',
  histogramFrequency: 'Frecuencia',

  combinationChart: 'Combinación',
  columnLineCombo: 'Columna y línea',
  AreaColumnCombo: 'Area & Column',

  // Charts
  pivotChartTitle: 'Gráfico dinámico',
  rangeChartTitle: 'Gráfico de rangos',
  settings: 'Configuración',
  data: 'Datos',
  format: 'Formato',
  categories: 'Categorías',
  defaultCategory: '(Ninguna)',
  series: 'Series',
  xyValues: 'Valores X Y',
  paired: 'Modo emparejado',
  axis: 'Eje',
  navigator: 'Navegador',
  color: 'Color',
  thickness: 'Grosor',
  xType: 'Tipo X',
  automatic: 'Automático',
  category: 'Categoría',
  number: 'Número',
  time: 'Hora',
  autoRotate: 'Rotación automática',
  xRotation: 'Rotación X',
  yRotation: 'Rotación Y',
  ticks: 'Ticks',
  width: 'Anchura',
  height: 'Altura',
  length: 'Longitud',
  padding: 'Relleno',
  spacing: 'Espaciado',
  chart: 'Gráfico',
  title: 'Título',
  titlePlaceholder: 'Título del gráfico - doble clic para editar',
  background: 'Fondo',
  font: 'Fuente',
  top: 'Arriba',
  right: 'Derecha',
  bottom: 'Bottom',
  left: 'Izquierda',
  labels: 'Etiquetas',
  size: 'Tamaño',
  minSize: 'Tamaño mínimo',
  maxSize: 'Tamaño máximo',
  legend: 'Leyenda',
  position: 'Posición',
  markerSize: 'Tamaño del marcador',
  markerStroke: 'Trazo del marcador',
  markerPadding: 'Padding del marcador',
  itemSpacing: 'Espaciado del elemento',
  itemPaddingX: 'Relleno X del elemento',
  itemPaddingY: 'Padding Padding Y',
  layoutHorizontalSpacing: 'Espaciado horizontal',
  layoutVerticalSpacing: 'Espaciado vertical',
  strokeWidth: 'Ancho del trazo',
  lineDash: 'Guión de línea',
  offset: 'Desplazamiento',
  offsets: 'Desplazamientos',
  tooltips: 'Información sobre herramientas',
  callout: 'Descripción',
  markers: 'Marcadores',
  shadow: 'Sombra',
  blur: 'Desenfoque',
  xOffset: 'Desplazamiento X',
  yOffset: 'Desplazamiento Y',
  lineWidth: 'Ancho de línea',
  normal: 'Normal',
  bold: 'Negrita',
  italic: 'Cursiva',
  boldItalic: 'Negrita cursiva',
  predefined: 'Predefinido',
  fillOpacity: 'Opacidad de relleno',
  strokeOpacity: 'Opacidad de trazo',
  histogramBinCount: 'Recuento de contenedores',
  columnGroup: 'Columna',
  barGroup: 'Barra',
  pieGroup: 'Pie',
  lineGroup: 'Línea',
  scatterGroup: 'X Y (Dispersión)',
  areaGroup: 'Area',
  histogramGroup: 'Histograma',
  combinationGroup: 'Combinación',
  groupedColumnTooltip: 'Agrupado',
  stackedColumnTooltip: 'Apilado',
  normalizedColumnTooltip: '100% apilado',
  groupedBarTooltip: 'Agrupadas',
  stackedBarTooltip: 'Apilado',
  normalizedBarTooltip: '100% Apilado',
  pieTooltip: 'Tarta',
  doughnutTooltip: 'Rosquilla',
  lineTooltip: 'Línea',
  groupedAreaTooltip: 'Área',
  stackedAreaTooltip: 'Apilado',
  normalizedAreaTooltip: '100% apilado',
  scatterTooltip: 'Dispersión',
  bubbleTooltip: 'Burbuja',
  histogramTooltip: 'Histograma',
  columnLineComboTooltip: 'Columna y línea',
  areaColumnComboTooltip: 'Área & Columna',
  customComboTooltip: 'Combinación personalizada',
  noDataToChart: 'No hay datos disponibles para el gráfico',
  pivotChartRequiresPivotMode:
    'El gráfico pivotante requiere tener activado el modo pivotante',
  chartSettingsToolbarTooltip: 'Menú',
  chartLinkToolbarTooltip: 'Vinculado a Rejilla',
  chartUnlinkToolbarTooltip: 'Desvinculado de la Cuadrícula',
  chartDownloadToolbarTooltip: 'Descargar gráfico',
  seriesChartType: 'Tipo de gráfico de series',
  seriesType: 'Tipo de serie',
  secondaryAxis: 'Eje secundario',

  // ARIA
  ariaChecked: 'Comprobado',
  ariaColumn: 'Columna',
  ariaColumnGroup: 'Grupo de columnas',
  ariaColumnList: 'Lista de columnas',
  ariaColumnSelectAll: 'Alternar Seleccionar todas las columnas',
  ariaDateFilterInput: 'Entrada de filtro de fecha',
  ariaDefaultListName: 'Lista',
  ariaFilterColumnsInput: 'Entrada de columnas de filtro',
  ariaFilterFromValue: 'Filtro desde valor',
  ariaFilterInput: 'Entrada del filtro',
  ariaFilterList: 'Lista de filtros',
  ariaFilterToValue: 'Filtro a valor',
  ariaFilterValue: 'Valor del filtro',
  ariaFilteringOperator: 'Operador de filtrado',
  ariaHidden: 'Oculto',
  ariaIndeterminate: 'indeterminado',
  ariaInputEditor: 'Editor de entradas',
  ariaMenuColumn: 'Pulse CTRL ENTER para abrir el menú de columna',
  ariaRowDeselect: 'Pulse ESPACIO para deseleccionar esta fila',
  ariaRowSelectAll:
    'Pulse Espacio para conmutar la selección de todas las filas',
  ariaRowToggleSelection: 'Pulsa Espacio para alternar la selección de filas',
  ariaRowSelect: 'Pulsa ESPACIO para seleccionar esta fila',
  ariaSearch: 'Buscar',
  ariaSortableColumn: 'Pulse ENTER para ordenar',
  ariaToggleVisibility: 'Pulsa ESPACIO para cambiar la visibilidad',
  ariaUnchecked: 'Desmarcado',
  ariaVisible: 'Visible',
  ariaSearchFilterValues: 'Valores del filtro de búsqueda',

  // ARIA Labels for Drop Zones

  ariaRowGroupDropZonePanelLabel: 'Grupos de filas',
  ariaValuesDropZonePanelLabel: 'Valores',
  ariaPivotDropZonePanelLabel: 'Etiquetas de columna',
  ariaDropZoneColumnComponentDescription: 'Pulse SUPR para eliminar',
  ariaDropZoneColumnValueItemDescription:
    'Press ENTER to change the aggregation type',
  ariaDropZoneColumnGroupItemDescription: 'Pulse ENTER para ordenar',
  // used for aggregate drop zone, format: {agregación}{ariaDropZoneColumnComponentAggFuncSeperator}{nombre de columna}
  ariaDropZoneColumnComponentAggFuncSeperator: 'de ',
  ariaDropZoneColumnComponentSortAscending: 'ascendente',
  ariaDropZoneColumnComponentSortDescending: 'descendente',

  // ARIA Labels for Dialogs
  ariaLabelColumnMenu: 'Column Menu',
  ariaLabelCellEditor: 'Editor de celdas',
  ariaLabelDialog: 'Diálogo',
  ariaLabelSelectField: 'Campo de selección',
  ariaLabelTooltip: 'Información sobre herramienta',
  ariaLabelContextMenu: 'Menú contextual',
  ariaLabelSubMenu: 'SubMenú',
  ariaLabelAggregationFunction: 'Función de agregación',

  // Number Format (Status Bar, Pagination Panel)
  thousandSeparator: ',',
  decimalSeparator: '.'
}
