import { createStore } from 'vuex'
import Facturacion from '../services/facturacion'
import Contratos from '../services/contratos'
const _Facturacion = new Facturacion()
const _Contratos = new Contratos()
const storeCardFacturacion = {
  state: {
    _dataFacturacion: {
      totalFacturadoAnio: 0,
      totalFacturadoMes: 0,
      totalRadicadoAnio: 0,
      totalRadicadoMes: 0,
      totalCostoMx: 0
    },
    _detalleFacturacion: [],
    _VlrContratado: 0,
    _cardCode: null,
    _contratos: [],
    _contrato: null
  },
  getters: {
    dataFacturacion: state => state._dataFacturacion,
    detalleFacturacion: state => state._detalleFacturacion,
    vlrContratado: state => state._VlrContratado,
    cardCode: state => state._cardCode,
    contratos: state => state._contratos,
    contrato: state => state._contrato
  },
  mutations: {
    setDataFacturacion (state, payload) {
      state._dataFacturacion = payload
    },
    setDetalleFacturacion (state, payload) {
      state._detalleFacturacion = payload
    },
    setvlrContratado (state, payload) {
      state._VlrContratado = payload.vlrContratado
      state._cardCode = payload.selectedCardCode
      state._contrato = payload.selectedContrato
    },
    setContratos (state, payload) {
      state._contratos = payload
    }
  },
  actions: {
    getDataFacturacion ({ commit }, payload) {
        return new Promise((resolve) => {
          _Facturacion.getDataFacturacion(payload).then(({ data }) => {
            commit('setDataFacturacion', data)
            resolve()
          })
        })
    },
    getDetalleFacturacion ({ commit }, payload) {
      return new Promise((resolve) => {
        _Facturacion.getDetalleFacturacion(payload).then(({ data }) => {
          commit('setDetalleFacturacion', data)
          resolve()
        })
      })
    },
    calcularVlr ({ commit }, payload) {
      commit('setvlrContratado', payload)
    },
    getContratos ({ commit }, payload) {
      return new Promise((resolve) => {
        _Contratos.getContratos(payload).then(({ data }) => {
          commit('setContratos', data)
          resolve()
        })
      })
    }
  }
}
const store = createStore(storeCardFacturacion)
export default {
  namespaced: true,
  ...store
}
