<template>
  <section>
    <div class="col-span-12 lg:col-span-6 mt-8">
      <div v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion')" class="intro-y block sm:flex items-center h-10" style="min-height:95px;">
        <h2 class="text-lg font-medium truncate mr-5 capitalize">Indicadores Operación</h2>
        <FiltroMesAno v-model="filtro" />
      </div>
    </div>
    <div v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion')" class="grid grid-cols-12 gap-6 mt-5">
      <!--   1   -->
      <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y" >
        <CardTotales
          :year="filtro.year"
          :month="filtro.month"
          v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-facturacion')"
        />
      </div>
      <!--   2   -->
      <CardFacturacion
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-facturacion')"
      />
      <!--   3   -->
      <CardSinFacturar
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-ovs-sin-facturar')"
      />
      <!--   4   -->
      <CardOvAbiertas
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-ovs-abiertas')"
      />
      <!--   4   -->
      <CardOvAbiertasV2
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-ovs-abiertas')"
      />
      <!--   5   -->
      <CardComprasProveedores
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-compras-proveedores')"
      />
      <!--   6   -->
      <CardConsumoClientes
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-consumo-clientes')"
      />
      <!-- 7 -->
      <CardConsumoSedes
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-consumo-sedes')"
      />
      <!-- 8 -->
      <CardInventario
        :year="filtro.year"
        :month="filtro.month"
        v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-inventario')"
      />
      <!-- 9 -->
      <div v-if="$can(can.dailytech.canCarteraClientes)" class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardCarteraClientes />
      </div>

      <!-- 10 -->
      <div v-if="$can(can.dailytech.canCarteraClientes)" class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
        <CardCarteraClientesServicio />
      </div>
      <!-- 11 -->
      <template v-if="$can(can.dailytech.canCarteraProveedores)">
        <div
          v-if="CarteraProv === 0"
          class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y relative">
          <Button
            v-if="estadoBtn === 2"
            icon="pi"
            severity="secondary"
            class="p-button sm:mr-2 btn-change p-mt-auto my-auto sm:flex h-7 z-10"
            outlined
            @click="setCarteraProv(1)"
            v-tippy="{ content: 'Administrativo' }"
          >
            <ArchiveIcon style="width:14px;"/>
          </Button>
          <CardCarteraProveedores @emitSetEstado="emitEstado"/>
        </div>
        <div
          v-if="CarteraProv === 1"
          class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y relative">
          <Button
            v-if="estadoBtn === 2"
            icon="pi"
            severity="secondary"
            class="p-button sm:mr-2 btn-change p-mt-auto my-auto sm:flex h-7 z-10"
            outlined
            @click="setCarteraProv(0)"
            v-tippy="{ content: 'Cartera' }"
          >
            <HomeIcon style="width:14px;"/>
          </Button>
          <CardCarteraProveedoresAdmin @emitSetEstado="emitEstado"/>
        </div>
      </template>
      <!-- 12 -->
      <div v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-facturas-por-radicar')" class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
        <CardFacturasPorRadicar :year="filtro.year" />
      </div>
      <!-- 13 -->
      <div v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.ovs-cerradas-tipificar')" class="col-span-12 sm:col-span-6 xl:col-span-4 intro-y">
        <CardFacturasPorTipificar :year="filtro.year" />
      </div>

      <!--      -->
      <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y" v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-pagos-proveedores')">
        <PagosProveedores :year="filtro.year" :month="filtro.month" />
      </div>
      <template v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.recaudos')">
        <div class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
          <CardRecaudos :year="filtro.year" :month="filtro.month" />
        </div>
      </template>
      <!--   capita pharmedis   -->
      <CardCapitacion :year="filtro.year" :month="filtro.month" v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-facturacion')" />
      <!--   9   -->
      <CardEntradasMercancia :year="filtro.year" :month="filtro.month" v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-facturacion')" />

      <!-- Card Compras Catalogo -->
      <div v-if="$can('pharmasan.dashboard.operacion.entradas-mercancia-analisis-precio')" class="col-span-12 sm:col-span-12">
        <CardComprasCatalogo />
      </div>

      <!-- Card Iframe excel salud mia -->
      <div v-if="$can('pharmasan.dashboard.operacion.entradas-mercancia-analisis-precio')" class="col-span-12 sm:col-span-12">
        <CardInformeSaludMia />
      </div>

      <div v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.card-clientes-filtros')" class="col-span-12 sm:col-span-12">
        <CardClientesFiltros />
      </div>
    </div>
    <template v-if="$can('pharmasan.dashboard.operacion.indicadores-financieros')">
      <div class="col-span-12 lg:col-span-6 mt-8 mb-8">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:95px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Indicadores financieros</h2>
          <FiltroMesAno v-model="filtroFinanciero" rango />
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 ">
        <div v-if="$can('pharmasan.dashboard.operacion.indicadores-financieros.balance')" class="col-span-12 sm:col-span-6 ">
          <TablaBalance :year="filtroFinanciero.year" />
        </div>
        <div v-if="$can('pharmasan.dashboard.operacion.indicadores-financieros.pyg')" class="col-span-12 sm:col-span-6 ">
          <TablaPerdidasGanacias :year="filtroFinanciero.year" :month="filtroFinanciero.month"
            :month2="filtroFinanciero.month2" />
        </div>
        <div v-if="$can('pharmasan.dashboard.operacion.indicadores-financieros.indicadores')" class="col-span-12 sm:col-span-6">
          <IndicadoresFinancieros />
        </div>
        <div v-if="$can('pharmasan.dashboard.operacion.indicadores-financieros.indicadores-bodega')" class="col-span-12 sm:col-span-6">
          <IndicadoresFinancierosBodega />
        </div>
        <div v-if="$can('pharmasan.dashboard.operacion.indicadores-financieros.costos')" class="col-span-12 sm:col-span-12">
          <CardCostos />
        </div>
      </div>
    </template>
    <div v-if="$can('pharmasan.dashboard.operacion.top-ventas')" class="grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-5">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Top ventas</h2>
        </div>
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.top-ventas.card-top-ventas-cliente')" class="col-span-12 sm:col-span-6 lg:col-span-6">
        <GraficaTopVentasClientes :filters="filtro" />
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.top-ventas.card-top-ventas-mx-cliente')" class="col-span-12 sm:col-span-6 lg:col-span-6">
        <GraficaTopVentasClientesMx :filters="filtro" />
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.top-ventas.card-top-ventas-proveedores')" class="col-span-12 sm:col-span-6 lg:col-span-6">
        <GraficaTopVentasProveedores :filters="filtro" />
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.top-ventas.card-top-ventas-mx-proveedores')" class="col-span-12 sm:col-span-6 lg:col-span-6">
        <GraficaTopVentasProveedoresMx :filters="filtro" />
      </div>
    </div>
    <div v-if="$can('pharmasan.dashboard.operacion.servicio-cliente')" class="grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-5">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:45px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Servicio al Cliente</h2>
        </div>
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.servicio-cliente.card-grafica-pqr-clasificacion')" class="col-span-12 sm:col-span-6 lg:col-span-12">
        <GraficaPqrClasificacion :filters="filtro" />
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.servicio-cliente.card-grafica-pqr-cliente')" class="col-span-12 sm:col-span-6 lg:col-span-6 ">
        <GraficaPqrCliente :filters="filtro" />
      </div>
      <div v-if="$can('pharmasan.dashboard.operacion.servicio-cliente.card-grafica-pqr-medio')" class="col-span-12 sm:col-span-6 lg:col-span-6 ">
        <GraficaPqrMedio :filters="filtro" />
      </div>
    </div>
    <div v-if="$can('pharmasan.dashboard.operacion.digiturno.card-digiturno')" class="grid grid-cols-12 gap-6">
      <div class="col-span-12 mt-8">
        <div class="intro-y block sm:flex items-center h-10" style="min-height:95px;">
          <h2 class="text-lg font-medium truncate mr-5 capitalize">Digiturno</h2>
          <FiltroDigiturno @updateFilters="updateFilters" />
        </div>
      </div>
    </div>
    <ContenedorDigiturno ref="contenedorDigiturnoRef" v-if="$can('pharmasan.dashboard.operacion.digiturno.card-contenedor-digiturno')" />
    <div v-if="$can('pharmasan.dashboard.operacion.indicadores-operacion.indicadores-hus')" class="grid w-full">
      <CardsHus/>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { helper } from '../../../../../utils/helper'
import FiltroMesAno from '../../components/filtroMesAno'
// import FiltroClasificacionPqr from './components/FiltroClasificacionPqr'
import CardTotales from '../../components/cardTotales/Main'
import CardFacturacion from '../../components/cardFacturacion/Main'
import CardSinFacturar from '../../components/cardSinFacturar/Main'
import CardOvAbiertas from '../../components/cardOvAbiertas/Main'
import CardOvAbiertasV2 from '../../components/cardOvAbiertasV2/Main'
import CardComprasProveedores from '../../components/cardComprasProveedores/Main'
import CardConsumoClientes from '../../components/cardConsumoClienteV2/Main.vue'
import CardConsumoSedes from '../../components/cardConsumoSede/Main'
import CardInventario from '../../components/cardInventario/Main'
import CardCarteraClientes from '../../components/cardCarteraClientes/Main'
import CardCarteraClientesServicio from '../../components/cardCarteraClientesPorServicio/Main.vue'
import CardCarteraProveedores from '../../components/cardCarteraProveedores/Main'
import CardCarteraProveedoresAdmin from '../../components/cardCarteraProveedoresAdmin/Main'
import CardFacturasPorRadicar from '../../components/cardFacturasPorRadicar/Main'
import CardFacturasPorTipificar from '../../components/cardFacturasPorTipificar/Main.vue'
// import CardConsumoClientes from '../../components/cardConsumoCliente/Main'
import FiltroDigiturno from './digiturno/filtrosDigiturno'
import CardCapitacion from '../../components/cardCapitacion/Main'
import CardEntradasMercancia from '../../components/cardEntradasMercancia/Main'
import CardsHus from './components/Hus/index.vue'
import TablaBalance from '../../components/cardBalance/Main'
import TablaPerdidasGanacias from '../../components/cuentaPerdidasGanancias/Main'
import PagosProveedores from '../../components/PagosProveedores/Main'
import CardRecaudos from '../../components/cardRecaudos/Main'
import GraficaTopVentasClientes from './components/GraficasTopVentas/GraficaClientes'
import GraficaTopVentasClientesMx from './components/GraficasTopVentas/GraficaClientesMx'
import GraficaTopVentasProveedores from './components/GraficasTopVentas/GraficaProveedores'
import GraficaTopVentasProveedoresMx from './components/GraficasTopVentas/GraficaProveedoresMx'
import GraficaPqrClasificacion from './components/GraficaPqrClasificacion'
import GraficaPqrCliente from './components/GraficaPqrCliente'
import GraficaPqrMedio from './components/GraficaPqrMedio'
import IndicadoresFinancieros from '../../components/cardIndicadoresFinancieros'
import ContenedorDigiturno from './digiturno'
import IndicadoresFinancierosBodega from '../../components/cardIndicadoresFinancierosBodega'
import CardCostos from '../../components/cardCostos'
import CardClientesFiltros from '../../components/cardClientesFiltros'
import CardComprasCatalogo from '../../components/cardComprasCatalogo/index.vue'
import CardInformeSaludMia from '../../components/cardInformeSaludMia/index.vue'
import can from './can'
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    FiltroMesAno,
    // FiltroClasificacionPqr,
    CardTotales,
    CardFacturacion,
    CardSinFacturar,
    CardOvAbiertas,
    CardOvAbiertasV2,
    CardComprasProveedores,
    CardConsumoClientes,
    CardConsumoSedes,
    CardInventario,
    CardCarteraClientes,
    CardCarteraClientesServicio,
    CardCarteraProveedores,
    CardCarteraProveedoresAdmin,
    CardFacturasPorRadicar,
    CardFacturasPorTipificar,
    FiltroDigiturno,
    TablaBalance,
    TablaPerdidasGanacias,
    PagosProveedores,
    CardRecaudos,
    GraficaTopVentasClientes,
    GraficaTopVentasClientesMx,
    GraficaTopVentasProveedores,
    GraficaTopVentasProveedoresMx,
    GraficaPqrClasificacion,
    GraficaPqrCliente,
    GraficaPqrMedio,
    IndicadoresFinancieros,
    ContenedorDigiturno,
    IndicadoresFinancierosBodega,
    CardCostos,
    CardClientesFiltros,
    CardCapitacion,
    CardEntradasMercancia,
    CardsHus,
    CardComprasCatalogo,
    CardInformeSaludMia
  },
  setup (props, { emits }) {
    const estadoBtn = ref(2)
    const emitEstado = (est) => {
      estadoBtn.value = est
    }
    const getYearNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        if (dayjs().month() === 0) {
          return new Date().getFullYear() - 1
        }
      }
      return new Date().getFullYear()
    }
    const getMonthNow = () => {
      const nowDay = new Date().getDate()
      if (nowDay >= 1 && nowDay <= 3) {
        return dayjs().subtract(1, 'month').month() + 1
      }
      return new Date().getMonth() + 1
    }

    const filtro = ref({
      year: getYearNow(),
      month: getMonthNow()
    })

    const filtroFinanciero = ref({
      year: getYearNow(),
      month: getMonthNow(),
      month2: getMonthNow()
    })
    const contenedorDigiturnoRef = ref()

    const updateFilters = (filtros) => {
      contenedorDigiturnoRef.value.fetchResults(filtros)
    }

    const CarteraProv = ref(0)
    const setCarteraProv = (status) => {
      CarteraProv.value = status
    }

    return {
      estadoBtn,
      emitEstado,
      CarteraProv,
      setCarteraProv,
      updateFilters,
      contenedorDigiturnoRef,
      filtroFinanciero,
      filtro,
      helper,
      can
    }
  }
})

</script>
<style lang="scss" scoped>

.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.btn-atras{
  padding-top: 4px;
  padding-bottom: 4px;
}
.color-azul{
  color: #1c3faa;
}
.btn-change{
  position: absolute;
  right: 5px;
  top: 5px;
}
</style>
