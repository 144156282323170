
<template>
  <section class="col-span-12 sm:col-span-6  xl:col-span-4 intro-y">
    <div v-if="estaCargando" class="rounded-md icono-carga p-8">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class=" box px-5 pt-5 zoom-in scroll-ajuste" style="padding-bottom: 4.5rem;">
      <template v-if="estado === 1" >
        <div class="flex items-center justify-between " @click="changeEstado(2)">
          <div class="grid grid-cols-2 w-full">
            <div class="flex flex-col">
              <div class="font-medium">Monto Maximo Pharmedis</div>
              <div class="text-gray-600 mt-2">{{$h.formatCurrency(parseInt(Monto))}}</div>
            </div>
            <div class="flex flex-col">
              <div class="font-medium">Total Ov Pharmedis</div>
              <div class="text-gray-600 mt-2">{{$h.formatCurrency(parseInt(Capita))}}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="estado === 2">
        <div class="flex justify-between items-center">
          <div class="font-medium truncate capitalize">Costo Pharmedis</div>
          <button type="button" class="btn btn-atras" @click="changeEstado(1)">
            <svg style="width:12px;height:12px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z" />
            </svg>
          </button>
        </div>
        <table style="width: 100%" class="table_cards">
          <thead>
          <tr>
            <th class="text-left">Mes</th>
            <th class="text-right">Monto</th>
            <th class="text-right">Ov</th>
          </tr>
          </thead>
          <tbody>
          <tr class="hover:bg-gray-300" v-for="(row,k)  in Items" :key="k">
            <td class="text-left">
              <div style="display: flex; align-items: center;" >
                <div class="dark:text-white text-primary-1 uppercase letra" style="position: relative; top: 1px;">
                  {{row.U_PHR_Mes}}
                </div>
              </div>
            </td>
            <td class="text-right">{{$h.formatNumber(parseInt(row.Monto))}}</td>
            <td class="text-right">{{$h.formatCurrency(parseInt(row.Ov))}}</td>
          </tr>
          </tbody>
        </table>
      </template>
    </div>
  </section>
</template>

<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { getDataNivel } from './service'
export default defineComponent({
  props: {
    year: {
      type: [String, Number],
      default: new Date().getFullYear()
    },
    month: {
      type: [String, Number],
      default: new Date().getMonth() + 1
    }
  },
  setup (props, context) {
    watch(() => props.year, (val) => {
      estado.value = 1
      getData1({ year: val, month: props.month })
    })
    watch(() => props.month, (val) => {
      estado.value = 1
      getData1({ year: props.year, month: val })
    })
    const estaCargando = ref(true)
    const estado = ref(1)
    const Monto = ref(0)
    const Capita = ref(0)
    const Items = ref([])
    const getData1 = (query) => {
      estaCargando.value = true
      getDataNivel(query, estado.value).then(({ data }) => {
        if (data.length) {
          Monto.value = data[0].MontoTotal
          Capita.value = data[0].CapitaPharmedis
        }
        estaCargando.value = false
      })
    }
    const getData2 = (val, query) => {
      estaCargando.value = true
      getDataNivel(query, val).then(({ data }) => {
        estaCargando.value = false
        Items.value = data
        estaCargando.value = false
      })
    }
    const changeEstado = (val) => {
      estado.value = val
      if (estado.value === 1) {
        getData1({ year: props.year, month: props.month })
      } else if (estado.value === 2) {
        getData2(2, { year: props.year, month: props.month })
      }
    }
    onMounted(() => {
      getData1({ year: props.year, month: props.month })
    })
    return {
      estado,
      Monto,
      Capita,
      estaCargando,
      Items,
      changeEstado
    }
  }
})
</script>

<style scoped>
.table_cards{
  text-align: start;
  font-size: 0.8em;
  margin-top: 10px;
}
.scroll-ajuste{
  z-index: 9999;
  position: relative;
  max-height: 280px;
  overflow-y: auto;
}
.icono-carga{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(255,255,255,0.75);
  height: 100%;
  z-index: 999999;
}
.letra{
  font-size: 12px !important;
}
</style>
