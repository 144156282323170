<template>
  <div class="w-full relative">
    <div v-if="estaCargando" class="box w-full h-full absolute" style="z-index: 10000;">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="grid grid-cols-1 gap-4 my-2 mt-4">
      <GraficaTurnosTotal ref="myGraficaTurnos" />
      <!-- <GraficaTotalMd ref="myGraficaTotalMd" /> -->
    </div>
    <div class="col-span-12 sm:col-span-6 lg:col-span-12">
      <TablaDigiturnos :data="tablaDigiturnos" />
    </div>
  </div>
</template>

<script>

import GraficaTurnosTotal from './graficaTotalTurnos/Main'
// import GraficaTotalMd from './graficaTotalMd/Main'
import TablaDigiturnos from './tablasDigiturno/Main'
import { ref } from 'vue'
import { multiplesHttp } from './tablasDigiturno/Service'
// import { messageWarning } from '../../../../../libs/mensajes'

export default {
  name: 'ContenedorDigiturno',
  components: {
    GraficaTurnosTotal,
    // GraficaTotalMd,
    TablaDigiturnos
  },
  setup (props) {
    const estaCargando = ref(false)
    const tablaDigiturnos = ref([])
    const myGraficaTurnos = ref(null)
    // const myGraficaTotalMd = ref(null)

    /* watch(props.filtros, (filter) => {
      console.log(filter)
      // fetchResults(filter)
    }) */

    const fetchResults = (filters) => {
      estaCargando.value = true
      multiplesHttp(filters).then((result) => {
        if (!result) {
          myGraficaTurnos.value.reloadChart([])
          // myGraficaTotalMd.value.reloadChart([])
          tablaDigiturnos.value = []
          // messageWarning('No se pudo obtener la información, intente de nuevo con un rango de fechas mas corto')
        } else {
          /** GRAFICA TOTAL TURNOS **/
          const graficaTurnosTotal = result[0].data.map(a => {
            return { value: a.total, name: a.nombre_sede }
          })
          const graficaTotalOv = result[1].data.map(a => {
            return { value: a.total, name: a.nombre_sede }
          })
          const graficaTotalServicio = result[2].data.map(a => {
            return {
              pos: a.pos,
              mipres: a.mipres,
              tutela: a.tutela,
              name: a.nombre_sede,
              location: a.location_id,
              infoTipoServicio: result[5].data ? result[5].data.find(ov => ov.location_id === a.location_id) : 0
             }
          })
          myGraficaTurnos.value.reloadChart(graficaTurnosTotal, graficaTotalOv, graficaTotalServicio)
          /** GRAFICA TOTAL MD **/
          /* const graficaTotalMd = result[0].data.map(a => {
            return { value: a.total, name: a.nombre_sede }
          })
          myGraficaTotalMd.value.reloadChart(graficaTotalMd) */
          /** GRAFICA TOTAL OV **/
          /** TABLAS **/
          tablaDigiturnos.value = result[0].data.map((e, idx) => {
            const { total } = result[1].data ? result[1].data.find(turno => turno.location_id === e.location_id) : 0
            const infoTiempoSala = result[3].data ? result[3].data.find(ov => ov.location_id === e.location_id) : 0
            const infoTiempoAtencion = result[4].data ? result[4].data.find(ov => ov.location_id === e.location_id) : 0
            const infoTiempoSalaServicio = result[5].data ? result[5].data.find(ov => ov.location_id === e.location_id) : 0
            return {
              location_id: e.location_id,
              name: e.nombre_sede,
              totalTurnos: e.total,
              totalOv: total,
              servicios: infoTiempoSalaServicio.servicios,
              maximoTiempoSala: infoTiempoSala ? infoTiempoSala.max_tiempo_sala : '---',
              minimoTiempoSala: infoTiempoSala ? infoTiempoSala.min_tiempo_sala : '---',
              promedioTiempoSala: infoTiempoSala ? infoTiempoSala.promedio_tiempo_sala : '---',
              maximoTiempoAtencion: infoTiempoAtencion ? infoTiempoAtencion.max_tiempo_atencion : '---',
              minimoTiempoAtencion: infoTiempoAtencion ? infoTiempoAtencion.min_tiempo_atencion : '---',
              promedioTiempoAtencion: infoTiempoAtencion ? infoTiempoAtencion.promedio_tiempo_atencion : '---'
            }
          })
        }
        /** cargando **/
        estaCargando.value = false
      }).catch((err) => {
        estaCargando.value = false
        console.error(err.message)
      })
    }

    return {
      fetchResults,
      tablaDigiturnos,
      props,
      // myGraficaTotalMd,
      myGraficaTurnos,
      estaCargando
    }
  }
}
</script>

<style scoped>

</style>
