import { getFacturacion, getFacturacionCapita } from './service'
import { computed, ref } from 'vue'
import { helper } from '../../../../../utils/helper'

const info = ref({
    cantidad: 0,
    total: 0,
    items: [],
    subtotales: {}
})
const infoCapita = ref({
    cantidad: 0,
    total: 0,
    items: [],
    subtotales: {}
})
const estaCargando = ref(true)
const searchable = ref(false)
const contratoSel = ref('')
const sedeSel = ref('')
const estado = ref(1)
const typeModalidad = ref('evento')
const searchNivel2 = ref('')
const searchNivel3 = ref('')

const chartPorcentaje = computed(() => {
  if (info.value.total) return (info.value.total * 100) / 12500000000
  return 0
})

const chartData = computed(() => {
  const tmp = []
  let porcentaje = 0
  if (info.value.total) porcentaje = (info.value.total * 100) / 12500000000
  if (porcentaje) {
    tmp.push(porcentaje)
    tmp.push(100 - porcentaje)
  }
  return tmp
})

const setContratoSel = (value) => {
  if (typeModalidad.value === 'evento') {
    estado.value = 3
    contratoSel.value = value
  }
}

const setSedeSel = (value) => {
  estado.value = 4
  sedeSel.value = value
}

const chartLabels = computed(() => {
  return [
    helper.formatCurrency(info.value.total),
    helper.formatCurrency((12500000000 - info.value.total))
  ]
})

const fetchFacturacion = (queryParams) => {
  estaCargando.value = true
  return getFacturacion(queryParams).then(({ status, data }) => {
    // console.log('getFacturacion----------', data)
    estaCargando.value = false
    if (status === 200) {
      info.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err.message)
  })
}

const fetchFacturacionCapita = (queryParams) => {
  estaCargando.value = true
  return getFacturacionCapita(queryParams).then(({ status, data }) => {
    console.log('getFacturacionCapita----------', data)
    estaCargando.value = false
    if (status === 200) {
      infoCapita.value = data
    }
  }).catch(err => {
    estaCargando.value = false
    console.error(err.message)
  })
}

const setEstado = (value, type = 'evento') => {
  estado.value = value
  typeModalidad.value = type
}

const getCantidad = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.cantidad, 0)
}

const getTotal = (column) => {
  return info.value.items[column].reduce((total, item) => total + item.total, 0)
}

const getItems = computed(() => {
  const tmp = []
  let infoType
  if (typeModalidad.value === 'evento') {
    infoType = info.value.items
  } else {
    infoType = infoCapita.value.items
  }
  for (const value of infoType) {
    const contrato = value.label
    const total = value.total
    const totalpbs = value.totalpbs
    const totalnopbs = value.totalnopbs
    const cantidad = value.cantidad
    const cantidadpbs = value.cantidadpbs
    const cantidadnopbs = value.cantidadnopbs
    const costo = value.costo
    const costopbs = value.costopbs
    const costonopbs = value.costonopbs
    const rentabilidad = value.rentabilidad
    const rentabilidadpbs = value.rentabilidadpbs
    const rentabilidadnopbs = value.rentabilidadnopbs
    const visible = false
    tmp.push({ contrato, total, totalpbs, totalnopbs, cantidad, cantidadpbs, cantidadnopbs, costo, costopbs, costonopbs, rentabilidad, rentabilidadpbs, rentabilidadnopbs, visible })
  }
  // const filtered = tmp.filter(a =>
  //   a.contrato.toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
  //   a.cantidad.toString().toLowerCase().includes(searchNivel2.value.toLowerCase()) ||
  //   a.total.toString().toLowerCase().includes(searchNivel2.value.toLowerCase())
  //   )
  return tmp
})

const getNameTypeModalidad = computed(() => {
  if (typeModalidad.value === 'evento') {
    return 'Evento'
  }
  return 'Capita'
})
const getCostoTotal = computed(() => {
  // const capita = infoCapita.value.items.reduce((total, current) => (total = total + current.costo), 0)
  // console.log('info.value-----', info.value)
  const evento = info.value ? info.value.items.reduce((total, current) => (total = total + current.costo), 0) : 0
  const total = evento
  return total
})

const getResumen = computed(() => {
  let infoType
  if (typeModalidad.value === 'evento') {
    infoType = info.value.items
  } else {
    infoType = infoCapita.value.items
  }
  const rows = infoType.find(f => f.label === contratoSel.value)
  if (rows === undefined) return []
  if (typeModalidad.value === 'evento') {
    const filtered = rows.children.filter(a =>
      a.label.toLowerCase().includes(searchNivel3.value.toLowerCase()) ||
      a.cantidad.toString().toLowerCase().includes(searchNivel3.value.toLowerCase()) ||
      a.total.toString().toLowerCase().includes(searchNivel3.value.toLowerCase())
      )
      // console.log('rows filteredpbs-----', filtered)
      return filtered
  }
  return []
})

const getRentabilidadGeneral = computed(() => {
  // const evento = info.value.Evento.items.reduce((total, current) => (total = total + current.rentabilidad), 0)
  // const eventoCantidad = info.value.Evento.items.reduce((total, current) => (total = total + 1), 0)
  // const total = evento / eventoCantidad
  // return (total).toFixed(1)
  const total = (((info.value.total - getCostoTotal.value) / getCostoTotal.value) * 100).toFixed(1)
  return total
})

const getCapitaCostoTotal = computed(() => {
  const total = infoCapita.value.items.reduce((total, current) => (total = total + (+current.costo)), 0)
  return total
})
const getCapitaRent = computed(() => {
  const total = (((infoCapita.value.total - getCapitaCostoTotal.value) / getCapitaCostoTotal.value) * 100).toFixed(1)
  return total
})
export {
  estaCargando,
  searchable,
  estado,
  info,
  infoCapita,
  chartPorcentaje,
  chartLabels,
  chartData,
  searchNivel2,
  searchNivel3,
  getItems,
  getResumen,
  fetchFacturacion,
  fetchFacturacionCapita,
  setEstado,
  getCantidad,
  getTotal,
  setContratoSel,
  setSedeSel,
  getNameTypeModalidad,
  typeModalidad,
  getCostoTotal,
  getRentabilidadGeneral,
  getCapitaCostoTotal,
  getCapitaRent
}
