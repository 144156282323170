<template>
  <section class="relative">
    <div v-if="loading" class="rounded-md icono-carga p-8 pos-loading">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" />
    </div>
    <div class="box w-full" style="overflow:hidden;">
      <div
      id="grafica"
      ref="elGrafica"
      style="height:500px;"
      class="w-full"
      />
    </div>
  </section>
</template>

<script>
import * as echarts from 'echarts'
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { apiMedioPqr, apiMedioClientePqr } from '../../services'
// import { truncate } from 'lodash'
import { useStore } from 'vuex'
const navegadorVentanaAncho = 1024
export default {
  props: {
    filters: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const isDark = computed(() => {
      return store.getters['main/darkMode']
    })
    watch(props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter.year)
      ) return
      fetchResults(filter)
      fetchResultsCliente(filter)
    })
    /*
    * Refs and reactives
    */
    const gradesRotation = ref(0)
    const positionLabel = ref('insideTop')
    const distanceLabel = ref(10)
    const loading = ref(false)
    const colorLabel = ref('white')
    const elGrafica = ref(null)
    let chart = reactive({})
    const chartData = ref([])
    const chartDataCliente = ref([])
    /*
    * Methods
    */
    const initChart = () => {
      gradesRotation.value = (window.innerWidth < 1000) ? 90 : 0
      positionLabel.value = (window.innerWidth < 1000) ? 'top' : 'top'
      distanceLabel.value = (window.innerWidth < 1000) ? 25 : 10
      colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
      chart = echarts.init(elGrafica.value, isDark.value ? 'dark' : 'light')
      const option = {
        backgroundColor: isDark.value ? '#313A55' : '',
        title: {
          top: 15,
          left: 15,
          text: 'PQR por Medio'
        },
        grid: {
          left: 0,
          right: 15,
          bottom: 50,
          top: 85,
          containLabel: true
        },
        toolbox: {
          top: 15,
          right: 15,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            saveAsImage: {}
          }
        },
        tooltip: {
          top: 0,
          position: (pos) => {
            if (window.innerWidth > navegadorVentanaAncho) {
              return pos[0]
            } else {
              return ['5%', '0']
            }
          },
          formatter (a) {
            let clientes = ''
            var array = getChartClientes(a)
              clientes = clientes + `<div class="title-tool"><p style="width:75%">${a.name}<p><p style="width:25%;text-align: right;">${a.data}<p></div><br/>`
              for (let index = 0; index < array.length; index++) {
              clientes = clientes + `<div style="display:flex;width:250px;"><p style="width:75%">${array[index].cliente}<p><p style="width:25%;text-align: right;">${array[index].cant}<p></div>`
            }
            return clientes
          }
        },
        legend: {
          show: false,
          data: ['Medios']
        },
        yAxis: [
          {
            show: false
          }
        ],
        xAxis: {
          data: getChartLabels(),
          axisLabel: {
            interval: 0,
            fontSize: 10,
            width: 60,
            overflow: 'break'
            // formatter (value) {
            //   return value.split(' ').join('\n')
            // }
          }
        },
        series: [
          {
            name: 'Medios',
            type: 'bar',
            showBackground: true,
            data: getChartData(),
            itemStyle: {
              borderRadius: 9,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              color: colorLabel.value,
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }
      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }
      chart.setOption(option)
      loadEvents()
    }
    const loadEvents = () => {
      window.addEventListener('resize', (evt) => {
        gradesRotation.value = (evt.target.innerWidth < 1000) ? 90 : 0
        positionLabel.value = (evt.target.innerWidth < 1000) ? 'top' : 'top'
        distanceLabel.value = (evt.target.innerWidth < 1000) ? 25 : 10
        colorLabel.value = (window.innerWidth < 1000 && !isDark.value) ? 'gray' : 'gray'
        reloadChart()
        chart.resize()
      })
    }

    const reloadChart = () => {
      const option = {
        xAxis: {
          data: getChartLabels(),
          show: true
        },
        series: [
          {
            showBackground: true,
            name: 'Medios',
            type: 'bar',
            data: getChartData(),
            label: {
              show: true,
              color: colorLabel.value,
              position: positionLabel.value,
              rotate: gradesRotation.value,
              verticalAlign: 'middle',
              distance: distanceLabel.value
            }
          }
        ]
      }

      if (window.innerWidth < 1024) {
        option.xAxis.show = false
      }

      chart.setOption(option)
    }

    const getChartData = () => {
      return chartData.value.map(a => a.cant)
    }

    const getChartLabels = () => {
      return chartData.value.map(a => a.medio)
    }
    const getChartClientes = (medio) => {
      var cli = chartDataCliente.value.map(a => a)
      var cliente = cli.filter(c => c.medio.includes(medio.name))
      return cliente
    }
    const setChartData = (values = []) => {
      chartData.value = values.data
    }
    const setChartClienteData = (values = []) => {
      chartDataCliente.value = values.data
    }

    watch(() => props.filters, (filter) => {
      if ([null, undefined, ''].includes(filter)
      ) return
      fetchResults(filter)
      fetchResultsCliente(filter)
    })

    const fetchResults = (filters) => {
      loading.value = true
      return apiMedioPqr(filters).then(({ data, status }) => {
        setChartData(data)
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
      }).finally(() => {
        loading.value = false
      })
    }
    const fetchResultsCliente = (filters) => {
      loading.value = true
      return apiMedioClientePqr(filters).then(({ data, status }) => {
        setChartClienteData(data)
        reloadChart()
      }).catch((err) => {
        console.error(err.message)
      }).finally(() => {
        loading.value = false
      })
    }

    /*
    * Hooks
    */
    onMounted(() => {
      fetchResults(props.filters)
      fetchResultsCliente(props.filters)
      initChart()
    })

    return {
      chart,
      loading,
      elGrafica
    }
  }
}
</script>

<style>
.title-tool{
  display:flex;width:250px;text-transform: uppercase;border-bottom:1px solid;padding-bottom: 3px
}
.title-tool p{
  white-space: pre-line;
}
.pos-loading {
  height:350px;
  position:absolute;
  top:50%;
  transform:translateY(-50%);
  z-index:1;
  width:100%
}
</style>
