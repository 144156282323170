import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_DASHBOARD_HUS
export default class Pedidos {
  getDataPedidos (payload = {}) {
    return http.get(`${baseUrl}/operaciones/pedidos`, {
      params: payload,
      headers: {
        loading: true
      }
    })
  }

  getDetallePedidos (payload = {}) {
    return http.get(`${baseUrl}/operaciones/pedidos-detalle`, {
      params: payload,
      headers: {
        loading: true
      }
    })
  }
}
