<template>
  <div class="box ">
    <div class="flex flex-col sm:flex-row items-center p-4 border-b border-gray-200 dark:border-dark-5">
      <h4 class="mr-auto capitalize text-base font-medium font-bold">Costos</h4>
    </div>

    <div v-show="loading" style="width:100%; height: 300px" class="rounded-md icono-carga flex justify-center items-center relative">
      <LoadingIcon icon="puff" color="rgba(0,0,0,0.4)" style="width:100%; height: 300px"/>
    </div>

    <div v-show="!loading" class="mb-4 relative">
      <!-- Filtros de la card -->
      <Filters class="mt-2 mb-4 h-full"/>
      <!-- Table -->
      <ListCost />
    </div>

  </div>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'Costos',
  components: {
    Filters: defineAsyncComponent(() => import('./components/filters')),
    ListCost: defineAsyncComponent(() => import('./components/listCosts'))
  },
  setup () {
    const store = useStore()

    const loading = computed(() => {
      return store.getters['filtersCardCostos/getLoadingSearch']
    })

    return {
      loading
    }
  }
}
</script>

<style scoped>

</style>
